<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="type === 'date'">{{ 'DATE.lbl.date_format' | translate }}</ng-container>
      <ng-container *ngIf="type === 'time'">{{ 'DATE.lbl.time_format' | translate }}</ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <ion-list>
    <ion-item [color]="format?.format === selected ? 'tertiary' : ''" *ngFor="let format of formatList" (click)="select(format.format)">
      <ion-text>{{ format.text }}</ion-text>
      <ion-icon class="font-22" color="success" slot="end" *ngIf="format?.format === selected" name="checkmark-circle"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>