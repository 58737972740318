<ion-content class="ion-padding" color="light" *ngIf="!pageMode" #content>
  <div>
    <ng-container *ngIf="true then loginSheet"></ng-container>
  </div>
  <!-- <div class="full-height vertical-center">
    <ion-card class="center ion-padding-vertical">
      <ng-container *ngIf="true then loginSheet"></ng-container>
    </ion-card>
  </div> -->
</ion-content>
<div class="full-width-height" *ngIf="pageMode" #content>
  <ng-container *ngIf="true then loginSheet"></ng-container>
</div>


<ng-template #loginSheet>
  <div class="ion-text-center ion-margin">
    <ion-text class="font-med font-16" color="dark">{{ 'LOGIN.home.intro.title' | translate }}</ion-text>
    <div class="ion-text-center">
      <ion-text class="font-12" color="dark">{{ 'LOGIN.home.intro.msg' | translate }}</ion-text>
    </div>
  </div>
  
  <div class="social-card-div ion-padding">
    <ion-card class="social-card mobile-card vertical-center ion-text-center" (click)="login('mobile')" button>
     
        <ion-icon class="social-icon vertical-middle" name="phone-portrait-outline"></ion-icon>
        <ion-text class="font-14 vertical-middle" color="dark">{{ 'LOGIN.btn.mobile' | translate }}</ion-text>

    </ion-card>
  
    <ion-card class="social-card google-card vertical-center ion-text-center" (click)="login('google.com')" button>

        <ion-icon class="social-icon vertical-middle" src="./assets/wedding/img/social/google.svg"></ion-icon>
        <ion-text class="font-14 vertical-middle">{{ 'LOGIN.btn.google' | translate }}</ion-text>

    </ion-card>
  
    <ion-card class="social-card facebook-card vertical-center ion-text-center" (click)="login('facebook.com')" button>
      
        <ion-icon class="social-icon vertical-middle" src="./assets/wedding/img/social/facebook.svg"></ion-icon>
        <ion-text class="font-14 vertical-middle">{{ 'LOGIN.btn.facebook' | translate }}</ion-text>
      
    </ion-card>
  
    <ion-card class="social-card apple-card vertical-center ion-text-center" (click)="login('apple.com')" button>
      
        <ion-icon class="social-icon vertical-middle" src="./assets/wedding/icon/social/apple.svg"></ion-icon>
        <ion-text class="font-14 vertical-middle">{{ 'LOGIN.btn.apple' | translate }}</ion-text>
     
    </ion-card>
  
    <!-- <ion-card class="social-card twitter-card" (click)="login('twitter.com')" button>
      <ion-item class="background-transparent" lines="none">
        <ion-icon class="social-icon" src="./assets/wedding/img/social/twitter.svg"></ion-icon>
        <ion-text class="font-14">{{ 'LOGIN.btn.twitter' | translate }}</ion-text>
      </ion-item>
    </ion-card> -->
  </div>
  <div class="ion-margin-vertical">
    <div class="font-10 ion-text-center">
      <ion-text [innerHTML]="'LOGIN.msg.agree_start' | translate"></ion-text>
      <ion-text class="font-underline" [innerHTML]="'MENU.term' | translate" (click)="presentTermModal()"></ion-text>
      <ion-text [innerHTML]="'LOGIN.msg.agree_and' | translate"></ion-text>
      <ion-text class="font-underline" [innerHTML]="'AUP.title' | translate" (click)="presentAupModal()"></ion-text>
      <ion-text [innerHTML]="'LOGIN.msg.agree_end' | translate"></ion-text>
      &nbsp;<ion-text [innerHTML]="'LOGIN.msg.privacy' | translate" (click)="presentPrivacyModal()"></ion-text>
    </div>
    <!-- <div class="font-10 ion-text-center" style="margin: 5px 0;" (click)="presentPrivacyModal()">
      <ion-text [innerHTML]="'LOGIN.msg.term' | translate"></ion-text>
    </div> -->
    <!-- <div class="font-10 ion-text-center ion-margin-vertical" (click)="presentPrivacyModal()">
      <ion-text [innerHTML]="'LOGIN.msg.privacy' | translate"></ion-text>
    </div> -->
  </div>
</ng-template>