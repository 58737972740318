import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { WebsiteDetail } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';


import { WebsiteCheckinService } from './website-checkin.service';
import { WebsiteContactService } from './website-contact.service';
import { WebsiteGalleryService } from './website-gallery.service';
import { WebsiteHostService } from './website-host.service';
import { WebsiteInfoService } from './website-info.service';
import { WebsiteItineraryService } from './website-itinerary.service';
import { WebsiteTimeService } from './website-time.service';
import { WebsiteQrcodeService } from './website-qrcode.service';
import { WebsiteVenueService } from './website-venue.service';
import { WebsiteThemeService } from './website-theme.service';
import { WebsiteContentsService } from './website-contents.service';
import { WebsiteFormService } from './website-form.service';
import { Link } from 'src/app/interfaces/general';
import { WebsiteDressCodeService } from './website-dress-code.service';
import { WebsiteLogoService } from './website-logo.service';
import { UpdateByService } from '../user/update-by.service';
import { WebsiteGuestProfileService } from './website-guest-profile.service';
import { ModalController } from '@ionic/angular';
import { WebsiteModeUpgradeComponent } from 'src/app/components/website/setting/website-mode-upgrade/website-mode-upgrade.component';


@Injectable({
  providedIn: 'root'
})
export class WebsiteDetailService implements OnInit, OnDestroy {

  websiteDetail: WebsiteDetail;

  private accountId: string;

  private websiteSettingSubscription: Subscription;

  observable: any;
  
  constructor(
    private afs: AngularFirestore,
    private modalController: ModalController,
    private websiteInfoService: WebsiteInfoService,
    private websiteTimeService: WebsiteTimeService,
    private websiteCheckinService: WebsiteCheckinService,
    private websiteContactService: WebsiteContactService,
    private websiteGalleryService: WebsiteGalleryService,
    private websiteItineraryService: WebsiteItineraryService,
    private websiteHostService: WebsiteHostService,
    private websiteVenueService :WebsiteVenueService,
    private websiteQrcodeService: WebsiteQrcodeService,
    private websiteFormService: WebsiteFormService,
    private websiteContentsService: WebsiteContentsService,
    private websiteDressCodeService: WebsiteDressCodeService,
    private websiteThemeService :WebsiteThemeService,
    private websiteProfileService: WebsiteGuestProfileService,
    private websiteLogoService: WebsiteLogoService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteDetail>(this.websiteDetail);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchWebsiteDetail();
    this.websiteDetail = null;
  }

  async setup(accountId: string) {
    this.accountId = accountId;
    
    // this.observableWebsiteDetail.next(this.websiteDetail);
    if (this.accountId) {
      await this.watchWebsiteDetail();
    } else {
      await this.unwatchWebsiteDetail();
      this.websiteDetail = null;
    }
  }

  async watchWebsiteDetail() {
    if (this.accountId) {
      if (!this.websiteSettingSubscription) {
        this.websiteSettingSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/website`)
        .snapshotChanges().subscribe({
          next: (result) => {
            if (result?.payload?.data()) {
              const websiteDetail: WebsiteDetail = result.payload.data() as WebsiteDetail;
              // console.log(websiteDetail);
              if (!this.functionService.isEqual(this.websiteDetail, websiteDetail)) {
                this.websiteDetail = websiteDetail;
                this.observable.next(this.websiteDetail);

                this.websiteContentsService.setWebsiteDetail(websiteDetail);
                
                this.websiteInfoService.setup(websiteDetail?.info);
                this.websiteTimeService.setup(websiteDetail?.time);
                this.websiteVenueService.setup(websiteDetail?.venue);
                this.websiteContentsService.setup(websiteDetail?.contents);
                this.websiteHostService.setup(websiteDetail?.hostMsg);
                this.websiteGalleryService.setup(websiteDetail?.gallery);
                this.websiteThemeService.setup(websiteDetail?.theme);
                this.websiteFormService.setup(websiteDetail?.form);
                this.websiteCheckinService.setup(websiteDetail?.checkin);
                this.websiteQrcodeService.setup(websiteDetail?.qrcode);
                this.websiteContactService.setup(websiteDetail?.contact);
                this.websiteItineraryService.setup(websiteDetail?.itinerary);
                this.websiteDressCodeService.setup(websiteDetail?.dressCode);
                this.websiteProfileService.setup(websiteDetail?.profile);
                this.websiteLogoService.setup(websiteDetail?.logo);
              }
            } else {
              // this.presentWebsiteModeUpgradeModal();
            }
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchWebsiteDetail();
      }, 500);
    }
  }

  /**
   * Unwatch page setting
   */
  async unwatchWebsiteDetail() {
    if (this.websiteSettingSubscription) {
      this.websiteSettingSubscription.unsubscribe();
      this.websiteSettingSubscription = null;
    }
  }

  getWebsiteDetail(reset?: boolean): WebsiteDetail {
    let websiteDetail: WebsiteDetail | any = this.functionService.cloneDeep(this.websiteDetail);
    if (!websiteDetail || this.functionService.isUndefined(websiteDetail)) {
      websiteDetail = {};
    }
    if (this.functionService.isUndefined(websiteDetail?.checkin) || reset) {
      websiteDetail.checkin = this.websiteCheckinService.getWebsiteCheckin();
    }
    if (this.functionService.isUndefined(websiteDetail?.contact) || reset) {
      websiteDetail.contact = this.websiteContactService.getWebsiteContact();
    }
    if (this.functionService.isUndefined(websiteDetail?.contents) || reset) {
      websiteDetail.contents = this.websiteContentsService.getWebsiteContents();
    }
    if (this.functionService.isUndefined(websiteDetail?.form) || reset) {
      websiteDetail.form = this.websiteFormService.getWebsiteForm();
    }
    if (this.functionService.isUndefined(websiteDetail?.gallery) || reset) {
      websiteDetail.gallery = this.websiteGalleryService.getWebsiteGallery();
    }
    if (this.functionService.isUndefined(websiteDetail?.hostMsg) || reset) {
      websiteDetail.hostMsg = this.websiteHostService.getWebsiteHost();
    }
    if (this.functionService.isUndefined(websiteDetail?.info) || reset) {
      websiteDetail.info = this.websiteInfoService.getWebsiteInfo();
    }
    if (this.functionService.isUndefined(websiteDetail?.itinerary) || reset) {
      websiteDetail.itinerary = this.websiteItineraryService.getWebsiteItinerary();
    }
    if (this.functionService.isUndefined(websiteDetail?.qrcode) || reset) {
      websiteDetail.qrcode = this.websiteQrcodeService.getWebsiteQrcode();
    }
    if (this.functionService.isUndefined(websiteDetail?.theme) || reset) {
      websiteDetail.theme = this.websiteThemeService.getWebsiteTheme();
    }
    if (this.functionService.isUndefined(websiteDetail?.time) || reset) {
      websiteDetail.time = this.websiteTimeService.getWebsiteTime();
    }
    if (this.functionService.isUndefined(websiteDetail?.venue) || reset) {
      websiteDetail.venue = this.websiteVenueService.getWebsiteVenue();
    }

    return { ...websiteDetail };
  }

  checkWebsiteDetail() {
    if (this.websiteDetail && !this.functionService.isEmpty(this.websiteDetail)) {
      return true;
    }
    return false;
  }

  async save(websiteDetail: WebsiteDetail) {
    if (websiteDetail && this.accountId && !this.functionService.isEqual(websiteDetail, this.websiteDetail)) {
      websiteDetail.updateBy = this.updateByService.updateBy;
      if (!websiteDetail?.createBy) {
        websiteDetail.createBy = websiteDetail.updateBy;
      }
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set(websiteDetail, { merge: true });
    }
  }

  async presentWebsiteModeUpgradeModal() {
    const modal = await this.modalController.create({
      component: WebsiteModeUpgradeComponent,
      cssClass: 'modal-transparent',
      componentProps: {
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      
    });
  }

  // async saveLink(link: Link) {
  //   if (link && this.accountId) {
  //     const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
  //     ref.set({ link }, { merge: true });
  //   }
  // }
    
}
