<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ title }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-item class="searchbar-item full-width ion-item-no-padding" lines="none">
      <ion-searchbar name="search" class="searchbar" color="white" debounce="800" [(ngModel)]="keyword" (ionInput)="setupItems()" (keypress)="closeKeyboard($event)" [placeholder]="placeholder ? placeholder : 'BTN.search' | translate" [showClearButton]="'always'" debounce="800" enterkeyhint="search" inputmode="search" type="text" autocomplete="on" searchIcon="search-outline"></ion-searchbar>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngIf="itemsList">
    <div class="full-width-height viewport-div" *ngIf="itemsList?.length else emptyBlock">
      <cdk-virtual-scroll-viewport [itemSize]="44" >
        <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
          <span *cdkVirtualFor="let item of itemsList; trackBy: trackByFn; let i = index;">
            <ion-item class="item" [color]="checkSelected(item) ? 'tertiary' : 'white'" lines="full" *ngIf="item" (click)="select(item)">
              <ion-text class="ion-text-wrap">
                {{ item?.[itemTextField] }}
              </ion-text>
              <ion-text slot="end" *ngIf="checkSelected(item)">
                <ion-icon name="checkmark-outline"></ion-icon>
              </ion-text>
            </ion-item>
          </span>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>

  <ng-template class="full-width-height center vertical-center" #emptyBlock>
    <ion-label class="ion-text-center ion-margin" color="medium">
      <ion-icon class="center icon-cactus" color="medium" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
      <div class="ion-margin-top font-12"><ion-text color="medium">{{ 'LBL.no_found' | translate }}</ion-text></div>
    </ion-label>
  </ng-template>
</ion-content>