<ng-container *ngIf="modalMode">
  <ion-content>
    <div class="full-height vertical-center">
      <ion-card class="center ion-no-padding">
        <ion-card-content class="ion-no-padding">
          <div>
            <div class="ion-margin-vertical font-18 font-semi" *ngIf="lbl">
              <span [innerHTML]="lbl"></span>&nbsp;<ion-text color="danger" *ngIf="required">*</ion-text>
            </div>
            <!-- <div class="ion-margin-vertical" *ngIf="subtitle" [innerHTML]="subtitle"></div> -->
            <div class="ion-margin-vertical ion-text-center font-14" *ngIf="msg" [innerHTML]="msg"></div>
          </div>
          <div>
            <ng-container *ngIf="true then mobileForm"></ng-container>
          </div>
          <ion-grid fixed>
            <ion-row class="ion-margin-vertical ion-hide-sm-down"></ion-row>
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size-xs="0" size-sm="1"></ion-col>
              <ion-col class="ion-align-self-center ion-text-center">
                <ion-button class="ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="dismissModal()">
                  {{ 'BTN.cancel' | translate }}
                </ion-button>
              </ion-col>
              <ion-col size-xs="1" size-sm="2"></ion-col>
              <ion-col class="ion-align-self-center center">
                <ion-button class="ion-no-margin" expand="block" [color]="'primary'" fill="solid" type="button" [disabled]="!form?.valid || invalidMobile || !mobile?.no" (click)="save()">
                  {{ 'BTN.save' | translate }}
                </ion-button>
              </ion-col>
              <ion-col size-xs="0" size-sm="1"></ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </div>
  </ion-content>
</ng-container>
<ng-container *ngIf="!modalMode">
  <ng-container *ngIf="true then mobileForm"></ng-container>
</ng-container>

<ng-template #mobileForm>
  <form [formGroup]="form" *ngIf="form && ready">
    <div>
      <ion-label position="stacked" *ngIf="lbl && !modalMode">{{ lbl }}&nbsp;<ion-text color="danger" *ngIf="required">*</ion-text></ion-label>
      <div>
        <ngx-intl-tel-input
          #phone
          [inputId]="'phoneNumber'"
          [cssClass]="cssClass" 
          [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true" 
          [enablePlaceholder]="false" 
          [searchCountryFlag]="false"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectFirstCountry]="false" 
          [selectedCountryISO]="selectedCountryISO"
          [maxLength]="15" 
          [phoneValidation]="true" 
          [separateDialCode]="separateDialCode"
          placeholderNumberType="MOBILE"
          name="mobile"
          formControlName="mobile">
        </ngx-intl-tel-input>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.mobile">
            <div class="error-message ion-text-left" *ngIf="ready && form?.get('mobile').hasError(validation.type) && (form?.get('mobile').dirty || form?.get('mobile').touched)">
              <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>

  <!-- <div class="ion-text-center font-12" *ngIf="otpMode && ((!mobile?.code && selectedCountryISO === 'my') || (mobile?.code === 60 || mobile?.country?.toLowerCase() === 'my'))">
    {{ 'MOBILE.msg.otp_digi' | translate }}
  </div> -->
</ng-template>

