<ng-container *ngIf="!pageMode then main">
</ng-container>

<div class="ion-page full-screen-height" *ngIf="pageMode">
  <ng-container *ngIf="true then main"></ng-container>
</div>

<ng-template #main>
  <ion-header class="ion-no-border" #header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <app-offline-indicator></app-offline-indicator>
        {{ 'BLAST.package.title' | translate }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="full-width ion-padding-vertical" color="light" #content>
    <div class="content-div">
      <div class="text-div ion-text-left line-1">
        <ion-text class="font-12 line-1">{{ 'BLAST.package.custom_msg' | translate }}</ion-text>
      </div>
      <ion-card class="custom-package-card ion-no-margin ion-padding">
        <ion-img class="package-img center ion-padding" src="./assets/wedding/img/blast/package.svg"></ion-img>
        
        <div class="ion-text-left line-1 font-underline calculator-link-div" (click)="presentBlastCreditCalculatorModal()">
          <ion-text class="font-10 line-1 font-italic">{{ 'BLAST.calculator.link' | translate }}</ion-text>
        </div>
        <ion-item class="background-transparent ion-item-no-padding ion-no-margin" lines="none">
          <ion-input class="prompt-input font-14 full-width ion-text-center ion-no-padding" [min]="minCredit" type="number" inputmode="numeric" [(ngModel)]="customCredit" (keyup)="setupItemList()">
            <ion-button class="qty-btn" slot="start" (click)="minusQty()" color="light">
              <ion-icon slot="icon-only" name="remove"></ion-icon>
            </ion-button>
            <ion-button class="qty-btn" slot="end" (click)="addQty()" color="light">
              <ion-icon slot="icon-only" name="add"></ion-icon>
            </ion-button>
          </ion-input>
        </ion-item>
        <div class="est-div font-10 ion-text-wrap" [innerHTML]="'BLAST.credit.est_msg_count' | translate: { smsCount: smsCount | number, whatsappCount: whatsAppCount | number, country: countryName }"></div>
        <div class="error-div">
          <div class="error-message" *ngIf="customCredit && minCredit > customCredit ">
            <ion-icon name="alert-circle-outline"></ion-icon> {{ 'VALIDATION.min_number' | translate: { number: minCredit } }}
          </div>
        </div>
      </ion-card>
    
      <div class="ion-padding-vertical" *ngIf="bulkQty && blastPackageProductList?.length">
        <div class="text-div ion-text-left line-1">
          <ion-text class="font-12 line-1">{{ 'BLAST.package.package_msg' | translate }}</ion-text>
        </div>
        <ion-card class="tier-card ion-padding">
          <ng-container *ngFor="let blastPackageProduct of blastPackageProductList; let i = index" >
            <ion-item class="ion-item-no-padding">
              <ion-text>
                <div class="font-14 font-med ion-text-wrap">{{ 'BLAST.package.tier.title' | translate: { tier: (i + 1)} }}</div>
                <div class="est-div font-12 ion-text-wrap">
                  <ng-container *ngIf="i === 0">
                    {{ 'BLAST.package.tier.msg.below' | translate: { credits: blastPackageProductList[1].blastCredit - 1 } }}
                  </ng-container>
                  <ng-container *ngIf="i === 1">
                    {{ 'BLAST.package.tier.msg.between' | translate: { credits1: blastPackageProduct.blastCredit, credits2: blastPackageProductList[2].blastCredit - 1 } }}
                  </ng-container>
                  <ng-container *ngIf="i === 2">
                    {{ 'BLAST.package.tier.msg.between' | translate: { credits1: blastPackageProduct.blastCredit, credits2: blastPackageProductList[3].blastCredit - 1 } }}
                  </ng-container>
                  <ng-container *ngIf="i === 3">
                    {{ 'BLAST.package.tier.msg.between' | translate: { credits1: blastPackageProduct.blastCredit, credits2: blastPackageProductList[4].blastCredit - 1 } }}
                  </ng-container>
                  <ng-container *ngIf="i === 4">
                    {{ 'BLAST.package.tier.msg.above' | translate: { credits: blastPackageProduct.blastCredit } }}
                  </ng-container>
                </div>
              </ion-text>
              <ion-text slot="end">
                <div class="per-credit-price-div text-ellipsis ion-text-right"><ion-text class="font-14 font-med" *ngIf="getPrice(blastPackageProduct.price) as price">{{ price.amount | currency : price.currency : 'symbol' : '1.0-5' }}</ion-text></div>
                <div class="ion-text-right"><ion-text class="font-10">{{ 'BLAST.package.per_credit' | translate }}</ion-text></div>
              </ion-text>
            </ion-item>
          </ng-container>
        </ion-card>
      </div>
    </div>
  </ion-content>
  <ion-footer #footer>
    <ion-toolbar class="ion-no-padding no-padding" color="tertiary" *ngIf="availableCredit">
      <div class="content-div">
        <ion-item class="full-width" color="tertiary" lines="none">
          <ion-toggle [(ngModel)]="minusAvailableCredit" [disabled]="availableCredit > customCredit" (ionChange)="setupItemList()" color="success">
            <ion-text class="font-12">{{ 'BLAST.package.minus_available' | translate: { credits: availableCredit } }}</ion-text>
          </ion-toggle>
        </ion-item>
      </div>
    </ion-toolbar>
    <ion-toolbar class="ion-padding-horizontal" color="white">
      <div class="content-div">
        <ion-item class="total-item background-transparent" lines="none">
          <ion-label class="font-20 font-med ion-no-margin" color="dark">{{ 'PAYMENT.lbl.payment' | translate }}</ion-label>
          <ion-text class="font-20 font-med" color="dark" slot="end">
            <div class="ion-text-right">
              <ng-container *ngIf="total && total > 0">{{ total | currency : currency : 'symbol' }}</ng-container>
              <ng-container *ngIf="!total || total < 0">
                -
              </ng-container>
            </div>
            <div class="ion-text-right" *ngIf="total && total > 0">
              <ion-text class="font-12">{{ 'BLAST.package.name_short' | translate: { count: totalBlastCredit } }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
        <div class="ion-margin">
          <ion-button expand="block" (click)="next()" [disabled]="!currency || !itemList?.length || !total || total < 0 || trialMode">
            <ion-text class="ion-text-wrap">{{ 'PAYMENT.btn.purchase' | translate }}</ion-text>
          </ion-button>
          <div class="line-100" (click)="goUpgrade()" *ngIf="trialMode">
            <ion-text class="font-10" color="danger" [innerHTML]="'BLAST.error.trial' | translate">
            </ion-text>
          </div>
        </div>
        
      </div>
    </ion-toolbar>
  </ion-footer>
</ng-template>
