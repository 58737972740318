<div class="ion-padding-horizontal">
  <div class="status-main-div" [class]="websiteProfile?.design === 1 ? (websiteThemeColor?.button?.fill === 'outline' ? 'website-status-outline-card' : 'website-status-solid-card') : ''">
    <div class="ion-padding-bottom" *ngIf="checkinStatus">
      <ion-segment [(ngModel)]="type" *ngIf="checkinStatus">
        <ion-segment-button [value]="'profile'">
          <ion-icon class="font-16" name="person"></ion-icon>
        </ion-segment-button>
        <ion-segment-button [value]="'status'">
          <ion-icon class="font-16" name="checkmark-circle"></ion-icon>
        </ion-segment-button>
      </ion-segment>
    </div>
  
    <ng-container *ngIf="!type || type === 'profile'">
      <quill-view [content]="websiteProfile.title" *ngIf="websiteProfile?.title"></quill-view>
      <quill-view [content]="websiteProfile.msg" *ngIf="websiteProfile?.msg"></quill-view>
    
      <div class="font-20 font-bold ion-text-center ion-margin" *ngIf="name">
        {{ name }}
      </div>

      <ion-item color="background-transparent" lines="none">
        <ion-icon class="icon" name="star-outline" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.status }}</ion-text>
        <ion-text slot="end">{{ status ? status : '-' }}</ion-text>
      </ion-item>
      
      <ion-item color="background-transparent" lines="none">
        <ion-icon class="icon" src="./assets/wedding/icon/general/persons.svg" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.pax }}</ion-text>
        <ion-text slot="end">{{ memberList?.length ? memberList.length : 1 }}</ion-text>
      </ion-item>
      
      <ion-item color="background-transparent" lines="none" *ngIf="websiteProfile?.showSeating || checkinStatus">
        <ion-icon class="icon" src="./assets/wedding/icon/guest/seating-outline.svg" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.seating }}</ion-text>
        <ion-text slot="end">{{ seating ? seating : '-' }}</ion-text>
      </ion-item>
      
      <ion-item color="background-transparent" lines="none" *ngIf="websiteDetail?.form?.dietaryReq?.enable">
        <ion-icon class="icon" name="restaurant-outline" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.dietaryReq }}</ion-text>
        <ion-text slot="end">{{ dietaryReq }}</ion-text>
      </ion-item>
      
      <ion-item color="background-transparent" lines="none" *ngIf="websiteDetail?.form?.specialReq?.enable">
        <ion-icon class="icon" name="alert-circle-outline" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.specialReq }}</ion-text>
        <ion-text slot="end">{{ specialReq }}</ion-text>
      </ion-item>
      
      <ion-item color="background-transparent" lines="none" *ngIf="websiteDetail?.form?.mobile?.type !== 'hidden'">
        <ion-icon class="icon" name="call-outline" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.mobile }}</ion-text>
        <ion-text slot="end">{{ mobile ? mobile : '-' }}</ion-text>
      </ion-item>
      
      <ion-item color="background-transparent" lines="none" *ngIf="websiteDetail?.form?.email?.type !== 'hidden'">
        <ion-icon class="icon" name="mail-outline" slot="start"></ion-icon>
        <ion-text slot="start">{{ websiteProfile?.field?.email }}</ion-text>
        <ion-text slot="end">{{ email ? email : '-' }}</ion-text>
      </ion-item> 
  
      <div class="ion-margin-vertical ion-padding-top">
        <ng-container *ngIf="group?.groupId && memberList?.length > 1">
          <div class="full-width ion-text-center font-underline" *ngIf="websiteProfile?.field?.member">
            {{ websiteProfile.field.member }}
          </div> 
          <ng-container *ngFor="let member of memberList; let i = index;">
            <ion-item class="background-transparent" lines="none" *ngIf="member?.guestId && member?.name">
              <ion-text slot="start">
                <ion-icon class="icon" name="person"></ion-icon> {{ i + 1 }}
              </ion-text>
              <ion-text>
                {{ member.name }}
                <div>
                  <div *ngIf="member.seating !== seating">
                    <ion-icon class="icon" name="star-outline"></ion-icon>&nbsp;{{ member.seating }}
                  </div>
                  <div *ngIf="getSettingField('dietary_req', member.dietaryReq[0]) as guestDietaryReq">
                    <div *ngIf="guestDietaryReq !== dietaryReq">
                      <ion-icon class="icon" name="restaurant-outline"></ion-icon>&nbsp;{{ guestDietaryReq }}
                    </div>
                  </div>

                  <div *ngIf="getSettingField('special_req', member.specialReq[0]) as guestSpecialReq">
                    <div *ngIf="guestSpecialReq !== specialReq">
                      <ion-icon class="icon" name="alert-circle-outline"></ion-icon>&nbsp;{{ guestSpecialReq }}
                    </div>
                  </div>
                </div>
              </ion-text>
            </ion-item>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  
    <ng-container *ngIf="type === 'status'">
      <app-website-checkin-success [websiteDetail]="websiteDetail" [setGuest]="guest" [setGroup]="group" [setMemberList]="memberList" [setCheckinStatus]="checkinStatus"></app-website-checkin-success>
    </ng-container>
  </div>
</div>
