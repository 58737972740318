import { Injectable } from '@angular/core';
import { VisitorManageService } from '../visitor/visitor-manage.service';
import { PopupService } from '../general/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteDetailService } from './website-detail.service';
import { WebsiteInfoService } from './website-info.service';
import { WebsiteVenueService } from './website-venue.service';
import { AccountInfoService } from '../account/account-info.service';
import { CoupleList } from 'src/app/interfaces/account';
import { VisitorLocationService } from '../visitor/visitor-location.service';
import { WebsiteLinkService } from './website-link.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteModeUpgradeService {

  constructor(
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private visistorLocationService: VisitorLocationService,
    private visitorManageService: VisitorManageService,
    private websiteDetailService: WebsiteDetailService,
    private websiteInfoService: WebsiteInfoService,
    private websiteVenueService: WebsiteVenueService,
    private websiteLinkService: WebsiteLinkService,
    private popupService: PopupService,
  ) { }

  async upgradeNow(newWebsiteMode: boolean) {
    await this.popupService.presentLoading();
    if (newWebsiteMode) {
      await this.setupWebsiteDetail();
    }
    await this.visitorManageService.savePageSetting({setting: { newWebsiteMode }});
    // this.setupUpdateTheme();
    
    await this.popupService.dismissLoading();
    await this.popupService.presentToast(this.translate.instant('MSG.action_done'), 'success');
  }

  async setupWebsiteDetail() {
    const websiteDetail = this.websiteDetailService.getWebsiteDetail(true);
    const accountInfo = this.accountInfoService.accountInfo;
    websiteDetail.info.title = this.websiteInfoService.getTitle(false, accountInfo.title.value);
    if (accountInfo.coverPhoto?.url) {
      websiteDetail.theme.cover.media = accountInfo.coverPhoto;
    }
    if (!accountInfo.eventMode) {
      if (accountInfo?.coupleList?.length) {
        accountInfo.coupleList.forEach((couple: CoupleList) => {
          if (couple?.accountCouple?.coupleId === 1) {
            websiteDetail.hostMsg.couple1.name = couple.name;
            if (couple?.avatar?.url) {
              websiteDetail.hostMsg.couple1.avatar = couple.avatar;
            }
          } else if (couple?.accountCouple?.coupleId === 2) {
            websiteDetail.hostMsg.couple2.name = couple.name;
            if (couple?.avatar?.url) {
              websiteDetail.hostMsg.couple2.avatar = couple.avatar;
            }
          }
        })
      }
    } else {
      websiteDetail.hostMsg.organizer.name = accountInfo?.organizer;
      if (accountInfo?.organizerAvatar?.url) {
        websiteDetail.hostMsg.organizer.avatar = accountInfo.organizerAvatar;
      }
    }
    
    const vistorLocation = this.visistorLocationService.getLocation();
    if (vistorLocation?.name) {
      websiteDetail.venue.name = this.websiteVenueService.getVenueName(vistorLocation.name);
      if (vistorLocation.address) {
        websiteDetail.venue.address = this.websiteVenueService.getVenueAddress(vistorLocation.address);
      }
      if (vistorLocation.hall) {
        websiteDetail.venue.hall = this.websiteVenueService.getVenueHall(vistorLocation.hall);
      }
    } else if (accountInfo?.location?.name) {
      websiteDetail.venue.name = this.websiteVenueService.getVenueName(accountInfo?.location?.name);
      websiteDetail.venue.address = this.websiteVenueService.getVenueAddress(accountInfo?.location?.address);
      websiteDetail.venue.hall = this.websiteVenueService.getVenueHall();
    }
    await this.websiteDetailService.save(websiteDetail);

    await this.websiteLinkService.getWebsiteLink('', false, true, true, true);
  }
}
