import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Group } from 'src/app/interfaces/group';
import { ModuleType } from 'src/app/types/general';
import { DynamicUrl } from 'src/app/commons/url';
import { GroupFixService } from './group-fix.service';
import { ErrorService } from '../general/error.service';
import { VisitorModeService } from '../visitor/visitor-mode.service';


/**
 * Guest group service get / update group data.
 */
@Injectable({
  providedIn: 'root'
})
export class GroupService implements OnInit, OnDestroy {

  /**
   * Group list
   */
  groupList: Group[];
  /**
   * Observable group list
   */
  observableGroupList: any;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;
  /**
   * Module subscription
   */
  private moduleSubscription: Subscription;

  private count: number;

  ready: boolean;

  /**
   * Constructor
   * @param afs angular firestore
   * @param onlineService online service
   */
  constructor(
    private afs: AngularFirestore,
    private moduleService: ModuleService,
    private groupFixService: GroupFixService,
    private guestService: GuestService,
    private giftService: GiftService,
    private visitorModeService: VisitorModeService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.groupList = [];
    this.observableGroupList = new BehaviorSubject<Group[]>(this.groupList);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchGroupList();
    this.unwatchGuestList();
    this.unwatchCurrentModule();
    this.unwatchGiftList();

    this.groupList = [];
    this.count = 0;
  }

  /**
   * Setup Account ID
   * @param Account ID
   */
  async setupAccountId(accountId?: string) {
    this.accountId = accountId;

    if (this.accountId) {
      await this.watchGroupList();
      await this.watchGuestList();
      await this.watchCurrentModule();
    } else {
      this.ready = false;
      await this.unwatchGroupList();
      await this.unwatchGuestList();
      await this.unwatchCurrentModule();
      await this.unwatchGiftList();
      this.groupList = [];
      this.count = 0;
    }
  }

  /**
   * Watch current module
   */
  async watchCurrentModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        if (module === 'gift') {
          this.watchGiftList();
        } else {
          this.unwatchGiftList();
        }
      });
    }
  }

  /**
   * Unwatch current module
   */
  async unwatchCurrentModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
    if (this.accountId && !this.groupListSubscription) {
      this.groupListSubscription = this.afs.collection(`accounts/${ this.accountId }/groups/`)
      .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
        const group: Group = a.payload.doc.data() as Group;
        if (!group?.groupId || group.groupId !== a.payload.doc.id) { group.groupId = a.payload.doc.id; }
        if (!group?.email) { group.email = ''; }
        if (!group?.status) { group.status = {}; }
        if (!group?.mobile?.no) {
          group.mobile = {
            code: 0,
            country: '',
            no: '',
          };
        }
        return group;
      }))).subscribe({
        next: async (groupList: Group[]) => {
          this.groupFixService.fixGroup([ ...groupList ]);
          
          await this.setupGroupMemberList(groupList);

          if (!this.ready) {
            await this.functionService.delay(200);
            this.ready = true;
          }
        }, error: (err: any) => {
          this.errorService.logError(err);
          this.ready = true;
        }
      });

      // if (this.restartCount < 2) {
      //   await this.functionService.delay(1500);
      //   if (this.guestService.toast && !this.groupList?.length) {
      //     await this.unwatchGroupList();
      //     this.restartCount += 1;
      //     await this.functionService.delay(500);
      //     await this.watchGroupList();
      //   }
      // }
    }
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  /**
   * Watch gift list changes
   */
  async watchGuestList() {
    if (this.accountId && !this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGroupMemberList(this.groupList);
      });
    }
  }

  /**
   * Unwatch gift list changes
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (this.accountId && !this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.setupGroupMemberList(this.groupList);
      });
    }
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  /**
   * Setup group member list
   * @param groupList Group list
   */
  async setupGroupMemberList(groupList: Group[]) {
    if (groupList?.length) {
      groupList?.forEach((group: Group) => {
        group.memberList = this.guestService.getGroupMemberId(group?.groupId);
        group.giftList = this.giftService.getGiftIdListByGroupId(group?.groupId);
        // if (!group?.mobile?.no || !group.email) {
        //   const guestList = this.guestService.getGuestListById(group.memberList);
        //   let mobile = {
        //     code: 0,
        //     no: ''
        //   };
        //   let email = '';
        //   let validMobile = true;
        //   let validEmail = true;
        //   guestList.forEach((guest: Guest) => {
        //     if (guest?.mobile?.no && !this.functionService.isEqual(guest.mobile.no, mobile)) {
        //       mobile = guest.mobile;
        //     }
        //     if (!guest?.mobile?.no || !this.functionService.isEqual(guest?.mobile, mobile)) {
        //       validMobile = false;
        //     }
        //     if (guest?.email && guest.email !== email) {
        //       email = guest.email;
        //     }
        //     if (!guest?.email || guest?.email !== email) {
        //      validEmail = false;
        //     }
        //   });
        //   if (!group.mobile) {
        //     if (validMobile) {
        //       group.mobile = mobile;
        //     } else {
        //       group.mobile = {
        //         code: 0,
        //         no: ''
        //       };
        //     }
        //   }
        //   if (validEmail) {
        //     group.email = email;
        //   } else {
        //     group.email = '';
        //   }
        // }
      });
      if (!this.functionService.isEqual(this.groupList, groupList)) {
        this.groupList = groupList;
        this.observableGroupList.next(this.groupList);
      }
    } else {
      this.count += 1;
      if (!this.ready || this.count <= 30) {
        setTimeout(() => {
          this.setupGroupMemberList(this.groupList);
        }, 1000);
      }
    }
  }

  /**`
   * Get group by id
   * @param groupId Group ID
   * @returns Guest group
   */
  getGroup(groupId: string): Group {
    let result: Group;
    if (groupId && this.groupList?.length) {
      const index = this.groupList?.findIndex((group: Group) => group?.groupId === groupId);
      if (index !== -1) {
        result = this.groupList[index];
      }
    }
    return result;
  }

  /**
   * Get group name
   * @param groupId group id
   * @returns group name
   */
  getGroupName(groupId: string): string {
    return this.getGroup(groupId)?.groupName;
  }

  /**
   * Get group list by group id
   * @param groupIdList group id list
   * @returns group list
   */
  getGroupListById(groupIdList: string[]): Group[] {
    return this.groupList.filter((group: Group) => {
      if (groupIdList?.indexOf(group.groupId) !== -1) {
        return true;
      } else {
        return false;
      }
    }).sort((a: Group, b: Group) =>
      this.functionService.compare(a?.groupName?.toLowerCase(), b?.groupName?.toLowerCase()
    ));
  }

  /**
   * Get group by code
   * @param qrcode qrcode
   * @returns group obj
   */
  getGroupByCode(qrcode: string): Group {
    let result: Group;
    if (qrcode && this.groupList?.length) {
      const index = this.groupList?.findIndex((group: Group) => group?.code === qrcode);
      if (index !== -1) {
        result = this.groupList[index];
      }
    }
    return result;
  }

  /**
   * Guest group by guest ID
   * @param guestId Guest ID
   * @returns Guest group
   */
  getGroupByGuestId(guestId: string): Group {
    let result: Group;
    if (guestId && this.groupList?.length) {
      const index = this.groupList?.findIndex((group: Group) => group.memberList?.indexOf(guestId) !== -1);
      if (index !== -1) {
        result = this.groupList[index];
      }
    }
    return result;
  }

  /**
   * Get group url
   * @param groupId Group ID
   * @returns URL
   */
  getGroupUrl(groupId: string, linkId: string, type?: string): string {
    if (this.visitorModeService.checkNewWebsite()) {
      return DynamicUrl.long.guestWeb + this.accountId + '/0/0/' + groupId + '/' + (type ? type : '');
    } else {
      return DynamicUrl.long.visitor + this.accountId + '/0/' + groupId;
    } 
  }

  /**
   * Get group by qrcode
   * @param qrcode QR code
   * @returns group obj
   */
  getGroupByQrcode(qrcode: string): Group {
    let result: Group;
    if (qrcode) {
      result = this.getGroup(qrcode);
      if (!result) {
        result = this.getGroupByCode(qrcode);
      }
    }
    return result;
  }

  /**
   * Get group member list
   * @param groupId group id
   * @returns group member id list
   */
  getGroupMember(groupId: string): string[] {
    const memberList = this.getGroup(groupId)?.memberList;
    return memberList?.length ? memberList : [];
  }

  /**
   * Get bulk of group member list
   * @param groupIdList group id list
   * @returns group member id list
   */
  getGroupListMember(groupIdList: string[]): string[] {
    const groupList = this.getGroupListById(groupIdList);
    const memberList: string[] = [];
    groupList?.forEach((group: Group) => {
      group?.memberList?.forEach((guestId: string) => {
        if (memberList?.indexOf(guestId) === -1) {
          memberList.push(guestId);
        }
      });
    });
    return memberList;
  }

  /**
   * Get group seating from all group guest
   * @returns Join seating from group guest
   */
   getGroupSeating(group: Group): string {
    let result = '';
    if (group?.memberList?.length) {
      let seatingList = this.guestService.getGroupSeating(group.memberList);
      if (seatingList) {
        seatingList = seatingList.sort((a: string, b: string) => {
          return this.functionService.compare(a.toLowerCase(), b.toLowerCase());
        });
        seatingList?.forEach((seating: string, index: number) => {
          if (index >= 1) {
            result += ', ';
          }
          result += seating;
        });
      }
    }
    return result;
  }

  getGroupSession(group: Group): string {
    let result = '';
    if (group?.memberList?.length) {
      let seatingList = this.guestService.getGroupSeating(group.memberList);
      if (seatingList) {
        seatingList = seatingList.sort((a: string, b: string) => {
          return this.functionService.compare(a.toLowerCase(), b.toLowerCase());
        });
        seatingList?.forEach((seating: string, index: number) => {
          if (index >= 1) {
            result += ', ';
          }
          result += seating;
        });
      }
    }
    return result;
  }

}
