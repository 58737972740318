<ion-header *ngIf="showHeader" #header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="preview">
      <app-offline-indicator></app-offline-indicator>
      {{ 'BTN.preview' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true" [class.theme-mode-content]="themeMode" [class.theme-mode-content-ios]="themeMode && hybrid && ios" [class.zh]="language?.code === 'zh'" [class.zh-tw]="language?.code === 'zh-TW'" color="white" #content>
  <div class="ion-page">
    <div class="ion-padding full-width-height center vertical-center" *ngIf="loading">
      <ion-img class="center" style="width: 80px; height: 80px;" src="./assets/wedding/icon/animated/loading.gif"></ion-img>
      <ion-text class="font-12 font-bold">{{ lbl.loading }}</ion-text>
    </div>
    <div class="ion-padding full-width-height vertical-center" *ngIf="!loading && showError">
      <ion-item class="full-width ion-item-no-padding ion-text-center" lines="none">
        <ion-label>
          <p class="line-120">
            {{ lbl.lost }}
          </p>
          <div class="font-24 font-bold line-120">
            {{ lbl.notFound }}
          </div>
        </ion-label>
      </ion-item>
      <ion-icon id="lost" class="not-found center vertical-center" src="./assets/wedding/img/visitor/not-found.svg"></ion-icon>
      <div class="full-width ion-margin ion-padding">
        <ng-container *ngIf="showRefreshBtn && accountId && (guestId || groupId) else homtBtnBlock">
          <ion-button type="button" expand="block" color="primary" (click)="refresh()">
            <ion-icon name="refresh"></ion-icon>&nbsp;{{ lbl.refresh }}
          </ion-button>
        </ng-container>
        <ng-template #homtBtnBlock>
          <ion-button type="button" expand="block" color="primary" (click)="back()">
            <ion-icon color="black" name="home"></ion-icon>&nbsp;{{ lbl.home }}
          </ion-button>
        </ng-template>
        
      </div>
    </div>
  </div>
  
  <ng-container *ngIf="backgroundId">
    <div class="background background-theme-{{ backgroundId }} theme-{{ replaceAll(themeId, '.', '-') }}" [style.background-image]="pageSetting?.backgroundUrl ? 'url(' + pageSetting.backgroundUrl + ') !important' : ''" *ngIf="!loading && !showError && themeId">
      <ng-container *ngIf="backgroundId === '9' || backgroundId === '10' || backgroundId === '11' || backgroundId === '17'">
        <div>
          <ion-img class="background-{{ backgroundId }}-1 full-width" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/background-1.svg"></ion-img>
        </div>
      </ng-container>
      <ng-container *ngIf="backgroundId === '9'">
        <div>
          <ion-img class="background-{{ backgroundId }}-2 full-width" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/background-2.svg"></ion-img>
        </div>
      </ng-container>
      <div class="main-div main-div-{{ backgroundId }} center vertical-center">
        <ng-container *ngIf="themeId === '1.1' || themeId === '2.1' || themeId === '3.1' || themeId === '8.1' || themeId === '9.1' || themeId === '10.1' || themeId === '11.1' || themeId === '12.1' || themeId === '13.1' || themeId === '14.1' || themeId === '15.1' || themeId === '16.1' || themeId === '17.1' || themeId === '18.1.1' || themeId === '18.2.1' || themeId === '19.1.1' || themeId === '19.2.1' || themeId === '20.1.1' || themeId === '20.2.1' || themeId === '21.1.1' || themeId === '21.2.1' || themeId === '22.1.1' || themeId === '22.2.1'">
          <div class="full-width frame-div">
            <ng-container *ngIf="backgroundId === '15' || backgroundId === '16'">
              <ion-img class="flower-1" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/flower-1.svg"></ion-img>
            </ng-container>
            <ion-card class="center full-width ion-padding" [class]="(backgroundId === '10' || backgroundId === '11' || backgroundId === '15' || backgroundId === '16' || backgroundId === '17' || backgroundId === '18' || backgroundId === '19' || backgroundId === '20' || backgroundId === '21' || backgroundId === '22') ? 'no-border no-background' : 'box-shadow opacity-9'">
              <div class="ion-padding">
                <ng-container *ngIf="true then checkinSegBlock"></ng-container>
                <ng-container *ngIf="visitorMenuType === 'status'">
                  <ng-container *ngIf="true then itineraryBlock"></ng-container>
                  <ng-container *ngIf="true then attachmentBlock"></ng-container>
                </ng-container>
                
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <ng-container *ngIf="true then greetBlock"></ng-container>
                  <div class="ion-margin-top">
                    <ng-container *ngIf="true then msgBlock"></ng-container>
                  </div>
                
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
                  
                  <div class="ion-text-center">
                    <div>
                      <ng-container *ngIf="true then titleBlock"></ng-container>
                    </div>
                    <div class="ion-margin-vertical">
                      <ng-container *ngIf="true then dateBlock"></ng-container>
                      <ng-container *ngIf="true then timeBlock"></ng-container>
                      <ng-container *ngIf="true then timezoneBlock"></ng-container>
                      <ng-container *ngIf="true then cocktailBlock"></ng-container>
                      <ng-container *ngIf="true then addCalendarBlock"></ng-container>
                    </div>
                    
                    <ng-container *ngIf="true then sessionBlock"></ng-container>
                    <ng-container *ngIf="true then venueBlock"></ng-container>
          
                    <ng-container *ngIf="true then remarkBlock"></ng-container>
                    <ng-container *ngIf="true then itineraryBlock"></ng-container>
                    
                    <ng-container *ngIf="true then attachmentBlock"></ng-container>
                  </div>
                </ng-container>
                
              </div>
            </ion-card>
            <ng-container *ngIf="backgroundId === '15' || backgroundId === '16'">
              <ion-img class="flower-2" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/flower-2.svg"></ion-img>
            </ng-container>
          </div>
        </ng-container>
    
        <ng-container *ngIf="themeId === '1.2' || themeId === '2.2' || themeId === '3.2' || themeId === '8.2' || themeId === '9.2' || themeId === '10.2' || themeId === '11.2' || themeId === '12.2' || themeId === '13.2' || themeId === '14.2' || themeId === '15.2' || themeId === '16.2' || themeId === '17.2' || themeId === '18.1.2' || themeId === '18.2.2' || themeId === '19.1.2' || themeId === '19.2.2' || themeId === '20.1.2' || themeId === '20.2.2' || themeId === '21.1.2' || themeId === '21.2.2' || themeId === '22.1.2' || themeId === '22.2.2'">
          <div class="full-width frame-div">
            <ng-container *ngIf="backgroundId === '15' || backgroundId === '16'">
              <ion-img class="flower-1" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/flower-1.svg"></ion-img>
            </ng-container>
            <ion-card class="center full-width ion-padding" [class]="(backgroundId === '10' || backgroundId === '11' || backgroundId === '15' || backgroundId === '16' || backgroundId === '17' || backgroundId === '18' || backgroundId === '19' || backgroundId === '20' || backgroundId === '21' || backgroundId === '22') ? 'no-border no-background' : 'box-shadow opacity-9'">
              <div class="ion-padding">
                <ng-container *ngIf="true then checkinSegBlock"></ng-container>
                
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <div>
                    <ng-container *ngIf="true then titleBlock"></ng-container>
                  </div>
      
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then greetBlock"></ng-container>
                  </div>
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then msgBlock"></ng-container>
                  </div>
      
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
      
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then dateBlock"></ng-container>
                    <ng-container *ngIf="true then timeBlock"></ng-container>
                    <ng-container *ngIf="true then timezoneBlock"></ng-container>
    
                    <ng-container *ngIf="true then cocktailBlock"></ng-container>
                    <ng-container *ngIf="true then addCalendarBlock"></ng-container>
                  </div>
                  
                  <ng-container *ngIf="true then sessionBlock"></ng-container>
                  <ng-container *ngIf="true then venueBlock"></ng-container>
      
                  <ng-container *ngIf="true then remarkBlock"></ng-container>

                  <ng-container *ngIf="true then itineraryBlock"></ng-container>
                  <ng-container *ngIf="true then attachmentBlock"></ng-container>
                </ng-container>
              </div>
            </ion-card>
            <ng-container *ngIf="backgroundId === '15' || backgroundId === '16'">
              <ion-img class="flower-2" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/flower-2.svg"></ion-img>
            </ng-container>
          </div>
        </ng-container>
    
        <ng-container *ngIf="themeId === '1.3' || themeId === '2.3' || themeId === '3.3' || themeId === '8.3' || themeId === '9.3' ||  themeId === '10.3' || themeId === '11.3' || themeId === '12.3' || themeId === '13.3' || themeId === '14.3' || themeId === '15.3' || themeId === '16.3' || themeId === '17.3' || themeId === '18.1.3' || themeId === '18.2.3' || themeId === '19.1.3' || themeId === '19.2.3' || themeId === '20.1.3' || themeId === '20.2.3' || themeId === '21.1.3' || themeId === '21.2.3' || themeId === '22.1.3' || themeId === '22.2.3'">
          <div class="full-width frame-div">
            <ng-container *ngIf="backgroundId === '15' || backgroundId === '16'">
              <ion-img class="flower-1" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/flower-1.svg"></ion-img>
            </ng-container>
            <ion-card class="center full-width ion-padding" [class]="(backgroundId === '10' || backgroundId === '11' || backgroundId === '15' || backgroundId === '16' || backgroundId === '17' || backgroundId === '18' || backgroundId === '19' || backgroundId === '20' || backgroundId === '21' || backgroundId === '22') ? 'no-border no-background' : 'box-shadow opacity-9'">
              <div class="ion-padding">
                <ng-container *ngIf="true then checkinSegBlock"></ng-container>
                
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <div>
                    <ng-container *ngIf="true then titleBlock"></ng-container>
                  </div>
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then dateBlock"></ng-container>
                    <ng-container *ngIf="true then timeBlock"></ng-container>
                    <ng-container *ngIf="true then timezoneBlock"></ng-container>
                    <ng-container *ngIf="true then cocktailBlock"></ng-container>
                    <ng-container *ngIf="true then addCalendarBlock"></ng-container>
                    <ng-container *ngIf="true then sessionBlock"></ng-container>
                  </div>
                  
                  <ng-container *ngIf="true then venueBlock"></ng-container>
                  <ng-container *ngIf="true then remarkBlock"></ng-container>

                  <ng-container *ngIf="true then itineraryBlock"></ng-container>
                  <ng-container *ngIf="true then attachmentBlock"></ng-container>
                  
                  <div class="line ion-margin-vertical"></div>
      
                  <ng-container *ngIf="true then greetBlock"></ng-container>
                  <div class="ion-margin-top">
                    <ng-container *ngIf="true then msgBlock"></ng-container>
                  </div>
                  
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
                </ng-container>
              </div>
            </ion-card>
            <ng-container *ngIf="backgroundId === '15' || backgroundId === '16'">
              <ion-img class="flower-2" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/flower-2.svg"></ion-img>
            </ng-container>
          </div>
        </ng-container>
  
        <ng-container *ngIf="themeId === '4.1' || themeId === '5.1'">
          <div class="full-width frame-div">
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/4/frame-1.svg"></ion-img>
            <ion-card class="no-radius ion-padding-horizontal ion-no-margin ion-text-center">
              <div>
                <ng-container *ngIf="true then dateGridBlock"></ng-container>
                <div class="font-14 font-bold">
                  <ng-container *ngIf="true then timeBlock"></ng-container>
                </div>
                <ng-container *ngIf="true then timezoneBlock"></ng-container>
  
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <ng-container *ngIf="true then cocktailBlock"></ng-container>
                  <ng-container *ngIf="true then addCalendarBlock"></ng-container>
                </ng-container>
                
                <ng-container *ngIf="true then sessionBlock"></ng-container>
              </div>
            </ion-card>
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/4/frame-2.svg"></ion-img>
            <ion-card class="no-radius ion-padding-horizontal ion-no-margin ion-text-center background-img background-star">
              <div>
                <ng-container *ngIf="true then checkinSegBlock"></ng-container>
                
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <div class="ion-padding-horizontal">
                    <ng-container *ngIf="true then greetBlock"></ng-container>
                    <div class="ion-margin-vertical">
                      <ng-container *ngIf="true then msgBlock"></ng-container>
                    </div>
                  </div>
                  <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                  <div class="ion-margin-horizontal">
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
                </ng-container>
              </div>
              <ion-card class="no-radius background-transparent ion-margin ion-padding border-dotted border-theme-{{ backgroundId }}">
                <ng-container *ngIf="true then titleBlock"></ng-container>
  
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <ng-container *ngIf="true then venueBlock"></ng-container>
                  <ng-container *ngIf="true then remarkBlock"></ng-container>
                </ng-container>
                
                <ng-container *ngIf="true then itineraryBlock"></ng-container>
                <ng-container *ngIf="true then attachmentBlock"></ng-container>
  
                <ion-icon class="icon-star-left" name="star"></ion-icon>
                <ion-icon class="icon-star-right" name="star"></ion-icon>
              </ion-card>
            </ion-card>
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/4/frame-3.svg"></ion-img>
          </div>
        </ng-container>
  
        <ng-container *ngIf="themeId === '4.2' || themeId === '5.2'">
          <div class="full-width frame-div">
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/4/frame-1.svg"></ion-img>
            <ion-card class="no-radius ion-padding-horizontal ion-no-margin ion-text-center">
              <div>
                <ng-container *ngIf="true then dateGridBlock"></ng-container>
                <div class="font-14 font-bold">
                  <ng-container *ngIf="true then timeBlock"></ng-container>
                </div>
                <ng-container *ngIf="true then timezoneBlock"></ng-container>
  
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <ng-container *ngIf="true then cocktailBlock"></ng-container>
                  <ng-container *ngIf="true then addCalendarBlock"></ng-container>
                </ng-container>
                
                <ng-container *ngIf="true then sessionBlock"></ng-container>
              </div>
            </ion-card>
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/4/frame-2.svg"></ion-img>
            <ion-card class="no-radius ion-padding-horizontal ion-no-margin ion-text-center background-img background-star">
              <ng-container *ngIf="true then checkinSegBlock"></ng-container>
              
              <ng-container *ngIf="visitorMenuType === 'qrcode'">
                <div>
                  <ng-container *ngIf="true then titleBlock"></ng-container>
                </div>
                <ng-container *ngIf="true then venueBlock"></ng-container>
    
                <ng-container *ngIf="true then remarkBlock"></ng-container>
              </ng-container>
              
              <ng-container *ngIf="true then itineraryBlock"></ng-container>
              <ng-container *ngIf="true then attachmentBlock"></ng-container>
  
              <ng-container *ngIf="visitorMenuType === 'qrcode'">
                <ion-card class="no-radius background-transparent ion-no-margin ion-margin-vertical ion-padding-horizontal border-dotted border-theme-{{ backgroundId }}">
                  <div class="ion-padding-top">
                    <ng-container *ngIf="true then greetBlock"></ng-container>
                    <div class="ion-margin-vertical">
                      <ng-container *ngIf="true then msgBlock"></ng-container>
                    </div>
                    <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                  </div>
                  
                  <div class="ion-margin-horizontal">
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
                  <ion-icon class="icon-star-left" name="star"></ion-icon>
                  <ion-icon class="icon-star-right" name="star"></ion-icon>
                </ion-card>
              </ng-container>
            </ion-card>          
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/4/frame-3.svg"></ion-img>
          </div>
        </ng-container>
  
        <ng-container *ngIf="themeId === '6.1'">
          <div class="full-width frame-div">
            <!-- <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/6/frame-1.svg"></ion-img> -->
            <ion-card class="theme-6-header no-radius ion-no-margin">
              <ion-img class="full-width-height" src="./assets/wedding/img/visitor/theme/6/header.svg"></ion-img>
              <div class="theme-6-header-text font-white full-width-height vertical-center ion-text-center">
                <ion-text class="font-white font-16 font-bold">{{ lbl.guestPass }}</ion-text>
              </div>
            </ion-card>
            <ng-container *ngIf="visitorMenuType === 'qrcode'">
              <ion-card class="background-frame-6 no-radius ion-padding-horizontal ion-no-margin ion-text-center">
                <div class="ion-padding-top">
                  <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                  <div class="ion-margin-horizontal">
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
                </div>
              </ion-card>
            </ng-container>
            
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/6/frame-2.svg"></ion-img>
            <ion-card class="background-frame-6 no-radius ion-padding-horizontal ion-no-margin ion-text-center background-img background-film">
              <ng-container *ngIf="true then checkinSegBlock"></ng-container>
              <ng-container *ngIf="visitorMenuType === 'status'">
                <ng-container *ngIf="true then itineraryBlock"></ng-container>
                <ng-container *ngIf="true then attachmentBlock"></ng-container>
              </ng-container>
              
              <ng-container *ngIf="visitorMenuType === 'qrcode'">
                <div class="ion-padding-horizontal">
                  <ng-container *ngIf="true then greetBlock"></ng-container>
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then msgBlock"></ng-container>
                  </div>
                  <ion-card class="no-radius background-transparent ion-no-margin ion-padding border-dotted border-theme-{{ backgroundId }}">
                    <ng-container *ngIf="true then titleBlock"></ng-container>
                    <div class="ion-margin-vertical">
                      <ng-container *ngIf="true then dateBlock"></ng-container>
                      <ng-container *ngIf="true then timeBlock"></ng-container>
                      <ng-container *ngIf="true then timezoneBlock"></ng-container>
      
                      <ng-container *ngIf="true then cocktailBlock"></ng-container>
                      <ng-container *ngIf="true then addCalendarBlock"></ng-container>
    
                      <ng-container *ngIf="true then sessionBlock"></ng-container>
                    </div>
                    <ng-container *ngIf="true then venueBlock"></ng-container>
    
                    <ng-container *ngIf="true then remarkBlock"></ng-container>
                    <ng-container *ngIf="true then itineraryBlock"></ng-container>
                    
                    <ng-container *ngIf="true then attachmentBlock"></ng-container>
                  </ion-card>
                </div>
              </ng-container>
              
            </ion-card>          
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/6/frame-3.svg"></ion-img>
          </div>
        </ng-container>
  
        <ng-container *ngIf="themeId === '7.1'">
          <div class="full-width frame-div">
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/7/frame-1.svg"></ion-img>
            <ion-card class="background-frame-6 no-radius ion-padding-horizontal ion-no-margin ion-text-center background-img background-film">
              <div class="ion-padding-horizontal">
                <ng-container *ngIf="true then checkinSegBlock"></ng-container>
                <ng-container *ngIf="visitorMenuType === 'status'">
                  <ng-container *ngIf="true then itineraryBlock"></ng-container>
                  <ng-container *ngIf="true then attachmentBlock"></ng-container>
                </ng-container>
                
                <ng-container *ngIf="visitorMenuType === 'qrcode'">
                  <ion-card class="no-radius background-transparent ion-no-margin ion-padding border-dotted border-theme-{{ backgroundId }}">
                    <ng-container *ngIf="true then titleBlock"></ng-container>
                    <div class="ion-margin-vertical">
                      <ng-container *ngIf="true then dateBlock"></ng-container>
                      <ng-container *ngIf="true then timeBlock"></ng-container>
                      <ng-container *ngIf="true then timezoneBlock"></ng-container>
      
                      <ng-container *ngIf="true then cocktailBlock"></ng-container>
                      <ng-container *ngIf="true then addCalendarBlock"></ng-container>
    
                      <ng-container *ngIf="true then sessionBlock"></ng-container>
                    </div>
                    <ng-container *ngIf="true then venueBlock"></ng-container>
    
                    <ng-container *ngIf="true then remarkBlock"></ng-container>
                    <ng-container *ngIf="true then itineraryBlock"></ng-container>
                    
                    <ng-container *ngIf="true then attachmentBlock"></ng-container>
                  </ion-card>
                  <div class="ion-margin-vertical">
                    <ng-container *ngIf="true then greetBlock"></ng-container>
                    <div class="ion-margin-iovertical">
                      <ng-container *ngIf="true then msgBlock"></ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ion-card>
            
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/7/frame-2.svg"></ion-img>
            <ion-card class="theme-7-header no-radius ion-padding-horizontal ion-no-margin ion-text-center">
              <div class="full-width-height vertical-center ion-text-center">
                <ion-text class="font-white font-16 font-bold">{{ lbl.guestPass }}</ion-text>
              </div>
              <ng-container *ngIf="visitorMenuType === 'qrcode'">
                <div class="ion-padding-top font-white">
                  <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                  <div class="ion-margin-horizontal">
                    <ng-container *ngIf="true then howBlock"></ng-container>
                  </div>
                </div>
              </ng-container>
            </ion-card>
            <ion-img class="frame-img" src="./assets/wedding/img/visitor/theme/7/frame-3.svg"></ion-img>
          </div>
        </ng-container>
  
  
      </div>
      <ng-container *ngIf="backgroundId === '10' || backgroundId === '11' || backgroundId === '17'">
        <div>
          <ion-img class="background-{{ backgroundId }}-2 full-width" src="./assets/wedding/img/visitor/theme/{{ backgroundId }}/background-2.svg"></ion-img>
        </div>
      </ng-container>
    </div>
  </ng-container>
  
</ion-content>
<ion-footer class="ion-no-border background-transparent opacity-9" [class.theme-mode-footer]="themeMode " [class.zh]="language?.code === 'zh'" [class.zh-tw]="language?.code === 'zh-TW'" *ngIf="!themeMode || showHeader" #footer>
  <ion-toolbar>
    <ng-container *ngIf="true then footerBlock"></ng-container>
  </ion-toolbar>
</ion-footer>

<ng-template #titleBlock>
  <ng-container>
    <div *ngIf="title">
      <ion-text class="font-25 font-bold" [innerHTML]="title"></ion-text>
    </div>
  </ng-container>
  
  <div *ngIf="title2">
    <ng-container>
      <ion-text class="font-12 font-italic" [innerHTML]="title2"></ion-text>
    </ng-container>
  </div>
</ng-template>

<!-- <ng-template #canvasBlock>
  <div #canvas></div>
</ng-template> -->

<ng-template #qrcodeBlock>
  <ng-container *ngIf="guest || group">
    <div *ngIf="pageSetting?.qrcode && url">
      <div class="qrcodeDiv" #qrcodeDiv>
        <!-- <div class="qrcode-canvas" id="canvas" name="canvas" #canvas></div>
        <ion-icon class="qrcode-logo" src="./assets/wedding/img/logo/thebigday-logo-round.svg"></ion-icon> -->
        <!-- <ion-icon class="qrcode-logo" src="./assets/wedding/img/logo/thebigday-logo-round.svg"></ion-icon>
        <ngx-qrcode [elementType]="'url'" [value]="url" errorCorrectionLevel="H" cssClass="center qrcodeImg"></ngx-qrcode> -->
        <ng-container  *ngIf="config?.width && url">
          <ngx-qrcode-styling class="qrcode-styling center" [data]="url" [config]="config"></ngx-qrcode-styling>
          <ng-container *ngIf="logoUrl">
            <ion-img class="qrcode-logo" [style.width.px]="logoWidth" [style.height.px]="logoWidth" [src]="logoUrl ? logoUrl : './assets/wedding/img/logo/thebigday-logo-round-primary.webp'"></ion-img>
          </ng-container>
          <!-- <ng-template #defaultLogo>
            <ion-img class="qrcode-logo" [style.width.px]="config.width * 0.5" [style.height.px]="config.height * 0.5" [src]="'./assets/wedding/img/logo/thebigday-logo-round-primary.webp'"></ion-img>
          </ng-template> -->
        </ng-container>
        
      </div>
    </div>
  
    <ng-container *ngIf="true then paxInfoBlock"></ng-container>
    <ng-container *ngIf="true then seatingInfoBlock"></ng-container>
  </ng-container>
</ng-template>

<ng-template #paxInfoBlock>
  <div *ngIf="(guest || group)" (click)="openGroupModal()">
    <div class="ion-text-center font-12" *ngIf="guest?.pax">
      <div *ngIf="guest.pax > 1">
        <div *ngIf="groupName">
          {{ groupName }}
        </div>
        <ng-container *ngIf="pageSetting?.qrcode">({{ groupMemberType }})</ng-container>
      </div>
      <div *ngIf="pageSetting?.pax" [innerHTML]="pax"></div>
    </div>
    <div class="ion-text-center font-12" *ngIf="group?.guestList?.length">
      <div>
        <ng-container *ngIf="pageSetting?.qrcode">({{ groupType }})</ng-container>
      </div>
      <div *ngIf="pageSetting?.pax" [innerHTML]="pax"></div>
    </div>
  </div>
</ng-template>

<ng-template #seatingInfoBlock>
  <div class="ion-text-center font-12" *ngIf="(guest || group) && showSeating">
    <ion-text [innerHTML]="seating"></ion-text>
  </div>
</ng-template>

<ng-template #sessionBlock>
  <div class="ion-margin-vertical ion-text-center font-14" *ngIf="pageSetting?.session">
    <ion-text [innerHTML]="session"></ion-text>
  </div>
</ng-template>

<ng-template #howBlock>
  <div class="ion-margin-horizontal" *ngIf="(guest || group) && themeId">
    <!-- [loop]="pageSetting?.webCheckin && pageSetting?.qrcode && pageSetting?.btn ? true : false" -->
    <ng-container *ngIf="pageSetting?.webCheckin || (pageSetting?.qrcode && pageSetting?.btn)">
      <swiper-container class="full-width" auto-height="true" autoplay-delay="3000" autoplay-disable-on-interaction="false" [loop]="true" [slidesPerView]="btnSlidePerView">
        <swiper-slide *ngIf="pageSetting?.webCheckin">
          <div class="full-width ion-padding-horizontal">
            <ion-button class="font-reg ion-no-margin" [ngClass]="themeId === '7.1' ? 'font-white' : 'btn'" [fill]="btnOutline" expand="block" [color]="btnColor?.['checkin']" (click)="webCheckin()" [disabled]="checkinStatus">
              {{ checkinStatus ? lbl.checked : lbl.checkin }}
             </ion-button>
          </div>
        </swiper-slide>
        <swiper-slide *ngIf="pageSetting?.qrcode && pageSetting?.btn">
          <div class="full-width ion-padding-horizontal">
            <ion-button class="font-reg ion-no-margin" [ngClass]="themeId === '7.1' ? 'font-white' : 'btn'" [fill]="btnOutline" expand="block" [color]="btnColor?.['how']" (click)="openHowPage()">
              {{ lbl.how }}
             </ion-button>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #dateBlock>
  <ng-container *ngIf="themeId">
    <ng-container *ngIf="themeId === '18.1.1' || themeId === '18.1.2' || themeId === '18.1.3' || themeId === '18.2.1' || themeId === '18.2.2' || themeId === '18.2.3' || themeId === '19.1.1' || themeId === '19.1.2' || themeId === '19.1.3' || themeId === '19.2.1' || themeId === '19.2.2' || themeId === '19.2.3' || themeId === '20.1.1' || themeId === '20.1.2' || themeId === '20.1.3' || themeId === '20.2.1' || themeId === '20.2.2' || themeId === '20.2.3' || themeId === '21.1.1' || themeId === '21.1.2' || themeId === '21.1.3' || themeId === '21.2.1' || themeId === '21.2.2' || themeId === '21.2.3' || themeId === '21.2.1' || themeId === '21.2.2' || themeId === '21.2.3' || themeId === '22.1.1' || themeId === '22.1.2' || themeId === '22.1.3' || themeId === '22.2.1' || themeId === '22.2.2' || themeId === '22.2.3' else defaultDateBlock">
      <ng-container *ngIf="themeId === '18.1.1' || themeId === '18.1.2' || themeId === '18.1.3' || themeId === '19.1.1' || themeId === '19.1.2' || themeId === '19.1.3' || themeId === '20.1.1' || themeId === '20.1.2' || themeId === '20.1.3' || themeId === '21.1.1' || themeId === '21.1.2' || themeId === '21.1.3' || themeId === '22.1.1' || themeId === '22.1.2' || themeId === '22.1.3'">
        <ng-container *ngIf="true then date2Block"></ng-container>
      </ng-container>
      <ng-container *ngIf="themeId === '18.2.1' || themeId === '18.2.2' || themeId === '18.2.3' || themeId === '19.2.1' || themeId === '19.2.2' || themeId === '19.2.3' || themeId === '20.2.1' || themeId === '20.2.2' || themeId === '20.2.3' || themeId === '21.2.1' || themeId === '21.2.2' || themeId === '21.2.3' || themeId === '22.2.1' || themeId === '22.2.2' || themeId === '22.2.3'">
        <ng-container *ngIf="true then date3Block"></ng-container>
      </ng-container>
    </ng-container>
    <ng-template #defaultDateBlock>
      <ng-container *ngIf="true then date1Block"></ng-container>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #dateGridBlock>
  <ng-container>
    <ng-container *ngIf="timestamp">
      <ion-grid class="ion-no-padding" fixed>
        <ion-row class="font-10 ion-no-padding">
          <ion-col size="4">
            {{ lbl.date }}
          </ion-col>
          <ion-col size="4">
            {{ lbl.month }}
          </ion-col>
          <ion-col size="4">
            {{ lbl.year }}
          </ion-col>
        </ion-row>
        <ion-row class="font-40 font-bold ion-no-padding">
          <ion-col>{{ formatDate(timestamp, 'dd') }}</ion-col>
          <ion-col class="ion-text-uppercase">{{ getMonth(timestamp) }}</ion-col>
          <ion-col>{{ formatDate(timestamp, 'yy') }}</ion-col>
        </ion-row>
      </ion-grid>
      <div class="font-14 font-bold">
        {{ formatDate(timestamp, 'cccc') }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #date1Block>
  <div *ngIf="date">
    <ion-text class="font-16 font-bold line-130" [innerHTML]="date"></ion-text>
  </div>
</ng-template>

<ng-template #date2Block>
  <ng-container *ngIf="timestamp">
    <ion-grid class="date2-grid ion-no-padding ion-text-center">
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-16">{{ formatDate(timestamp, 'MMMM') }}</ion-col>
      </ion-row>
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-14 border-top border-bottom" size="4">{{ formatDate(timestamp, 'cccc') }}</ion-col>
        <ion-col class="font-40 font-bold" size="4" style="padding: 0 6px;">{{ formatDate(timestamp, 'dd') }}</ion-col>
        <ion-col class="border-top border-bottom" size="4">
          <div class="font-14 ">
            <ion-text class="font-14">{{ formatDate(timestamp, 'h:mma') | uppercase }}</ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-16">{{ formatDate(timestamp, 'yyyy') }}</ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ng-template>

<ng-template #date3Block>
  <ng-container *ngIf="timestamp">
    <ion-grid class="date3-grid ion-no-padding ion-text-center">
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-14 letter-spacing-4">&nbsp;&nbsp;{{ formatDate(timestamp, 'ccc') | uppercase }}&nbsp;&nbsp;</ion-col>
        <ion-col>
          <div class="round-div vertical-center">
            <div><ion-text class="font-14 letter-spacing-4" size="3">{{ formatDate(timestamp, 'MMM') | uppercase }}</ion-text></div>
            <div>
              <ion-text class="font-40 letter-spacing-4 font-bold">{{ formatDate(timestamp, 'dd') }}</ion-text>
            </div>
            <div><ion-text class="font-14 letter-spacing-4" size="3">{{ formatDate(timestamp, 'yyyy') }}</ion-text></div>
          </div>
        </ion-col>
        <ion-col>
          <div class="font-14 letter-spacing-1">
            <ion-text class="font-14 letter-spacing-2">{{ formatDate(timestamp, 'h:mm a') | uppercase }}</ion-text>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ng-template>

<ng-template #timeBlock>
  <ng-container *ngIf="backgroundId">
    <div *ngIf="backgroundId !== '18' && backgroundId !== '19' && backgroundId !== '20' && backgroundId !== '21'">
      <ion-text class="font-14 line-130" *ngIf="startTime" [innerHTML]="startTime"></ion-text>
      <ion-text class="font-14 line-130" *ngIf="startTime && endTime">&nbsp; - &nbsp;</ion-text>
      <ion-text class="font-14 line-130" *ngIf="endTime" [innerHTML]="endTime"></ion-text>
    </div>
  </ng-container>
</ng-template>

<ng-template #timezoneBlock>
  <ng-container>
    <div *ngIf="timezone">
      <ion-text class="font-10 line-120" [innerHTML]="timezone"></ion-text>
    </div>
  </ng-container>
  
</ng-template>
<ng-template #cocktailBlock>
  <ng-container>
    <div class="ion-margin-top" *ngIf="cocktail">
      <ion-text class="font-14 line-130" [innerHTML]="cocktail"></ion-text>
    </div>
  </ng-container>
</ng-template>

<ng-template #venueBlock>
  <ng-container>
    <div class="ion-margin-vertical">
      <div class="margin-5" *ngIf="venue">
        <ion-text class="font-14 line-130" [innerHTML]="venue"></ion-text>
      </div>
  
      <div class="margin-5" *ngIf="hall">
        <ion-text class="font-14 font-italic line-130" [innerHTML]="hall"></ion-text>
      </div>
  
      <div class="margin-5" *ngIf="address">
        <ion-text class="font-10 font-italic" [innerHTML]="address"></ion-text>
      </div>
    </div>
  
    <div class="ion-text-center ion-margin-vertical" *ngIf="pageLocation?.coordinate || themeMode">
      <ion-grid class="full-width" [style.maxWidth.px]="gpsMaxWidth" fixed>
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <ion-col *ngIf="googleMap">
            <ion-img class="gps-img googleMap-icon" src="./assets/wedding/img/visitor/gps/google.png" (click)="openGoogleNav()"></ion-img>
          </ion-col>
          <ion-col *ngIf="waze">
            <ion-icon class="icon-large waze-icon" src="./assets/wedding/img/visitor/gps/waze.svg" (click)="openWazeNav()"></ion-icon>
          </ion-col>
          <ion-col *ngIf="uber">
            <ion-img class="gps-img" src="./assets/wedding/img/visitor/gps/uber.png" (click)="openUberNav()"></ion-img>
          </ion-col>
          <ion-col *ngIf="grab">
            <ion-icon class="icon-large" src="./assets/wedding/img/visitor/gps/grab.svg" (click)="openGrab()"></ion-icon>
          </ion-col>
          <ion-col *ngIf="appleMap">
            <ion-img class="gps-img" src="./assets/wedding/img/visitor/gps/apple.png" (click)="openAppleNav()"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ng-container>
  
</ng-template>

<ng-template #greetBlock>
  <ng-container>
    <ng-container *ngIf="(guest || group) && greeting">
      <div class="font-16 font-bold" [innerHTML]="greeting"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #msgBlock>
  <ng-container>
    <ng-container *ngIf="(guest || group) && msg">
      <div class="font-14 line-110" [innerHTML]="msg"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #remarkBlock>
  <ng-container *ngIf="remark">
    <div class="ion-margin-vertical font-10" [innerHTML]="remark"></div>
  </ng-container>
</ng-template>

<ng-template #itineraryBlock>
  <div class="margin-5" *ngIf="itinerary">
    <ion-text class="font-12" [innerHTML]="itinerary"></ion-text>
  </div>
</ng-template>

<ng-template #addCalendarBlock>
  <div class="ion-margin-top">
    <ion-button class="btn font-reg" [fill]="btnOutline" size="small" [color]="btnColor?.['attachment']" (click)="openCalendar('ical')" *ngIf="pageSetting?.calendar">
      <ion-icon class="btn-icon" slot="start" name="calendar-outline"></ion-icon> {{ lbl.calendar }}
    </ion-button>
  </div>
</ng-template>

<ng-template #footerBlock>
  <ion-grid class="ion-no-padding full-width" fixed>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-align-self-center" size="4">
        <div class="font-8 ion-text-left" style="margin-left: 10px;">
          <ion-text color="dark" [innerHTML]="lbl.copyright">
          </ion-text>
        </div>
      </ion-col>
      <ion-col class="ion-align-self-center" size="4">
        <ion-item class="footer-item background-transparent" lines="none">
          <ng-container *ngIf="!logoUrl || !customLogo">
            <ion-img class="footer-icon ion-no-margin" src="./assets/wedding/img/logo/thebigday-logo-round-primary.webp" slot="start"></ion-img>
            <ion-text class="font-bold" color="dark" (click)="goWebsite()">
              <span class="margin-5 font-10">thebigdays</span>
            </ion-text>
          </ng-container>
          <ion-text class="center ion-text-center font-bold" color="dark" *ngIf="logoUrl && customLogo">
            <ion-img class="footer-icon center" style="vertical-align: middle;" [src]="logoUrl"></ion-img>
          </ion-text>
        </ion-item>
        
      </ion-col>
      <ion-col class="ion-align-self-center" size="4" *ngIf="language?.code && pageLangList?.length > 1">
        <div class="ion-text-right" style="margin-right: 10px;" (click)="presentLanguagePicker(language.code)">
          <ion-text color="dark" class="font-10" *ngIf="language?.name">
            <ion-icon src="./assets/wedding/icon/general/language.svg"></ion-icon> {{ language.name }}
          </ion-text>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>

<ng-template #attachmentBlock>
  <div class="full-wdith" #attachmentOuterDiv>
    <div class="full-width" *ngIf="(pageSetting?.floorplan?.url || pageSetting?.menu?.url || pageSetting?.attachment?.[0]?.url)" #attachmentDiv>
      <swiper-container class="visitor-swiper-btn" autoplay-delay="3000" autoplay-disable-on-interaction="false" [loop]="true" [slidesPerView]="attachmentSlidePerView">
        <swiper-slide *ngIf="pageSetting?.floorplan?.url">
          <div class="full-width ion-padding-horizontal">
            <ion-button class="btn font-reg center ion-text-wrap" [fill]="btnOutline" expand="block" [color]="btnColor?.['attachment']" (click)="openFile(pageSetting.floorplan)">
              <ion-icon class="btn-icon margin-5" src="./assets/wedding/icon/visitor/floorplan.svg"></ion-icon> {{ floorplan }}
            </ion-button>
          </div>
        </swiper-slide>
        <swiper-slide *ngIf="pageSetting?.menu?.url">
          <div class="full-width ion-padding-horizontal">
            <ion-button class="btn font-reg center ion-text-wrap" [fill]="btnOutline" expand="block" [color]="btnColor?.['attachment']" (click)="openFile(pageSetting.menu)">
              <ion-icon class="btn-icon margin-5" src="./assets/wedding/icon/visitor/menu.svg"></ion-icon> {{ menu }}
            </ion-button>
          </div>
        </swiper-slide>
        <swiper-slide *ngIf="pageSetting?.attachment?.[0]?.url">
          <div class="full-width ion-padding-horizontal">
            <ion-button class="btn font-reg center ion-text-wrap" [fill]="btnOutline" expand="block" [color]="btnColor?.['attachment']" (click)="openFile(pageSetting.attachment[0])">
              <ion-icon class="btn-icon margin-5" name="document-outline"></ion-icon> {{ attachment }}
            </ion-button>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
  
</ng-template>

<ng-template #checkinSegBlock>
  <ng-container *ngIf="checkinStatus">
    <div class="ion-padding-bottom">
      <ion-segment class="seg-bar" [(ngModel)]="visitorMenuType" (ionChange)="menuChanged($event)" scrollable>
        <ion-segment-button value="qrcode">
          <ion-text class="font-10">{{ lbl.qrcode }}</ion-text>
        </ion-segment-button>
        <ion-segment-button value="status">
          <ion-text class="font-10">{{ lbl.status }}</ion-text>
        </ion-segment-button>
      </ion-segment>
    </div>
    <ng-container *ngIf="true then checkinContentBlock"></ng-container>
  </ng-container>
</ng-template>

<ng-template #checkinContentBlock>
  <div class="full-width" *ngIf="visitorMenuType === 'status'">
    <div #checkinDiv>
      <ng-container>
        <div class="gif-div ion-text-center center" [style.width]="'100%'" [style.height]="'100%'" #gifDiv>
          <ion-img class="checked-gif center" src="./assets/wedding/gif/visitor/checked.gif" *ngIf="!stopGif"></ion-img>
          <ng-container *ngIf="stopGif">
            <ng-container *ngIf="guestSeating">
              <ng-container *ngIf="guestSeating && guestSeating !== '-' else noSeatingBlock">
                <div class="full-width-height ion-padding">
                  <ion-card class="ion-padding full-width-height checked-card center">
                    <div class="full-width-height ion-text-center center vertical-center">
                      <div>
                        <ion-text class="font-14">{{ lbl.checkinSeating }}</ion-text>
                      </div>
                      <div class="ion-margin-top ion-text-wrap">
                        <ion-text class="font-40 font-bold">{{ guestSeating }}</ion-text>
                      </div>
                    </div>
                  </ion-card>
                </div>
              </ng-container>
              <ng-template #noSeatingBlock>
                <ion-img class="checked-gif center" src="./assets/wedding/gif/visitor/checked.jpg"></ion-img>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
    
    <div>
      <div>
        <ion-text class="font-28">{{ lbl.checkinTitle }}</ion-text>
      </div>
      <div class="ion-margin-vertical">
        <ion-text class="font-14">{{ lbl.checkinMsg }}</ion-text>
      </div>
    </div>
  </div>
</ng-template>
