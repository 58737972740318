<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'FILTER.title_field' | translate: { field: 'SEATING.title.list' | translate: { seating: seatingTypeName } } }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="reset()" [disabled]="isEmptyForm() && !sorting">
        <ion-icon color="dark" slot="icon-only" name="refresh"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" color="light">
  <ion-item class="ion-item-no-padding full-width" color="light" lines="none">
    <ion-label position="stacked" color="dark">{{ 'LBL.sorting' | translate }}</ion-label>
    <ion-item class="ion-item-no-padding full-width" color="light" lines="none">
      <ion-select class="prompt-input-item" [(ngModel)]="sorting">
        <ion-select-option value="">
          {{ 'LBL.pin' | translate }} ({{ 'LBL.default' | translate }})
        </ion-select-option>
        <ion-select-option value="name">
          {{ 'SEATING.lbl.name' | translate: { seating: seatingTypeName } }}
        </ion-select-option>
        <ion-select-option value="count">
          {{ 'SEATING.lbl.count' | translate: { seating: seatingTypeName } }}
        </ion-select-option>
        <ion-select-option value="maxGuest">
          {{ 'SEATING.lbl.max_guest' | translate: { seating: seatingTypeName } }}
        </ion-select-option>
        <ion-select-option value="availableSeating">
          {{ 'SEATING.lbl.available' | translate: { seating: seatingTypeName } }}
        </ion-select-option>
        <ion-select-option value="guestCount">
          {{ 'SEATING.lbl.guest_count' | translate }}
        </ion-select-option>
        <ion-select-option value="createdTime">
          {{ 'LBL.created_time' | translate }}
        </ion-select-option>
        <ion-select-option value="updatedTime">
          {{ 'LBL.updated_time' | translate }}
        </ion-select-option>
      </ion-select>
      <ion-buttons slot="end">
        <ion-button fill="clear" (click)="desc = !desc;" style="margin-right: 0;">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/sort-alpha-asc.svg" *ngIf="!desc"></ion-icon>
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/sort-alpha-desc.svg" *ngIf="desc"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
    <div class="error-div">
    </div>
  </ion-item>
  <form [formGroup]="filterForm" (ngSubmit)="filterFormSubmit()" *ngIf="filterForm">
    <ion-list class="background-transparent">
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.giftType">
        <ion-label position="stacked" color="dark">{{ 'GIFT.lbl.type' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="giftType">
          <ion-select-option value="cash">
            {{ 'LIST.gift.cash' | translate }}
          </ion-select-option>
          <ion-select-option value="other">
            {{ 'LIST.gift.other' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.currency && currencyList?.length">
        <ion-label position="stacked" color="dark">{{ 'LBL.currency' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="currency">
          <ion-select-option *ngFor="let currency of currencyList" [value]="currency">
            {{ currency }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.amount && amount">
        <ion-label position="stacked" color="dark">
          {{ 'LBL.amount' | translate }} 
          <span class="font-12" *ngIf="filterForm?.value?.amount?.lower || filterForm?.value?.amount?.upper">
            ( {{ filterForm?.value?.amount?.lower ? filterForm.value.amount.lower : 'min' }} - {{ filterForm?.value?.amount?.upper ? filterForm.value.amount.upper : 'max' }} )
          </span>
        </ion-label>
        <ion-range formControlName="amount" debounce="500" dualKnobs="true" [min]="amount.lower" [max]="amount.upper" pin="true" snaps="false" step="1"></ion-range>
        <div class="error-div">
        </div>
      </ion-item>
      <div *ngIf="showField.invitedBy && invitedByList?.length > 1">
        <ion-label class="custom-stacked-label" position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}</ion-label>
        <ion-segment scrollable formControlName="invitedBy" *ngIf="invitedByList">
          <ion-segment-button [value]="invitedBy" *ngFor="let invitedBy of invitedByList">
            <ion-text>
              <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
              <ng-container *ngIf="!invitedBy.custom">{{ 'LIST.invited_by.' + invitedBy.value | translate }}</ng-container>
            </ion-text>
          </ion-segment-button>
        </ion-segment>
        <div class="error-div">
        </div>
      </div>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.category" (click)="presentSettingFieldModal('category', filterForm?.value?.category)">
        <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ category }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.status">
        <ion-label position="stacked" color="dark">{{ 'LBL.status' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="attending">
          <ion-select-option value="attending">
            {{ 'LIST.attending_status.attending' | translate }}
          </ion-select-option>
          <ion-select-option value="pending">
            {{ 'LIST.attending_status.pending' | translate }}
          </ion-select-option>
          <ion-select-option value="not_attending">
            {{ 'LIST.attending_status.not_attending' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.seating">
        <ion-label position="stacked" color="dark">{{ seatingTypeName }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="seating">
          <ion-select-option value="full">
            {{ 'LIST.occupancy.full' | translate }}
          </ion-select-option>
          <ion-select-option value="half">
            {{ 'LIST.occupancy.half' | translate }}
          </ion-select-option>
          <ion-select-option value="empty">
            {{ 'LIST.occupancy.empty' | translate }}
          </ion-select-option>
          <ion-select-option value="overload">
            {{ 'LIST.occupancy.overload' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.dietaryReq" (click)="presentSettingFieldModal('dietary_req', filterForm?.value?.dietaryReq)">
        <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.dietary_req' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ dietaryReq }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.specialReq" (click)="presentSettingFieldModal('special_req', filterForm?.value?.specialReq)">
        <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.special_req' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ specialReq }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.session" (click)="presentSettingFieldModal('session', filterForm?.value.session)">
        <ion-label position="stacked" color="dark">{{ 'LBL.session' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ session }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.mobile">
        <ion-label position="stacked" color="dark">{{ 'MOBILE.lbl.no' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="mobile">
          <ion-select-option value="assigned">
            {{ 'LIST.assign_status.assigned' | translate }}
          </ion-select-option>
          <ion-select-option value="not_assign">
            {{ 'LIST.assign_status.not_yet_assign' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.email">
        <ion-label position="stacked" color="dark">{{ 'LBL.email' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="email">
          <ion-select-option value="assigned">
            {{ 'LIST.assign_status.assigned' | translate }}
          </ion-select-option>
          <ion-select-option value="not_assign">
            {{ 'LIST.assign_status.not_yet_assign' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.group">
        <ion-label position="stacked" color="dark">{{ 'LIST.group.group' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="group">
          <ion-select-option value="individual">
            {{ 'LIST.group.individual' | translate }}
          </ion-select-option>
          <ion-select-option value="group">
            {{ 'LIST.group.group' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.reserved">
        <ion-label position="stacked" color="dark">{{ 'SEATING.lbl.reserved' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="reserved">
          <ion-select-option value="none">
            {{ 'LBL.none' | translate }}
          </ion-select-option>
          <ion-select-option value="reserved">
            {{ 'SEATING.lbl.reserved' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>

      <!-- <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.createdBy" (click)="presentAccountUserModal('createBy')">
        <ion-label position="stacked" color="dark">{{ 'LBL.created_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.createBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.updateBy" (click)="presentAccountUserModal('updateBy')">
        <ion-label position="stacked" color="dark">{{ 'LBL.updated_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.updateBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField.giftBy" (click)="presentAccountUserModal('giftBy')">
        <ion-label position="stacked" color="dark">{{ 'GIFT.lbl.by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.giftBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item> -->
    </ion-list>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="filterFormSubmit()" [disabled]="!filterForm || !filterForm.valid">
      {{ 'BTN.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>