<ion-header class="header-light">
  <ion-toolbar class="gradient-primary-right">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'GIFT.title.guest' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="gradient-primary-right">
    <ion-grid class="ion-text-center">
      <ion-row>
        <ion-col style="padding: 0 10px;" *ngIf="guest?.status?.checkin">
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/checkin/tick-round.svg"></ion-icon>
            <div class="font-10">{{ 'LIST.checkin.attended' | translate }}</div>
          </div>
        </ion-col>
        <ion-col style="padding: 0 10px;" *ngIf="!guest?.status?.checkin">
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/checkin/close-round.svg"></ion-icon>
            <div class="font-10">{{ 'LIST.checkin.not_attend' | translate }}</div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <ion-toolbar class="gradient-primary-right">
    <ng-container *ngIf="guest">
      <div class="ion-margin-vertical  ion-text-center">
        <div class="margin-5 ion-text-wrap">
          <ion-text class="font-18 font-bold" *ngIf="guest?.name">{{ guest.name }}</ion-text>
        </div>
        <div class="margin-5 ion-text-wrap">
          <ion-text class="font-12 font-italic" *ngIf="guest?.nickname">{{ guest.nickname }}</ion-text>
        </div>
        <div class="margin-5 ion-text-wrap">
          <ion-text class="font-10 line-110" color="black">
            <ng-container *ngIf="invitedBy">
              {{ invitedBy }}
            </ng-container>
            <ng-container *ngIf="invitedBy && category">&nbsp;|&nbsp;</ng-container>
            <ng-container *ngIf="category">
              {{ category }}
            </ng-container>
          </ion-text>
        </div>
      </div>
      
      <ion-grid class="ion-text-center" fixed>
        <ion-row>
          <ion-col size="4">
            <ion-card class="ion-no-margin vertical-center card-status" button>
              <div class="font-10">{{ 'LBL.status' | translate }}</div>
              <div class="center">
                <ion-icon class="font-26 margin-5" src="./assets/wedding/icon/guest/status/pending-round-background.svg" *ngIf="guest?.status?.attending === 'pending'"></ion-icon>
                <ion-icon class="font-26 margin-5" src="./assets/wedding/icon/guest/status/attending-round-background.svg" *ngIf="guest?.status?.attending === 'attending'"></ion-icon>
                <ion-icon class="font-26 margin-5" src="./assets/wedding/icon/guest/status/not-attending-round-background.svg" *ngIf="guest?.status?.attending === 'not_attending'"></ion-icon> 
              </div>
              <div>
                <ng-container *ngIf="guest?.status?.attending === 'pending'">{{ 'LIST.attending_status.pending' | translate }}</ng-container>
                <ng-container *ngIf="guest?.status?.attending === 'attending'">{{ 'LIST.attending_status.attending' | translate }}</ng-container>
                <ng-container *ngIf="guest?.status?.attending === 'not_attending'">{{ 'LIST.attending_status.not_attending' | translate }}</ng-container>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="4">
            <ion-card class="ion-no-margin vertical-center card-status" (click)="presentGroupModal(group)" button>
              <div class="font-10">{{ 'LIST.group.group' | translate }}</div>
              <div class="center">
                <ion-icon class="font-22 margin-5" src="./assets/wedding/icon/guest/group-green.svg" *ngIf="guest.groupId"></ion-icon>
                <ion-icon class="font-22 margin-5" src="./assets/wedding/icon/guest/individual.svg" *ngIf="!guest.groupId"></ion-icon>
              </div>
              <div>
                <ng-container *ngIf="guest.groupId && group else groupBlock">
                  {{ group?.memberList?.length }}&nbsp;{{ 'GUEST.lbl.guests' | translate}}
                </ng-container>
                <ng-template #groupBlock>-</ng-template>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="4">
            <ion-card class="ion-no-margin vertical-center card-status" (click)="presentSeatingDetailModal(guest.seating)" button>
              <div><ion-text class="font-10 font-semi">{{ seatingTypeName }}</ion-text></div>
              <div class="center">
                <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/guest/seating-green.svg" *ngIf="guest.seating"></ion-icon>
                <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/guest/seating-outline.svg" *ngIf="!guest.seating"></ion-icon>
              </div>
              <div class="text-ellipsis">
                <ion-text class="font-14" color="dark">
                  <ng-container *ngIf="guest.seating">
                    <ng-container *ngIf="guestSeating">
                      <div *ngIf="guestSeating?.count > 1">
                        <span class="font bold ion-text-uppercase font-10 free-seating">
                          {{ 'SEATING.lbl.free_seating' | translate }}
                        </span>
                      </div>
                    </ng-container>
                    <fit-line [minFontSize]="10" [maxFontSize]="14" [calcOnResize]="true">
                      <div class="text-ellipsis">
                        <ion-label color="dark">{{ guest.seating }}</ion-label>
                      </div>
                    </fit-line>
                  </ng-container>
                  <span *ngIf="!guest.seating">
                    -
                  </span>
                </ion-text>
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <app-select-all [total]="guest?.giftList?.length" [selected]="selectedGiftList?.length" [selectAll]="selectAll" [editMode]="editMode" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="font-12 font-italic ion-margin-horizontal" color="medium">{{ 'GIFT.lbl.gift_records' | translate }}</div>
  
  <ion-list class="full-width gift-list" *ngIf="giftList?.length else notFound">
    <div class="full-width viewport-div" [style.height.px]="viewportHeight">
      <cdk-virtual-scroll-viewport [itemSize]="87">
        <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
          <app-gift-item *cdkVirtualFor="let gift of giftList; trackBy: trackByFn; let i = index;" [setGift]="gift" [giftModal]="giftModal" [index]="i" [editMode]="editMode" [selectedGiftList]="selectedGiftList" (setSelectedGift)="setSelectedGift($event)"></app-gift-item>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
      
    <ion-card class="ion-no-margin ion-padding-vertical" (click)="presentGuestLogModal()" button>
      <ion-item lines="none" detail>
        <ion-icon class="icon-small" slot="start" src="./assets/wedding/icon/guest/log.svg" style="vertical-align: middle;"></ion-icon>
        {{ 'BTN.history' | translate}}
      </ion-item>
    </ion-card>
  </ion-list>

  <ng-template #notFound>
    <ion-item class="full-height vertical-center background-transparent ion-item-no-padding ion-text-center" lines="none">
      <ion-label class="ion-no-margin" color="medium">
        <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
      </ion-label>
    </ion-item>
  </ng-template>
  <ion-fab horizontal="end" vertical="bottom" slot="fixed">
    <ion-fab-button color="primary" (click)="toggleEditMode()">
      <ion-icon name="chevron-up" *ngIf="!editMode"></ion-icon>
      <ion-icon name="close" *ngIf="editMode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer *ngIf="editMode">
  <app-bulk-action [mode]="'gift'" [selectedList]="selectedGiftList" [count]="guest?.giftList?.length" [setReady]="true" (action)="bulkAction($event)" ></app-bulk-action>
</ion-footer>
