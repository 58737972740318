<ion-header #header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="step === 1 || step === 2">
        <ng-container *ngIf="settingForm.value.group">
          {{ 'SEATING.title.add_group' | translate: { seating: seatingTypeName } }}
        </ng-container>
        <ng-container *ngIf="!settingForm.value.group">
          <ng-container *ngIf="settingForm.value.count > 1 else titleBlock">
            {{ 'SEATING.title.add_bulk' | translate: { seating: seatingTypeName } }}
          </ng-container>
          <ng-template #titleBlock>
            {{ 'CRUD.new_field' | translate: { field: seatingTypeName } }}
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="step === 3">
        {{ 'BTN.preview' | translate }}
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="white" #content>
  <ng-container *ngIf="step === 1">
    <form class="ion-padding" [formGroup]="settingForm" (ngSubmit)="seatingFormSubmit()" *ngIf="settingForm">
      <ion-item class="ion-item-no-padding" color="white" lines="none">
        <ion-label position="stacked" color="dark">{{ 'SEATING.lbl.count' | translate: { seating: seatingTypeName } }}</ion-label>
        <ion-item class="ion-item-no-padding full-width" lines="none">
          <ion-input class="prompt-input ion-text-center" formControlName="count" min="1" type="number" inputmode="numeric" tabindex="1">
            <ion-button class="qty-btn" slot="start" (click)="minusSeating()" fill="clear">
              <ion-icon name="remove"></ion-icon>
            </ion-button>
            <ion-button class="qty-btn" slot="end" (click)="addSeating()" fill="clear">
              <ion-icon name="add"></ion-icon>
            </ion-button>
          </ion-input>
          
        </ion-item>
      </ion-item>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.count">
          <div class="error-message" *ngIf="settingForm.get('count').hasError(validation.type) && (settingForm.get('count').dirty || settingForm.get('count').touched)">
            <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
      <ion-card class="ion-no-margin ion-margin-bottom ion-padding-vertical" color="secondary" *ngIf="settingForm.value.count > 1">
        <ion-item color="secondary" lines="none">
          <ion-toggle formControlName="group" color="success" (ionChange)="groupChanged()">
            <ion-text class="ion-text-wrap" color="dark">
              <div class="font-12 font-bold">{{ 'SEATING.setting.group.title' | translate: { seating: seatingTypeName } }}</div>
              <div class="font-10 font-italic">
                {{ 'SEATING.setting.group.msg' | translate: { seating: seatingTypeName } }}
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>
      
      <ion-item class="ion-item-no-padding" color="white" lines="none">
        <ion-label position="stacked" color="dark">
          <ng-container *ngIf="settingForm.value.group">{{ 'SEATING.lbl.total_guest' | translate: { seating: seatingTypeName } }}</ng-container>
          <ng-container *ngIf="!settingForm.value.group">{{ 'SEATING.lbl.max_guest' | translate: { seating: seatingTypeName } }}</ng-container>
        </ion-label>
        <ion-item class="ion-item-no-padding full-width" lines="none">
          <ion-input class="prompt-input ion-text-center" formControlName="maxGuest" min="1" type="number" inputmode="numeric" tabindex="2">
            <ion-button class="qty-btn" slot="start" (click)="minusMaxGuest()" fill="clear">
              <ion-icon name="remove"></ion-icon>
            </ion-button>
            <ion-button class="qty-btn" slot="end" (click)="addMaxGuest()" fill="clear">
              <ion-icon name="add"></ion-icon>
            </ion-button>
          </ion-input>
          
        </ion-item>
      </ion-item>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.maxGuest">
          <div class="error-message" *ngIf="settingForm.get('maxGuest').hasError(validation.type) && (settingForm.get('maxGuest').dirty || settingForm.get('maxGuest').touched)">
            <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
      <ng-container>
        <ion-card class="ion-no-margin ion-margin-bottom ion-padding-vertical" color="secondary" *ngIf="!settingForm?.value?.alphabet && !settingForm?.value?.skip4">
          <ion-item color="secondary" lines="none">
            <ion-toggle formControlName="replace4" color="success">
              <ion-text class="ion-text-wrap" color="dark">
                <div class="font-12 line-150 font-bold" [innerHtml]="'SEATING.setting.replace4.title' | translate: { seating: seatingTypeName }"></div>
                <div class="font-10 font-italic">
                  <ion-text [innerHtml]="'SEATING.setting.replace4.msg' | translate"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
  
        <ion-card class="ion-no-margin ion-margin-vertical ion-padding-vertical" color="secondary" *ngIf="!settingForm?.value?.alphabet && !settingForm?.value?.replace4">
          <ion-item color="secondary" lines="none">
            <ion-toggle formControlName="skip4" color="success">
              <ion-text class="ion-text-wrap" color="dark">
                <div class="font-12 line-150 font-bold" [innerHtml]="'SEATING.setting.skip4.title' | translate: { seating: seatingTypeName }"></div>
                <div class="font-10 font-italic">
                  <ion-text [innerHtml]="'SEATING.setting.skip4.msg' | translate"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
        
        <ion-card class="ion-no-margin ion-margin-vertical ion-padding-vertical" color="secondary" *ngIf="!settingForm?.value?.skip4 && !settingForm?.value?.replace4">
          <ion-item color="secondary" lines="none">
            <ion-toggle formControlName="alphabet" color="success">
              <ion-text class="ion-text-wrap" color="dark">
                <div class="font-12 line-120 font-bold" [innerHtml]="'SEATING.setting.alphabet.title' | translate: { seating: seatingTypeName }"></div>
                <div class="font-10 font-italic">
                  <ion-text [innerHtml]="'SEATING.setting.alphabet.msg' | translate"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
      </ng-container>

      <ion-card class="ion-no-margin  ion-padding-vertical" [ngClass]="settingForm?.value?.count > 1 ? 'ion-margin-bottom' : 'ion-margin-bottom'" color="secondary" *ngIf="!newMode">
        <ion-item color="secondary" lines="none">
          <ion-toggle formControlName="reserved" color="success">
            <ion-text class="ion-text-wrap" color="dark">
              <div class="font-12 ine-150 font-bold">{{ 'SEATING.setting.reserved.title' | translate: { seating: seatingTypeName } }}</div>
              <div class="font-10 font-italic">
                <ion-text>{{ 'SEATING.setting.reserved.msg' | translate: { seating: seatingTypeName } }}</ion-text>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>
      
    </form>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <form class="ion-padding" [formGroup]="nameForm">
      <ion-item class="ion-item-no-padding" color="white" lines="none">
        <ion-label position="stacked" color="dark">
          <ng-container *ngIf="settingForm.value.count > 1 else nameBlock">
            {{ 'SEATING.lbl.start_name' | translate: { seating: seatingTypeName } }}
          </ng-container>
          <ng-template #nameBlock>
            {{ 'SEATING.lbl.name' | translate: { seating: seatingTypeName } }}
          </ng-template>
        </ion-label>
        <ion-input class="prompt-input" formControlName="name" (ionChange)="checkNameForm()" debounce="800" type="text" inputmode="text" autocapitalize="words" tabindex="3"></ion-input>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.name">
            <div class="error-message" *ngIf="nameForm.get('name').hasError(validation.type) && (nameForm.get('name').dirty || nameForm.get('name').touched)">
              <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>

      <div *ngIf="invitedByList?.length > 1">
        <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}</ion-label>
        <ion-segment scrollable formControlName="invitedBy" (ionChange)="checkNameForm()" *ngIf="invitedByList?.length">
          <ion-segment-button [value]="invitedBy.value" *ngFor="let invitedBy of invitedByList">
            <ion-text>
              <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
              <ng-container *ngIf="!invitedBy.custom">{{ 'LIST.invited_by.' + invitedBy.value | translate }}</ng-container>
            </ion-text>
          </ion-segment-button>
        </ion-segment>
        <div class="error-div"></div>
      </div>

      <ion-item class="ion-item-no-padding" lines="none" color="white" (click)="presentSettingFieldModal('category', nameForm?.value?.category)">
        <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ category }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div"></div>
      </ion-item>
    </form>
  </ng-container>

  <ng-container *ngIf="step === 3 || (step === 2 && (settingForm.value.count === 1 || settingForm.value.group))">
    <ion-list class="seating-list full-width-height ion-padding-vertical" #seatingListElement>
      <ion-grid fixed>
        <ion-row class="ion-justify-content-center">
          <ng-container *ngFor="let seating of seatingList; trackBy: trackByFn; let i = index;">
            <ion-col [size]="settingForm.value.group ? '12' : 'auto'" class="ion-align-self-center" *ngIf="seating?.seatingId && seating?.name && !seating?.favorite">
              <app-seating-view [ready]="true" [width]="contentWidth" [gridSize]="gridSize" [gridNameHeight]="gridNameHeight" [seating]="seating" [seatingView]="'grid'" [disableWatching]="true" (seatingClick)="presentSeatingEditModal(seating, i)"></app-seating-view>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-grid>
    </ion-list>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="ion-padding-horizontal">
      <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" *ngIf="step === 1" (click)="seatingFormSubmit()" [disabled]="!settingForm.valid">
        {{ 'BTN.next' | translate }}
      </ion-button>
      <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" *ngIf="step === 2" (click)="nameFormSubmit(true)" [disabled]="!nameForm.valid">
        <ng-container *ngIf="settingForm.value.count === 1 || settingForm.value.group">{{ 'BTN.confirm' | translate }}</ng-container>
        <ng-container *ngIf="settingForm.value.count > 1 && !settingForm.value.group">{{ 'BTN.preview' | translate }}</ng-container>
      </ion-button>
      <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" *ngIf="step === 3" (click)="saveSeatingList()">
        {{ 'BTN.confirm' | translate }}
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>