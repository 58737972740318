<ion-header class="header-light" #header>
  <ion-toolbar [color]="module === 'trash' ? 'danger' : 'light'">  
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()" *ngIf="!pageMode">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
      <ion-menu-button *ngIf="pageMode">
        <ion-icon class="icon-menu" name="menu"></ion-icon>
        <app-inbox-count></app-inbox-count>
      </ion-menu-button>
    </ion-buttons> 
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="title">
        {{ title }}
      </ng-container>
      <ng-container *ngIf="!title">
        <ng-container *ngIf="mode === 'attending'">
          {{ 'GUEST.title.attending' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'qrcode'">
          {{ 'GUEST.title.qrcode' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'seating'">
          {{ 'GUEST.title.seating' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'group'">
          {{ 'GUEST.title.group' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'preview'">
          {{ 'BTN.preview' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'invites'">
          {{ 'WEBSITE.link.title.private_link' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'invites'">
          {{ 'WEBSITE.form.guest_list' | translate }}
        </ng-container>
        <ng-container *ngIf="mode === 'list' || mode === 'gift'">
          <ng-container *ngIf="module === 'trash'">
            {{ 'GUEST.title.deleted' | translate }}
          </ng-container>
          <ng-container *ngIf="module !== 'trash'">
            {{ 'MENU.guest_list' | translate }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="module === 'checkin' && mode !== 'group' && mode !== 'list'">
          <ng-container *ngIf="pageMode">
            {{ 'BTN.search' | translate }}
          </ng-container>
          <ng-container *ngIf="!pageMode">
            {{ 'GUEST.title.checkin' | translate }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="module === 'blast'">
          <ng-container *ngIf="mode === 'sms' || mode === 'whatsapp'">
            {{ 'BLAST.template.recipients' | translate }}
          </ng-container>
          <ng-container *ngIf="mode === 'sms-result'">
            {{ 'BLAST.status.sms_result' | translate }}
          </ng-container>
          <ng-container *ngIf="mode === 'whatsapp-result'">
            {{ 'BLAST.status.whatsapp_result' | translate }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding background-transparent" *ngIf="type && (mode === 'attending' || mode === 'qrcode' || mode === 'seating' || mode === 'group' || mode === 'checkin' || type?.giftReceived || mode === 'sms' || mode === 'whatsapp' || mode === 'sms-result' || mode === 'whatsapp-result')">
    <div class="content-div seg-div full-width">
      <!-- <ng-container *ngIf="module === 'invites'">
        <ion-segment [(ngModel)]="type.rsvp" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button [value]="true">
            <ion-label>{{ 'MODULE.list.rsvp' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="false">
            <ion-label>{{ 'WEBSITE.form.no_rsvp' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container> -->
      <ng-container *ngIf="mode === 'attending' && type?.attending">
        <ion-segment [(ngModel)]="type.attending" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="attending">
            <ion-label>{{ 'LIST.attending_status.attending' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="pending">
            <ion-label>{{ 'LIST.attending_status.pending' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="not_attending">
            <ion-label>{{ 'LIST.attending_status.not_attending' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
      <ng-container *ngIf="mode === 'qrcode' && type?.qrcode">
        <ion-segment [(ngModel)]="type.qrcode" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="sent">
            <ion-label>{{ 'LIST.send_status.sent' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="not_send">
            <ion-label>{{ 'LIST.send_status.not_send' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
      <ng-container *ngIf="mode === 'seating' && type?.seating">
        <ion-segment [(ngModel)]="type.seating" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="assigned">
            <ion-label>{{ 'LIST.assign_status.assigned' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="not_assign">
            <ion-label>{{ 'LIST.assign_status.not_yet_assign' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
      <ng-container *ngIf="mode === 'group' && type?.group">
        <ion-segment [(ngModel)]="type.group" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="individual">
            <ion-label>{{ 'LIST.group.individual' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="group">
            <ion-label>{{ 'LIST.group.group' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
      <ng-container *ngIf="mode === 'checkin' && type?.checkin">
        <ion-segment [(ngModel)]="type.checkin" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="attended">
            <ion-label>{{ 'LIST.checkin.attended' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="not_attend">
            <ion-label>{{ 'LIST.checkin.not_attend' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
      <ng-container *ngIf="type?.giftReceived">
        <ion-segment [(ngModel)]="type.giftReceived" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="received">
            <ion-label>{{ 'GIFT.lbl.received' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="not_receive">
            <ion-label>{{ 'GIFT.lbl.not_receive' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
      <ng-container *ngIf="mode === 'sms' || mode === 'whatsapp'">
        <ion-grid class="full-width" fixed>
          <ion-row>
            <ion-col size="6">
              <ion-segment [(ngModel)]="type.mobile" (ionChange)="setupGuestList();" scrollable>
                <ion-segment-button value="with">
                  <ion-label>{{ 'LIST.mobile.with' | translate }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="without">
                  <ion-label>{{ 'LIST.mobile.without' | translate }}</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
            <ion-col size="6">
              <ion-segment [(ngModel)]="type.blastSentType" (ionChange)="setupGuestList();" scrollable>
                <ion-segment-button value="sent">
                  <ion-label>{{ 'LIST.send_status.sent' | translate }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="not_send">
                  <ion-label>{{ 'LIST.send_status.not_send' | translate }}</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ng-container>
      <ng-container *ngIf="(mode=== 'sms-result' || mode === 'whatsapp-result') && type?.blastDeliveredType && type?.usageId">
        <ion-segment [(ngModel)]="type.blastDeliveredType" (ionChange)="setupGuestList();" scrollable>
          <ion-segment-button value="all">
            <ion-label>{{ 'LBL.all' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="read" *ngIf="mode === 'whatsapp-result'">
            <ion-label>{{ 'BLAST.status.read' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="delivered">
            <ion-label>{{ 'BLAST.status.delivered' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="sent">
            <ion-label>{{ 'BLAST.status.sent' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="processing">
            <ion-label>{{ 'BLAST.status.processing' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="failed">
            <ion-label>{{ 'BLAST.status.failed' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-container>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-search ion-no-padding ion-no-border" [ngClass]="module === 'trash' ? 'trash-toolbar' : ''" color="light" *ngIf="mode !== 'preview' && mode !== 'sms-result' && mode !== 'whatsapp-result'">
    <div class="content-div">
      <app-search [type]="'guestList'" [reset]="resetSearch" [guestListMode]="mode" [hideFilterField]="hideFilterField" (action)="search($event)" (setupHeight)="setupContentHeight()"></app-search>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <div class="content-div">
      <app-select-all [total]="count" [selected]="selectedGuestList?.length" [selectAll]="selectAll" [editMode]="selectAllEditMode" [hideTotal]="type?.giftReceived ? true : false" (toggleSelect)="toggleSelectAll()"></app-select-all>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="full-width ion-padding-horizontal" [style.height]="contentHeight" [scrollY]="false" color="light" #content>
  <!-- <div class="full-width viewport-div" [style.height]="contentHeight" *ngIf="guestList?.length else emptyListBlock">
    <ion-list class="full-width background-transparent ion-padding-bottom" lines="none" #list>
      <app-guest-card *ngFor="let guest of guestList | slice : 0 : limit; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [setGuest]="guest" [editMode]="editMode" [selectMode]="selectMode" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" [mode]="mode" [disableWatchGuest]="mode === 'preview' ? true : false" [showGuestSeating]="showGuestSeating" [setHideField]="mode === 'preview' && showGuestSeating ? { qrcodeStatus: true, seatingStatus: true } : null" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
      <ion-infinite-scroll class="" threshold="5px" (ionInfinite)="loadLimit($event)" *ngIf="guestList?.length > limit">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          [loadingText]="'LOADING.loading' | translate">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-list>
  </div> -->
  <div class="content-div">
    <div class="full-width viewport-div" [style.height]="contentHeight" *ngIf="guestList?.length && limit else emptyListBlock">
      <cdk-virtual-scroll-viewport [itemSize]="estimateItemSize" class="ion-content-scroll-host">
        <ion-list class="full-width-height background-transparent ion-padding-horizontal ion-padding-bottom" lines="none" #list>
          <!-- [disableWatchGuest]="mode === 'preview' ? true : false" -->
           <ng-container *ngIf="mode === 'invites'">
            <div *cdkVirtualFor="let guest of guestList | slice : 0 : limit; trackBy: trackByFn; let index = index;">
              <ion-item-divider *ngIf="headerFn(guest, index)" sticky>
                {{ headerFn(guest, index) }}
              </ion-item-divider>
              <app-guest-card [style.height.px]="itemHeightFn(guest)" [blastUsageId]="blastUsageId" [blastTemplate]="blastTemplate" [setGuest]="guest" [editMode]="editMode" [selectMode]="selectMode" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" [mode]="mode" [showGuestSeating]="showGuestSeating" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
            </div>
           </ng-container>
           <ng-container *ngIf="mode !== 'invites'">
            <app-guest-card *cdkVirtualFor="let guest of guestList | slice : 0 : limit; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [blastUsageId]="blastUsageId" [blastTemplate]="blastTemplate" [setGuest]="guest" [editMode]="editMode" [selectMode]="selectMode" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" [mode]="mode" [showGuestSeating]="showGuestSeating" [setHideField]="mode === 'preview' && showGuestSeating ? { qrcodeStatus: true, seatingStatus: true } : null" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
           </ng-container>
          
          <ion-infinite-scroll threshold="10%" (ionInfinite)="loadLimit($event)" *ngIf="guestList?.length > limit">
            <ion-infinite-scroll-content
              loadingSpinner="bubbles"
              [loadingText]="'LOADING.loading' | translate">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
    
    <ng-template #emptyListBlock>
      <div class="full-width vertical-center ion-padding center" [style.height]="contentHeight">
        <div class="center" *ngIf="searchTerm || filter || type || (module !== 'checkin' && module !== 'gift')">
          <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
            <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
            <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
          </ion-label>
        </div>
        <div class="ion-text-center ion-padding-vertical" *ngIf="(module === 'checkin' || module === 'gift') && !searchTerm && !filter && !type">
          <ion-label>{{ 'SEARCH.lbl.smart' | translate }}</ion-label>
          <!-- <ion-toolbar class="background-transparent ion-padding-horizontal"> -->
            <div class="ion-padding-horizontal">
              <ion-segment [(ngModel)]="smartSearchType" scrollable>
                <ion-segment-button value="english" *ngIf="language !== 'ms'"><ion-label>{{ 'SEARCH.english.lbl' | translate }}</ion-label></ion-segment-button>
                <ion-segment-button value="malay" *ngIf="language === 'ms'"><ion-label>{{ 'SEARCH.malay.lbl' | translate }}</ion-label></ion-segment-button>
                <ion-segment-button value="pinyin"><ion-label>{{ 'SEARCH.pinyin.lbl' | translate }}</ion-label></ion-segment-button>
                <!-- <ion-segment-button value="cangjie"><ion-label>{{ 'SEARCH.cangjie.lbl' | translate }}</ion-label></ion-segment-button> -->
                <ion-segment-button value="zhuyin"><ion-label>{{ 'SEARCH.zhuyin.lbl' | translate }}</ion-label></ion-segment-button>
              </ion-segment>
            </div>
          <!-- </ion-toolbar> -->
            
          <div class="font-12 ion-padding-vertical">
            <ng-container *ngIf="smartSearchType === 'english'">
              <div class="ion-padding">
                <ion-text>
                  {{ 'SEARCH.english.msg1' | translate }}
                </ion-text>
              </div>
              <app-search [type]="'guestList'" [keyword]="'nat'" [guestListMode]="mode" [disableFilter]="true" [disabled]="true" (action)="search($event)"></app-search>
              <div class="guest-demo-div">
                <ion-card class="ion-no-margin">
                  <app-guest-item [setGuest]="demoGuest?.['english']" [disableClick]="true" [watchGuest]="false"></app-guest-item>
                </ion-card>
              </div>
            </ng-container>
            <ng-container *ngIf="smartSearchType === 'malay'">
              <div class="ion-padding">
                <ion-text>
                  {{ 'SEARCH.malay.msg1' | translate }}
                </ion-text>
              </div>
              <app-search [type]="'guestList'" [keyword]="'nat'" [guestListMode]="mode" [disableFilter]="true" [disabled]="true" (action)="search($event)"></app-search>
              <div class="guest-demo-div">
                <ion-card class="ion-no-margin">
                  <app-guest-item [setGuest]="demoGuest?.['malay']" [disableClick]="true" [watchGuest]="false"></app-guest-item>
                </ion-card>
              </div>
            </ng-container>
            <ng-container *ngIf="smartSearchType === 'pinyin'">
              <div class="ion-padding">
                <ion-text>
                  {{ 'SEARCH.pinyin.msg1' | translate }}
                </ion-text>
              </div>
              <app-search [type]="'guestList'" [keyword]="'ldh or de hua'" [guestListMode]="mode" [disableFilter]="true" [disabled]="true" (action)="search($event)"></app-search>
              <div class="guest-demo-div">
                <ion-card class="ion-no-margin">
                  <app-guest-item [setGuest]="demoGuest?.['pinyin']" [disableClick]="true" [watchGuest]="false"></app-guest-item>
                </ion-card>
              </div>
            </ng-container>
            <!-- <ng-container *ngIf="smartSearchType === 'cangjie'">
              <div class="ion-padding">
                <ion-text>
                  {{ 'SEARCH.cangjie.msg1' | translate }}
                </ion-text>
              </div>
              <app-search [type]="'guestList'" [keyword]="'ldh or de hua'" [guestListMode]="mode" [disableFilter]="true" [disabled]="true" (action)="search($event)"></app-search>
              <div class="guest-demo-div">
                <ion-card class="ion-no-margin">
                  <app-guest-item [setGuest]="demoGuest?.['pinyin']" [disableClick]="true" [watchGuest]="false"></app-guest-item>
                </ion-card>
              </div>
            </ng-container> -->
            <ng-container *ngIf="smartSearchType === 'zhuyin'">
              <div class="ion-padding">
                <ion-text>
                  {{ 'SEARCH.zhuyin.msg1' | translate }}
                </ion-text>
              </div>
              <app-search [type]="'guestList'" [placeholder]="'ㄌㄧㄡˊㄉㄜˊㄏㄨㄚˊ or ㄌㄉㄏ or ㄉㄏ'" [guestListMode]="mode" [disableFilter]="true" [disabled]="true" (action)="search($event)"></app-search>
              <div class="guest-demo-div">
                <ion-card class="ion-no-margin">
                  <app-guest-item [setGuest]="demoGuest?.['pinyin']" [disableClick]="true" [watchGuest]="false"></app-guest-item>
                </ion-card>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="module !== 'account' && mode !== 'preview' && mode !== 'invites' && mode !== 'sms-result' && mode !== 'whatsapp-result' && (mode !== 'qrcode' || !pageMode) && !selectMode && guestList?.length">
    <ion-fab-button color="primary" (click)="toggleEditMode()">
      <ion-icon name="chevron-up" *ngIf="!editMode"></ion-icon>
      <ion-icon name="close" *ngIf="editMode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer class="ion-no-border" *ngIf="editMode || selectMode" #footer>
  <app-bulk-action *ngIf="editMode" [mode]="mode" [selectedList]="selectedGuestList" [count]="count" [showGuestSeating]="showGuestSeating" [setReady]="true" (action)="bulkAction($event)"></app-bulk-action>
  
  <ion-toolbar *ngIf="selectMode">
    <div class="content-div">
      <ion-grid fixed>
        <ion-row>
          <ion-col *ngIf="currentPrivilege?.[module]?.['new']">
            <ion-button expand="block" (click)="presentGuestNewModal()" [disabled]="selectedGuestList?.length">{{ 'CRUD.new_field' | translate: { field: 'GUEST.lbl.guest' | translate } }}</ion-button>
          </ion-col>
          <ion-col *ngIf="guestList?.length">
            <ion-button expand="block" (click)="select()" [disabled]="!selectedGuestList?.length">{{ 'BTN.select' | translate }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-toolbar>
</ion-footer>
<ion-footer class="ion-no-border" *ngIf="mode === 'invites'">
  <ion-toolbar>
    <ion-button expand="block" (click)="presentGuestNewModal()">{{ 'CRUD.new_field' | translate: { field: 'GUEST.lbl.guest' | translate } }}</ion-button>
  </ion-toolbar>
</ion-footer>
