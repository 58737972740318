<ng-container *ngIf="pageMode then contentBlock">
</ng-container>
<ng-container *ngIf="!pageMode">
    <ion-header>
        <ion-toolbar color="light">
            <ion-buttons slot="start">
                <ion-button (click)="dismissModal()">
                    <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>
                <app-offline-indicator></app-offline-indicator>
                {{ 'BLAST.usage.history' | translate }}
            </ion-title>
        </ion-toolbar>
     </ion-header>
    <ion-content color="light">
        <div class="ion-padding-horizontal">
            <app-blast-template-preview [langCode]="!blastUsage?.blastTemplate?.language?.custom && blastUsage?.blastTemplate?.language?.value ? blastUsage.blastTemplate.language.value : ''" [blastType]="blastUsage.blastType" [msgTitle]="blastUsage?.blastTemplate?.templateTitle" [msg]="blastUsage.blastTemplate.msg" [url]="blastUsage?.blastTemplate?.url ? sampleUrl : ''" [phoneCode]="phoneCode" [pageMode]="true" *ngIf="blastUsage.blastTemplate"></app-blast-template-preview>
        </div>

        <ng-container *ngIf="true then contentBlock"></ng-container>

        <ion-card class="ion-padding">
            <div>
                <ion-text class="font-14 font-bold" color="black">{{ 'DATE.lbl.date' | translate }}</ion-text>
            </div>
            <div *ngIf="blastUsage?.createBy?.time?.seconds">
                <ion-text class="font-12" color="black">{{ format(blastUsage.createBy.time.seconds * 1000) }}</ion-text>
            </div>
        </ion-card>

        <ion-card class="ion-padding">
            <div>
                <ion-text class="font-14 font-bold" color="black">{{ 'QRCODE.lbl.sent_by' | translate }}</ion-text>
            </div>
            <div>
                <ion-text class="font-12" color="black">{{ username }}</ion-text>
            </div>
        </ion-card>
    </ion-content>
  <ion-footer>
      <ion-toolbar class="ion-no-padding" color="white">
          <div class="ion-padding ion-margin-horizontal">
              <ion-button expand="block" (click)="resend()">
                  {{ 'BLAST.lbl.resend' | translate }}
              </ion-button>
          </div>
      </ion-toolbar>
  </ion-footer>
</ng-container>

<ng-template #contentBlock>
    <ion-card class="ion-padding">
        <div>
            <div class="line-150">
                <ion-text class="font-14 font-bold" color="black">{{ 'LBL.summary' | translate }}</ion-text>
            </div>
            <div class="line-120">
                <ng-container *ngIf="blastUsage?.usageDetail?.length">
                    <app-blast-usage-summary [blastType]="blastUsage.blastType" [blastUsageDetailList]="blastUsage.usageDetail" [showTotalBlastCount]="true" [ready]="ready"></app-blast-usage-summary>
                </ng-container>
            </div>
        </div>
    </ion-card>

    <ion-card class="ion-padding" (click)="presentGuestListModal()" *ngIf="guestIdList?.length || groupIdList?.length">
        <ion-item class="ion-item-no-padding" lines="none" [detail]="guestIdList?.length || groupIdList?.length">
            <ion-text>
                <div class="font-14 font-bold">
                    {{ 'BLAST.template.recipients' | translate }}
                </div>
                <div class="font-12">
                    <div *ngIf="guestIdList?.length">
                        {{ 'BLAST.lbl.count_guest_selected' | translate: { count: guestIdList?.length ? guestIdList.length : 0 } }}
                    </div>
                    <div *ngIf="groupIdList?.length">
                        {{ 'BLAST.lbl.count_group_selected' | translate: { count: groupIdList?.length ? groupIdList.length : 0 } }}
                    </div>
                </div>
            </ion-text>
        </ion-item>
    </ion-card>

    <ion-card class="ion-padding">
        <div>
            <ion-text class="font-14 font-bold" color="black">{{ 'BLAST.usage.status.title' | translate }}</ion-text>
        </div>
        <div>
          <ng-container *ngIf="sentStatus else processingBlock">
              <div>
                  <ion-text class="font-12" color="black" [innerHTML]="blastStatus"></ion-text>
              </div>
          </ng-container>
          <ng-template #processingBlock>
              <div *ngIf="estDeliveryTime">
                  <ion-text class="font-12" color="black" [innerHTML]="'BLAST.usage.status.processing' | translate: { min: estDeliveryTime.min, max: estDeliveryTime.max }"></ion-text>
              </div>
          </ng-template>
        </div>
    </ion-card>
</ng-template>