import { Injectable } from '@angular/core';
import { WebsiteLinksService } from './website-links.service';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteLink } from 'src/app/interfaces/website';
import { ShortUrlService } from '../general/short-url.service';
import { UpdateByService } from '../user/update-by.service';
import { DynamicUrl } from 'src/app/commons/url';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Platform } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { PopupService } from '../general/popup.service';
import { ErrorService } from '../general/error.service';

import { FunctionService } from '../general/function.service';
import { WebsiteSettingFieldService } from './website-setting-field.service';
import { Guest, Invite } from 'src/app/interfaces/guest';
import { GuestManageService } from '../guest/guest-manage.service';
import { GroupManageService } from '../group/group-manage.service';
import { GuestService } from '../guest/guest.service';
import { GroupService } from '../group/group.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteLinkService {

  accountId: string;

  constructor(
    private platform: Platform,
    private afs: AngularFirestore,
    private translate: TranslateService,
    private websiteLinksService: WebsiteLinksService,
    private websiteSettingFieldService: WebsiteSettingFieldService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private shortUrlService: ShortUrlService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) { }

  get linkId(): string {
    return this.accountId ? this.afs.collection(`accounts/${ this.accountId }/accountSetting/website/links/`).ref.doc().id : '';
  }

  async getWebsiteLink(linkId: string, quickShare?: boolean, preview?: boolean, generateNewLink?: boolean, save?: boolean) {
    let id = 1;
    let link: WebsiteLink;
    this.websiteLinksService.websiteLinks.forEach((websiteLink: WebsiteLink) => {
      if (linkId) {
        if (websiteLink?.linkId === linkId) {
          link = websiteLink;
        }
      } else if (quickShare) {
        if (websiteLink?.quickShare) {
          if (websiteLink?.enable) {
            link = websiteLink;
          }
          id += 1;
        }
      } else if (preview) {
        if (websiteLink?.preview) {
          if (websiteLink?.enable) {
            link = websiteLink;
          }
          id += 1;
        }
      } else {
        if (!websiteLink?.preview && !websiteLink?.quickShare) {
          if (websiteLink?.enable) {
            link = websiteLink;
          }
          id += 1;
        }
      }
    });

    if (!link?.linkId && generateNewLink) {
      link = await this.generateLink(id, quickShare, preview, save);
    }
    return link;
  }

  async generateLink(id: number, quickShare: boolean, preview: boolean, save?: boolean, accountId?: string) {
    const updateBy = this.updateByService.updateBy;
    const linkId = this.linkId;
    let name = this.translate.instant('WEBSITE.link.lbl.name_id', { id });
    if (quickShare) {
      name = this.translate.instant('WEBSITE.link.lbl.quick_name_id', { id });
    } else if (preview) {
      name = this.translate.instant('WEBSITE.link.lbl.preview_link');
    }
    const websiteLink: WebsiteLink = {
      linkId: linkId,
      accountId: accountId ? accountId : this.accountId,
      name,
      invitedBy: [ this.websiteSettingFieldService.settingFieldOnlineReg ],
      category: [ this.websiteSettingFieldService.settingFieldOnlineReg ],
      link: {
        url: this.generateUrl(linkId, accountId ? accountId : this.accountId),
      },
      enable: true,
      quickShare,
      preview,
      createBy: updateBy,
      updateBy,
    };

    if (websiteLink?.link?.url) {
      const shortUrl = await this.shortUrlService.generateShortUrl(websiteLink.link.url);
      websiteLink.link.short = this.shortUrlService.setupShortUrl(shortUrl);
      if (save) {
        await this.saveWebsiteLink(websiteLink);
      }
    }

    return websiteLink;
  }

  // async generateWebsiteLinkCall(websiteLink: WebsiteLink) {
  //   if (this.onlineService.online) {
  //     if (websiteLink) {
  //       // await this.popupService.presentLoading();
  //       return await this.fns.httpsCallable('generateWebsiteLinkCall')({ websiteLink })
  //       .toPromise().then(async (result: any) => {
  //         return result;
  //       }).catch((err: any) => {
  //         this.errorService.logError(err);
  //         // this.popupService.dismissLoading();
  //         // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
  //       });
  //     } else {
  //       // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
  //     }
  //   }
  // }

  generateUrl(linkId: string, accountId?: string): string {
    if (linkId && (this.accountId || accountId)) {
      return DynamicUrl.long.guestWeb + (accountId ? accountId : this.accountId) + ('/' + linkId + '/');
    }
    return '';
  }

  generatePrivateLink(guestId: string, groupId: string) {
    return `${ DynamicUrl.long.guestWeb }${ this.accountId }/0/${ guestId ? guestId : 0 }/${ groupId ? groupId :0 }`
  }

  async share(websiteLink: WebsiteLink) {
    let save = false;
    if (!websiteLink?.link?.url) {
      websiteLink.link.url = this.generateUrl(websiteLink.linkId, this.accountId);
      save = true;
    }
    if (!websiteLink?.link?.short && websiteLink?.link?.url) {
      const shortUrl = await this.shortUrlService.generateShortUrl(websiteLink.link.url);
      websiteLink.link.short = this.shortUrlService.setupShortUrl(shortUrl);
      save = true;
    }
    if (save) {
      await this.saveWebsiteLink(websiteLink);
    }
    if (websiteLink.link?.short || websiteLink.link?.url) {
      const url = websiteLink.link?.short ? websiteLink.link.short : websiteLink.link.url;
      if (this.platform.is('hybrid')) {
        // await this.popupService.presentLoading();
        await Share.share({
          // title: this.getTitle(),
          text: url,
        }).then((result) => {
        }).catch((err) => {
          if (err?.message !== 'Share canceled') {
            this.errorService.logError(err);
          }
        });
        // await this.popupService.dismissLoading();
      } else {
        try {
          await Clipboard.write({ string: url });
          this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
        } catch (err: any) {
          this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
        }
      }
    } else {
      this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
    }
  }

  async sharePriviteLink(guestId?: string, groupId?: string) {
    const url = this.generatePrivateLink(guestId, groupId);
    if (url) {
      if (this.platform.is('hybrid')) {
        // await this.popupService.presentLoading();
        await Share.share({
          // title: this.getTitle(),
          text: url,
        }).then((result) => {
          this.updateInviteStatus(guestId, groupId);
        }).catch((err) => {
          if (err?.message !== 'Share canceled') {
            this.errorService.logError(err);
          }
        });
        // await this.popupService.dismissLoading();
      } else {
        try {
          await this.updateInviteStatus(guestId, groupId);
          await Clipboard.write({ string: url });
          this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
        } catch (err: any) {
          console.log(err);
          this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
        }
      }
    }
  }

  async updateInviteStatus(guestId?: string, groupId?: string) {
    const invite: Invite = {
      type: 'private',
      createBy: this.updateByService.updateBy,
    };
    invite.createBy.time = {
      seconds: new Date().getTime() / 1000
    };

    if (guestId) {
      const guest = this.guestService.getGuest(guestId);
      const invites = guest?.invites ? guest.invites : [];
      invites.push(invite);
      await this.guestManageService.saveDb({ invites }, [ guestId ]);
    } else if (groupId) {
      const memberList = this.guestService.getGroupMember(groupId);
      const memberIdList = memberList.map((guest: Guest) => {
        return guest?.guestId;
      });
      const group = this.groupService.getGroup(groupId);
      const invites = group?.invites ? group.invites : [];
      await this.groupManageService.saveGroup(groupId, { invites });
      memberList.forEach((guest: Guest) => {
        if (!guest?.invites) {
          guest.invites = [];
        }
        guest.invites.push(invite);
      });
      await this.guestManageService.saveGuest(null, memberIdList, '', memberList, true);
    }
  }

  async saveWebsiteLink(websiteLink: WebsiteLink) {
    if (this.accountId && websiteLink) {
      if (!this.functionService.isEqual(websiteLink, await this.getWebsiteLink(websiteLink.linkId))) {
        websiteLink.updateBy = this.updateByService.updateBy;
        if (!websiteLink?.linkId) {
          websiteLink.linkId = this.linkId;
        }
        if (!websiteLink?.link?.url) {
          websiteLink.link.url = this.generateUrl(websiteLink.linkId, this.accountId);
        }
        if (!websiteLink?.link?.short && websiteLink?.link?.url) {
          const shortUrl = await this.shortUrlService.generateShortUrl(websiteLink.link.url);
          websiteLink.link.short = this.shortUrlService.setupShortUrl(shortUrl);
        }
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website/links/${ websiteLink.linkId}`);
        ref.set(websiteLink, { merge: true });
      }
    }
    return websiteLink;
  }
}
