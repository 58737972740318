<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BTN.preview' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <div>
    <ng-container *ngIf="selectedSeating">
      <ng-container class="ion-align-self-center" size="12" *ngIf="selectedSeating?.seatingId && !selectedSeating?.deleted && selectedSeating?.name">
        <ng-container *ngIf="selectedSeatingSummary">
          <ng-container *ngIf="selectedSeatingSummary?.guestList?.length">
            <app-seating-view [width]="contentWidth" [gridSize]="gridSize" [gridNameHeight]="gridNameHeight" [seating]="selectedSeating" [seatingSummary]="selectedSeatingSummary" [seatingView]="'list'" [disableWatching]="true" [disableClick]="true" [expand]="true" [selectedGuestList]="targetGuestIdList" [checkSelectedGroup]="true"></app-seating-view>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="ion-text-center">
      <ion-icon class="center icon-large" src="./assets/wedding/icon/general/switch-horizontal.svg"></ion-icon>
    </div>

    <ng-container *ngIf="targetSeating">
      <ng-container class="ion-align-self-center" size="12" *ngIf="targetSeating?.seatingId && !targetSeating?.deleted && targetSeating?.name">
        <ng-container *ngIf="targetSeatingSummary">
          <ng-container *ngIf="targetSeatingSummary?.guestList?.length">
            <app-seating-view  [width]="contentWidth" [gridSize]="gridSize" [gridNameHeight]="gridNameHeight" [seating]="targetSeating" [seatingSummary]="targetSeatingSummary" [seatingView]="'list'" [disableWatching]="true" [disableClick]="true" [expand]="true" [selectedGuestList]="selectedGuestIdList" [checkSelectedGroup]="true"></app-seating-view>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="confirm()">
      {{ 'BTN.confirm' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>