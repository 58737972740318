import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';

import { WebsiteModeUpgradeService } from 'src/app/services/website/website-mode-upgrade.service';

@Component({
  selector: 'app-website-mode-upgrade',
  templateUrl: './website-mode-upgrade.component.html',
  styleUrls: ['./website-mode-upgrade.component.scss'],
})
export class WebsiteModeUpgradeComponent  implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private modalController: ModalController,
    private accountEventModeService: AccountEventModeService,
    private websiteModeUpgradeService: WebsiteModeUpgradeService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  async upgradeNow() {
    await this.websiteModeUpgradeService.upgradeNow(true);
    await this.goWebsiteDesignPage();
    await this.dismissModal();
  }

  async goWebsiteDesignPage() {
    await this.router.navigate(['/website-setting/home']);
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

}
