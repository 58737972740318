<ion-content class="ion-padding" color="light">
  <div class="content-div">
    <div class="ion-text-center ion-margin">
      <ion-text class="font-16">{{ 'MENU.accounts_add' | translate }}</ion-text>
    </div>
  
    <div class="ion-padding">
      <div>
        <div class="lbl-div">
          <ion-label class="font-14 line-120" color="medium">{{ 'ACCOUNTS.lbl.create' | translate }}</ion-label>
        </div>
        <ion-card class="ion-no-margin">
          <ion-item lines="full" (click)="goTrialPage()" *ngIf="isTrialAvailable" detail="false" button>
            <ion-icon name="add-circle-outline" slot="start"></ion-icon>
            <ion-text class="font-14">{{ 'ACCOUNTS.trial.lbl.try_free' | translate }}</ion-text>
          </ion-item>
          <ion-item lines="none" (click)="goPremiumPage()" detail="false" button>
            <ion-icon name="add-circle-outline" slot="start"></ion-icon>
            <ion-text class="font-14">{{ 'ACCOUNTS.premium.lbl.go_premium' | translate }}</ion-text>
          </ion-item>
        </ion-card>
      </div>
    
      <div class="ion-margin-vertical">
        <div class="lbl-div">
          <ion-label class="font-14 line-120" color="medium">{{ 'ACCOUNTS.lbl.join' | translate }}</ion-label>
        </div>
        <ion-card class="ion-no-margin">
          <ion-item lines="full" (click)="goScan()" detail="false" button>
            <ion-icon name="add-circle-outline" slot="start"></ion-icon>
            <ion-text class="font-14">{{ 'BTN.scan' | translate }}</ion-text>
          </ion-item>
          <ion-item lines="none" (click)="presentAccountsPasswordLoginModal()" detail="false" button>
            <ion-icon name="add-circle-outline" slot="start"></ion-icon>
            <ion-text class="font-14">{{ 'ACCOUNT.lbl.wedding_id' | translate }}</ion-text>
          </ion-item>
        </ion-card>
      </div>
    </div>
  </div>
</ion-content>