<ng-container *ngIf="!pageMode">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ 'BTN.preview' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" color="light">
    <ng-container *ngIf="true then gallery"></ng-container>
  </ion-content>
</ng-container>

<ng-container *ngIf="pageMode">
  <ng-container *ngIf="true then gallery"></ng-container>
</ng-container>


<ng-template #gallery>
  <div class="ion-text-center ion-margin" *ngIf="title">
    <ion-text class="content-title">
      <quill-view [content]="title"></quill-view>
    </ion-text>
  </div>
  <div class="ion-text-center ion-margin" *ngIf="msg">
    <ion-text class="content-msg">
      <quill-view [content]="msg"></quill-view>
    </ion-text>
  </div>
  <ng-container *ngIf="websiteGallery?.layout?.type === 'mansory'">
    <div class="mansory-container gallery-container" [class]="'container-gap-' + websiteGallery?.layout?.gap + ' size-' + websiteGallery?.layout?.size" gallerize="gallery-mansory">
      <ng-container *ngFor="let photo of list; let i = index;">
        <ng-container>
          <div class="mansory-item gallery-item" [class]="'border-radius-' + websiteGallery?.layout?.borderRadius + ' gap-' + websiteGallery?.layout?.gap + ' shadow-' + websiteGallery?.layout?.shadow" [ngClass]="websiteGallery?.layout?.border ? 'border' : 'no-border'">
            <img [src]="photo?.mediaUrl?.url" [attr.imageSrc]="photo?.mediaUrl?.url" [attr.thumbSrc]="photo?.mediaUrl?.url"/>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="websiteGallery?.layout?.type === 'pinterest'">
    <div class="pinterest-container gallery-container" [class]="'size-' + websiteGallery?.layout?.size" gallerize="gallery-pinterest">
      <ng-container *ngFor="let photo of list; let i = index;">
        <ng-container>
          <div class="pinterest-item gallery-item">
            <img [src]="photo?.mediaUrl?.url" [attr.imageSrc]="photo?.mediaUrl?.url" [attr.thumbSrc]="photo?.mediaUrl?.url"/>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="websiteGallery?.layout?.type === 'carousel'">
    <gallery [autoHeight]="true" [items]="items" [thumb]="websiteGallery?.layout?.thumbnail ? true : false" [autoPlay]="websiteGallery?.layout?.autoPlay ? true : false" [loop]="websiteGallery?.layout?.loop ? true : false" [thumbPosition]="websiteGallery?.layout?.thumbnailPosition ? websiteGallery?.layout?.thumbnailPosition : 'top'" gallerize="gallery-carousel" *ngIf="items?.length"></gallery>
  </ng-container>
  <ng-container *ngIf="websiteGallery?.layout?.type === 'grid'">
    <ion-grid class="container center" gallerize="gallery-carousel">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ng-container *ngFor="let photo of list; let i = index;">
          <ng-container>
            <ion-col [size]="'auto'" [class]="'border-radius-' + websiteGallery?.layout?.borderRadius + ' grid-gap-' + websiteGallery?.layout?.gap + ' shadow-' + websiteGallery?.layout?.shadow" [ngClass]="websiteGallery?.layout?.border ? 'border' : 'no-border'">
              <ng-container *ngIf="!websiteGallery?.layout?.backgroundSize || websiteGallery?.layout?.backgroundSize === 'auto'">
                <img [class]="'grid-img-size-' + websiteGallery?.layout?.size" [src]="photo?.mediaUrl?.url" [attr.imageSrc]="photo?.mediaUrl?.url" [attr.thumbSrc]="photo?.mediaUrl?.url"/>
              </ng-container>
              <ng-container *ngIf="websiteGallery?.layout?.backgroundSize && websiteGallery?.layout?.backgroundSize !== 'auto'">
                <div class="grid-img-wrapper" [class]="'grid-fixed-img-size-' + websiteGallery?.layout?.size">
                  <img class="grid-img" [class]="'grid-fixed-img-size-' + websiteGallery?.layout?.size + ' grid-img-' + websiteGallery?.layout?.backgroundSize" [src]="photo?.mediaUrl?.url" [attr.imageSrc]="photo?.mediaUrl?.url" [attr.thumbSrc]="photo?.mediaUrl?.url"/>
                </div>
              </ng-container>
            </ion-col>
          </ng-container>
        </ng-container> 
      </ion-row>
    </ion-grid>
  </ng-container>
</ng-template>
