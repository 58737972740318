<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="goBack()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'MENU.privacy' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom">
  <div class="content-div">
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-text class="font-bold font-16" [innerHTML]="'PRIVACY.header' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.msg' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
    
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.intro.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.intro.header' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.intro.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.intro.point2' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.intro.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.intro.msg2' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.info_collect.title' | translate"></ion-label></p>
        <p><ion-text class="font-bold font-16" [innerHTML]="'PRIVACY.info_collect.header' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.msg3' | translate"></ion-text></p>
    
        <p><ion-text class="font-bold font-16" [innerHTML]="'PRIVACY.info_collect.you_create.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.you_create.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.you_create.msg2' | translate"></ion-text></p>
    
        <p><ion-text class="font-bold font-16" [innerHTML]="'PRIVACY.info_collect.we_collect.title' | translate"></ion-text></p>
    
        <p><ion-text class="font-16" [innerHTML]="'PRIVACY.info_collect.we_collect.device.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.device.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.device.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.device.msg3' | translate"></ion-text></p>
    
        <p><ion-text class="font-16" [innerHTML]="'PRIVACY.info_collect.we_collect.activity.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.point4' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.activity.msg3' | translate"></ion-text></p>
    
        <p><ion-text class="font-16" [innerHTML]="'PRIVACY.info_collect.we_collect.location.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.msg2' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.point4' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.we_collect.location.msg3' | translate"></ion-text></p>
    
        <p><ion-text [innerHTML]="'PRIVACY.info_collect.msg4' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.title' | translate"></ion-label></p>
        <p><ion-text class="font-bold font-16" [innerHTML]="'PRIVACY.why_collect.header' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.provide_service.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.provide_service.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.maintain_improve.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.maintain_improve.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.new_service.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.new_service.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.personalized.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.personalized.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.personalized.msg2' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.why_collect.personalized.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.why_collect.personalized.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.why_collect.personalized.point3' | translate"></ion-text></p></li>
          </ul>
        </div>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.measure.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.measure.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.communicate.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.communicate.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.why_collect.protect.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.protect.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.protect.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.protect.msg3' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.why_collect.protect.msg4' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.control.title' | translate"></ion-label></p>
      
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.control.choice.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.control.choice.msg' | translate"></ion-text></p>
        
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.control.manage.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.control.manage.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.control.remove.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.control.remove.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.control.remove.msg2' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.control.remove.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.control.remove.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.control.remove.point3' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.control.remove.msg3' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.sharing.title' | translate"></ion-label></p>
  
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.sharing.you_share.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.you_share.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.you_share.msg2' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.sharing.we_share.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.sharing.we_share.consent.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.consent.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.sharing.we_share.external.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.external.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.sharing.we_share.legal.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.point4' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.sharing.we_share.legal.msg3' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.keep_secure.title' | translate"></ion-label></p>
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.keep_secure.header' | translate"></ion-label></p>
    
        <p><ion-text [innerHTML]="'PRIVACY.keep_secure.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.keep_secure.msg2' | translate"></ion-text></p>
    
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.keep_secure.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.keep_secure.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.keep_secure.point3' | translate"></ion-text></p></li>
          </ul>
        </div>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.delete_info.title' | translate"></ion-label></p>
        <p><ion-text class="font-bold font-16" [innerHTML]="'PRIVACY.delete_info.header' | translate"></ion-text></p>
    
        <p><ion-text [innerHTML]="'PRIVACY.delete_info.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.delete_info.msg2' | translate"></ion-text></p>
    
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.delete_info.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.delete_info.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.delete_info.point3' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.control.remove.msg3' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.retain_info.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.retain_info.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.retain_info.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.retain_info.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.retain_info.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.retain_info.point4' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.retain_info.point5' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'PRIVACY.retain_info.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.retain_info.msg3' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.compliance.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.compliance.msg' | translate"></ion-text></p>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.compliance.data_transfer.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.compliance.data_transfer.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.compliance.data_transfer.msg2' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase ion-text-wrap" [innerHTML]="'PRIVACY.about.title' | translate"></ion-label></p>
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.intro.provider.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.intro.provider.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'COMPANY.hq.name' | translate"></ion-text>&nbsp;(<ion-text [innerHTML]="'COMPANY.hq.code' | translate"></ion-text>)</p>
        <p><ion-text [innerHTML]="'COMPANY.hq.address_html' | translate"></ion-text></p>
        <p><ion-text>support&#64;thebigdays.com</ion-text></p>
        <p><ion-text [innerHTML]="'TERM.intro.provider.msg2' | translate"></ion-text></p>
        
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.about.apply.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.about.apply.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'PRIVACY.about.apply.msg2' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'PRIVACY.about.apply.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'PRIVACY.about.apply.point2' | translate"></ion-text></p></li>
          </ul>
        </div>
    
        <p><ion-label class="font-bold font-16 ion-text-wrap" [innerHTML]="'PRIVACY.about.changes.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'PRIVACY.about.changes.msg' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  </div>
</ion-content>
