<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BTN.history' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="full-width-height viewport-div" *ngIf="logList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="120" [minBufferPx]="200" [maxBufferPx]="200" autosize>
      <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
        <ion-card class="card-margin ion-padding" *cdkVirtualFor="let log of logList; trackBy: trackByFn;">
          <ng-container *ngIf="log?.after">
            <ng-container *ngIf="log.by">
              <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                <ion-icon class="icon-small" name="time-outline"></ion-icon>
                {{ format(log.by.time.seconds * 1000) }}
              </ion-item>
              <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.uid && !log?.by?.name && getUserName(log.by.uid) as username">
                <ion-icon class="icon-small" name="person-outline"></ion-icon>
                <ng-container>
                  {{ username }}
                </ng-container>
              </ion-item>
              <ion-item class="ion-item-no-padding" lines="none" *ngIf="!log?.by?.uid && log?.by?.name">
                <ion-icon class="icon-small" name="person-outline"></ion-icon>
                <ng-container>
                  {{ log.by.name }}
                </ng-container>
              </ion-item>
            </ng-container>
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.type?.indexOf('new') !== -1">
              <ion-icon class="icon-small" name="star"></ion-icon>
              <ion-text [innerHTML]="'LOG.msg.new' | translate: { field: 'GIFT.lbl.gift' | translate }"></ion-text>
            </ion-item>
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.type?.indexOf('update') !== -1">
              <ion-icon class="icon-small" name="star"></ion-icon>
              <ion-text [innerHTML]="'LOG.msg.update' | translate: { field: 'GIFT.lbl.gift' | translate }"></ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.type?.indexOf('delete') !== -1">
              <ion-icon class="icon-small" name="star"></ion-icon>
              <ion-text *ngIf="log?.after?.delete" [innerHTML]="'LOG.msg.remove' | translate: { field: 'GIFT.lbl.gift' | translate }">
              </ion-text>
              <ion-text *ngIf="!log?.after?.delete" [innerHTML]="'LOG.msg.restore' | translate: { field: 'GIFT.lbl.gift' | translate }">
              </ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.after.giftType || log?.before?.giftType">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
              <ion-text *ngIf="log.type?.indexOf('new') !== -1 && log?.after?.giftType">
                {{ 'GIFT.lbl.type' | translate }}: <strong>{{ 'LIST.gift.' + log.after.giftType | translate }}</strong>
              </ion-text>
              <ion-text *ngIf="log.type?.indexOf('update') !== -1" [innerHTML]="'LOG.field.update' | translate: { field: ('GIFT.lbl.type' | translate), value1: ('LIST.gift.' + log?.before?.giftType | translate), value2: ('LIST.gift.' + log.after.giftType | translate) }">
              </ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.after.name || log?.before?.name">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
              <ion-text *ngIf="log.type?.indexOf('new') !== -1 && log?.after?.name">
                {{ 'GIFT.lbl.name' | translate }}: <strong>{{ log.after.name }}</strong>
              </ion-text>
              <ion-text *ngIf="log.type?.indexOf('update') !== -1" [innerHTML]="'LOG.field.update' | translate: { field: ('GIFT.lbl.name' | translate), value1: log?.before?.name ? log.before.name : '', value2: log.after.name }">
              </ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log?.after?.qty && log?.after?.giftType === 'other'">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
              <ion-text *ngIf="log.type?.indexOf('new') !== -1 && log?.after?.qty">
                {{ 'LBL.qty' | translate }}: <strong>{{ log.after.qty }}</strong>
              </ion-text>
              <ion-text *ngIf="log.type?.indexOf('update') !== -1" [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.qty' | translate), value1: log?.before?.qty ? log.before.qty : '', value2: log.after.qty }">
              </ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.after.currency || log?.before?.currency">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
              <ion-text *ngIf="log.type?.indexOf('new') !== -1 && log?.after?.currency">
                {{ 'LBL.currency' | translate }}: <strong>{{ log.after.currency }}</strong>
              </ion-text>
              <ion-text *ngIf="log.type?.indexOf('update') !== -1" [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.currency' | translate), value1: log?.before?.currency ? log.before.currency : '', value2: log.after.currency }">
              </ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.after.amount || log?.before?.amount">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
              <ion-text *ngIf="log.type?.indexOf('new') !== -1 && log?.after?.qty">
                {{ 'LBL.amount' | translate }}: <strong>{{ log.after.amount }}</strong>
              </ion-text>
              <ion-text *ngIf="log.type?.indexOf('update') !== -1" [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.amount' | translate), value1: log?.before?.amount ? log.before.amount : '0', value2: log.after.amount }">
              </ion-text>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none" *ngIf="(log?.after?.guestIdList?.length && log?.after?.guestNameList) || (log?.before?.guestIdList?.length && log?.before?.guestNameList)">
              <ion-label class="ion-no-margin">
                <ng-container *ngIf="log?.after?.guestIdList?.length">
                  <ng-container *ngFor="let guestId of log.after.guestIdList">
                    <ng-container *ngIf="(!log?.before?.guestNameList || !log?.before?.guestNameList[guestId]?.name) && log?.after?.guestNameList && log?.after?.guestNameList[guestId]?.name">
                      <div>
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                        <ion-text [innerHTML]="'LOG.field.new_gift_guest' | translate: { name: log?.after?.guestNameList[guestId]?.name }"></ion-text>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="log?.before?.guestNameList && log?.before?.guestNameList[guestId]?.name && log?.after?.guestNameList && log?.after?.guestNameList[guestId]?.name">
                      <ng-container *ngIf="!log?.before?.guestNameList[guestId]?.deleted && log?.after?.guestNameList[guestId]?.deleted">
                        <div>
                          <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                          <ion-text [innerHTML]="'LOG.field.remove_group_member' | translate: { guest: log.before?.guestNameList[guestId]?.name }"></ion-text>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="log?.before?.guestNameList[guestId]?.deleted && !log?.after?.guestNameList[guestId]?.deleted">
                        <div>
                          <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                          <ion-text [innerHTML]="'LOG.field.restore_group_member' | translate: { guest: log.after?.guestNameList[guestId]?.name }"></ion-text>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="log?.before?.guestIdList?.length">
                  <ng-container *ngFor="let guestId of log.before.guestIdList">
                    <ng-container *ngIf="log?.before?.guestNameList && log?.before?.guestNameList[guestId]?.name && (!log?.before?.guestNameList || !log?.after?.guestNameList[guestId]?.name)">
                      <div>
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                        <ion-text [innerHTML]="'LOG.field.delete_gift_guest' | translate: { name: log.before?.guestNameList[guestId]?.name }"></ion-text>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ion-label>
            </ion-item>
  
            <ion-item class="ion-item-no-padding" lines="none" *ngIf="(log?.after?.groupIdList?.length && log?.after.groupNameList) || (log?.before?.groupIdList?.length && log?.before?.groupNameList)">
              <ion-label class="ion-no-margin">
                <ng-container *ngIf="log?.after?.groupIdList?.length">
                  <ng-container *ngFor="let groupId of log.after.groupIdList">
                    <ng-container *ngIf="!log.before?.groupNameList?.[groupId]?.name && log.after?.groupNameList?.[groupId]?.name">
                      <div>
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                        <ion-text [innerHTML]="'LOG.field.new_gift_group' | translate: { name: log.after?.groupNameList?.[groupId]?.name }"></ion-text>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="log.before?.groupNameList?.[groupId]?.name && log.after?.groupNameList?.[groupId]?.name">
                      <ng-container *ngIf="!log.before?.groupNameList?.[groupId]?.deleted && log.after?.groupNameList?.[groupId]?.deleted">
                        <div>
                          <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                          <ion-text [innerHTML]="'LOG.field.remove_group_member' | translate: { guest: log.before?.groupNameList?.[groupId]?.name }"></ion-text>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="log.before?.groupNameList?.[groupId]?.deleted && !log.after?.groupNameList?.[groupId]?.deleted">
                        <div>
                          <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                          <ion-text [innerHTML]="'LOG.field.restore_group_member' | translate: { guest: log.after?.groupNameList?.[groupId]?.name }"></ion-text>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="log?.before?.groupIdList?.length">
                  <ng-container *ngFor="let groupId of log.before.groupIdList">
                    <ng-container *ngIf="log.before?.groupNameList?.[groupId]?.name && !log.after?.groupNameList?.[groupId]?.name">
                      <div>
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon> 
                      <ion-text [innerHTML]="'LOG.field.delete_gift_group' | translate: { name: log.before?.groupNameList?.[groupId]?.name }"></ion-text>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ion-label>
            </ion-item>
  
          </ng-container>
        </ion-card>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="full-width-height vertical-center ion-padding center" *ngIf="!logList?.length">
    <div class="center">
      <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
        <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
      </ion-label>
    </div>
  </div>
</ion-content>