<ion-toolbar color="tertiary" *ngIf="showBar && showField">
  <ion-grid class="content-div" fixed>
    <ion-row class="ion-align-items-center full-width-height" *ngIf="!nextStep">
      <ion-col class="ion-align-self-center" *ngIf="showField.moveTop">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('move_top')" button>
          <ion-icon class="icon-small" name="arrow-up-outline"></ion-icon>
          <div><ion-label class="font-10">{{ 'WEBSITE.gallery.setting.move_top' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.moveBottom">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('move_bottom')" button>
          <ion-icon class="icon-small" name="arrow-down-outline"></ion-icon>
          <div><ion-label class="font-10">{{ 'WEBSITE.gallery.setting.move_bottom' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.info">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('info')" button>
          <ion-icon class="icon-small" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
          <div><ion-label class="font-10">{{ 'CRUD.edit' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.assign">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('assign')" button>
          <ion-icon class="icon-small" src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon>
          <div><ion-label class="font-10">{{ 'MODULE.action.guest.seating' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.add">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('add')" button>
          <ion-icon class="icon-small" name="add-circle-outline"></ion-icon>
          <div><ion-label class="font-10">{{ 'CRUD.new' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.restore">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('restore')" button>
          <ion-icon class="icon-small" name="refresh"></ion-icon>
          <div><ion-label class="font-10">{{ 'CRUD.restore' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.delete">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('delete')" button>
          <ion-icon class="icon-small" name="trash-outline"></ion-icon>
          <div><ion-label class="font-10">{{ 'CRUD.delete' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>

      <ion-col class="ion-align-self-center" *ngIf="showField.checkin">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('checkin')" button>
          <ion-icon class="icon-small" src="./assets/wedding/icon/checkin/tick-round.svg"></ion-icon>
          <div><ion-label class="font-10">{{ 'MODULE.list.checkin' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      <ion-col class="ion-align-self-center" *ngIf="showField.uncheck">
        <ion-card class="ion-no-margin ion-text-center" (click)="bulkAction('uncheck')" button>
          <ion-icon class="icon-small" src="./assets/wedding/icon/checkin/close-round.svg"></ion-icon>
          <div><ion-label class="font-10">{{ 'MODULE.action.checkin.uncheck' | translate }}</ion-label></div>
        </ion-card>
      </ion-col>
      
    </ion-row>
    <ion-row class="ion-align-items-center full-width-height" *ngIf="nextStep">
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-text class="font-14">{{ msg }}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center full-width-height" *ngIf="nextStep">
      <ion-col class="ion-align-self-center">
        <ion-button expand="block" color="grey" fill="solid" (click)="cancel()">{{ 'BTN.cancel' | translate }}</ion-button>
      </ion-col>
      <ion-col class="ion-align-self-center">
        <ion-button expand="block" color="primary" fill="solid" (click)="confirm()">{{ 'BTN.next' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-toolbar>