<ion-header #header>
  <ion-toolbar color="light">  
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons> 
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="!giftMode">
        {{ 'CHECKIN.btn.group_checkin' | translate }}
      </ng-container>
      <ng-container *ngIf="giftMode">
        {{ 'GIFT.btn.receive' | translate }}
      </ng-container>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <app-select-all [total]="count" *ngIf="selectedGuestList && guestList?.length" [selected]="selectedGuestList.length" [selectAll]="selectAll" [editMode]="true" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal" color="light" #content>
  <div class="full-width-height viewport-div" *ngIf="guestList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="110" [minBufferPx]="200" [maxBufferPx]="200">
      <ion-list class="full-width background-transparent ion-padding-bottom" lines="none" #list>
        <app-guest-card *cdkVirtualFor="let guest of guestList; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [setGuest]="guest" [selectMode]="true" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [showGuestSeating]="true" [index]="index" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" [disabled]="!selectedGuestList?.length" (click)="save()">
      {{ 'CHECKIN.btn.group_checkin' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
