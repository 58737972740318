<ion-content>
  <div class="full-height vertical-center" [style.--font]="font" [style.--backgroundColor]="backgroundColor" [style.--backgroundLine]="color">
    <ion-card class="center alert-modal-card">
      <ion-card-content>
        <div>
          <div><ion-icon class="icon-warning center" name="warning-outline" *ngIf="!hideWarning"></ion-icon></div>
          <div class="ion-margin" *ngIf="title" [innerHTML]="title"></div>
          <div class="ion-margin" *ngIf="subtitle" [innerHTML]="subtitle"></div>
        </div>
        <div class="ion-text-center ion-margin-vertical font-14" *ngIf="msg" [innerHTML]="msg" (click)="presentGuestListModal()"></div>
        <ion-button expand="block" color="primary" fill="solid" type="button" (click)="dismissModal()">
          {{ 'BTN.ok' | translate }}
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>