<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'FILTER.title_field' | translate: { field: 'PRODUCT.account.title' | translate } }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <form [formGroup]="filterForm" (ngSubmit)="filterFormSubmit()" *ngIf="filterForm">
    <ion-list class="background-transparent">
      <ion-item class="ion-item-no-padding ion-margin" color="light" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LBL.type' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="type">
          <ion-select-option value="premium">
            {{ 'ACCOUNT.lbl.premium' | translate }}
          </ion-select-option>
          <ion-select-option value="trial">
            {{ 'ACCOUNT.lbl.trial' | translate }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item class="ion-item-no-padding ion-margin" color="light" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LBL.start' | translate }}</ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding background-transparent" lines="none" (click)="presentDatetimeModal('startDate')">
          <ion-label class="ion-no-margin" *ngIf="filterForm?.value?.startDate">{{ format(filterForm.value.startDate) }}</ion-label>
        </ion-item>
      </ion-item>
      <ion-item class="ion-item-no-padding ion-margin" color="light" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LBL.end' | translate }}</ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding background-transparent" lines="none" (click)="presentDatetimeModal('endDate')">
          <ion-label class="ion-no-margin" *ngIf="filterForm?.value?.endDate">{{ format(filterForm.value.endDate) }}</ion-label>
        </ion-item>
      </ion-item>
      <ion-item class="ion-item-no-padding ion-margin" color="light" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LBL.role' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="role">
          <ion-select-option *ngFor="let role of roleList" [value]="role">{{ 'LIST.role.' + role | translate }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar color="light">
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <ion-button class="ion-margin" expand="block" color="white" fill="solid" type="button" (click)="reset()" [disabled]="isEmptyForm()">
            <ion-icon name="refresh"></ion-icon>{{ 'BTN.reset' | translate }}
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="filterFormSubmit()" [disabled]="!filterForm || !filterForm.valid">
            {{ 'BTN.apply' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>