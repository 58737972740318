import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'ngx-qrcode-styling';
import { DynamicUrl, WeddingWebsiteUrl } from 'src/app/commons/url';
import { AccountInfo } from 'src/app/interfaces/account';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteDetail, WebsiteLogo, WebsiteThemeColor } from 'src/app/interfaces/website';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { WebsitePdfService } from 'src/app/services/website/website-pdf.service';
import { WebsiteTypeService } from 'src/app/services/website/website-type.service';

import { WebsitePdfComponent } from '../website-pdf/website-pdf.component';
import { WebsiteLogoService } from 'src/app/services/website/website-logo.service';
import { WebsiteType } from 'src/app/types/website';

@Component({
  selector: 'app-website-qrcode',
  templateUrl: './website-qrcode.component.html',
  styleUrls: ['./website-qrcode.component.scss'],
})
export class WebsiteQrcodeComponent  implements OnInit, OnDestroy {

  @ViewChild('qrcodeDiv', { static: false }) qrcodeDiv: ElementRef;

  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize() {
    this.setupQrcodeSize();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange() {
    this.setupQrcodeSize();
  }
  
  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.setup();
    }
  }

  @Input() updateTheme: boolean;

  @Input() pdfMode: boolean;
  @Input() pageMode: boolean;
  @Input() previewMode: boolean;
  
  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    this.qrcodeTitle = this.getContent(this.websiteDetail?.qrcode?.title);
    this.qrcodeMsg = this.getContent(this.websiteDetail?.qrcode?.msg);

    if (!this.functionService.isEqual(this.websiteLogo, this.websiteDetail?.logo)) {
      this.websiteLogo = this.websiteDetail?.logo;
      this.setupLogo();
    }
  }

  @Input() websiteThemeColor: WebsiteThemeColor;
  
  @Input() accountId: string;
  @Input() linkId: string;
  @Input() accountInfo: AccountInfo;
  @Input() guestId: string;
  @Input() groupId: string;

  // @Input() logoUrl: string;
  // @Input() logoWidth: number;
  // @Input() logoSize: number;
  

  @Input() guest: Guest;
  @Input() group: Group;
  @Input() memberList: Guest[];

  @Input() checkinStatus: boolean;

  @Input() set setTitle(title: string) {
    this.title = this.getContent(title);
  }

  @Input() mediaHeight: number;

  websiteType: WebsiteType;

  websiteDetail: WebsiteDetail;
  websiteLogo: WebsiteLogo;

  title: string;
  qrcodeTitle: string;
  qrcodeMsg: string;

  webCheckinBtn: string;

  config: Options;

  url: string;

  name: string;
  seating: string;
  // checkinStatus: boolean;

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private accountInfoService: AccountInfoService,
    private websiteTypeService: WebsiteTypeService,
    private websiteLogoService: WebsiteLogoService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.setup();
  }

  ionViewWillLeave() {

  }

  setup() {
    this.config = this.getDefaultConfig();
    this.websiteType = this.websiteTypeService.websiteType;
    this.setupQrcodeSize();
    this.setupUrl();
    this.setupLogo();

    this.webCheckinBtn = this.getWebCheckinBtn();
    this.setupStatus();
  }

  setupUrl() {
    if (this.previewMode) {
      if (!this.accountId) {
        this.accountId = this.accountInfoService.accountId;
      }
      // if (!this.guestId) {
      //   this.guestId = 'test';
      // }
    }
    this.url = this.getGuestUrl();
  }

  setupLogo() {
    this.websiteLogo = this.websiteLogoService.getWebsiteLogo(this.websiteLogo);
    // if (!this.logoUrl) {
    //   this.logoUrl = './assets/wedding/img/logo/thebigday-logo-round-primary.webp';
    // }
    // if (!this.logoWidth) {
    //   this.logoWidth = this.getLogoWidth();
    // }
  }

  setupStatus() {
    if (this.accountId) {
      if (this.group?.groupId) {
        this.name = this.group.groupName;
        if (!this.checkinStatus) {
          this.checkinStatus = this.group?.status?.checkin ? true : false;
        }
        
        this.seating = this.memberList.map((x: Guest) => {
          return x.seating;
        }).join (', ');
      } else if (this.guest?.guestId) {
        this.name = this.guest.name;
        if (!this.checkinStatus) {
          this.checkinStatus = this.guest?.status?.checkin ? true : false;
        }
        
        this.seating = this.guest?.seating;
      } else if (this.guest?.guestId === 'test' || this.guestId === 'test') {
        this.name = this.translate.instant('WEBSITE.qrcode.guest.test');
      }
    }
  }

  setupQrcodeSize() {
    if (this.qrcodeDiv?.nativeElement?.offsetWidth) {
      let width = this.qrcodeDiv.nativeElement.offsetWidth * 0.8;
      if (width > 300) {
        width = 300;
      } else if (width < 200) {
        width = this.qrcodeDiv.nativeElement.offsetWidth;
      }
      if (!this.config || this.config?.width !== width) {
        this.config = null;
        setTimeout(() => {
          this.config = this.getDefaultConfig();
          this.config.width = width;
          this.config.height = width;
        }, 20);
      }
    } else {
      setTimeout(() => {
        this.setupQrcodeSize();
      }, 100);
    }
  }

  getDefaultConfig(): Options {
    return {
      width: 215,
      height: 215,
      type: 'canvas',
      margin: 5,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'H'
      },
      imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0.4,
        margin: 0,
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: '#000000',
        type: 'dots'
      },
      backgroundOptions: {
      },
      cornersSquareOptions: {
        color: '#000000',
        type: 'extra-rounded',
      },
      cornersDotOptions: {
        color: '#000000',
        type: 'dot',
      }
    };
  }

  /**
   * Get guest url
   * @returns guest url
   */
  getGuestUrl(): string {
    let url = DynamicUrl.long.guestWeb;

    if (url) {
      if (url[url.length - 1] !== '/') {
        url = url + '/';
      }
    }
    const guestId = this.guestId ? this.guestId : this.guest?.guestId ? this.guest.guestId : '';
    const groupId = this.groupId ? this.groupId : this.group?.groupId ? this.group.groupId : '';

    if (this.accountId) {
      if (guestId && groupId){
        url = `${ url }${ this.accountId }/0/${ guestId }/${ groupId}/`;
      } else if (guestId) {
        url = `${ url }${ this.accountId }/0/${ guestId }/0/`;
      } else if (groupId) {
        url = `${ url }${ this.accountId }/0/0/${ groupId}/`;
      }
    } else {
      if (guestId) {
        url = guestId;
      } else if (groupId) {
        url = groupId;
      }
    }
    // if (this.accountId && this.guestId && this.groupId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/' + this.guestId + '/' + this.groupId;
    // } else if (this.accountId && this.guest && this.guest?.guestId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/' + this.guest.guestId;
    // } else if (this.accountId && this.guestId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/' + this.guestId;
    // } else if (this.accountId && this.guest && this.guest?.guestId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/' + this.guest.guestId;
    // } else if (this.guestId) {
    //   url = this.guestId;
    // } else if (this.guest && this.guest?.guestId) {
    //   url = this.guest.guestId;
    // } else if (this.accountId && this.groupId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/0/' + this.groupId;
    // } else if (this.accountId && this.group?.groupId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/0/' + this.group.groupId;
    // } else if (this.accountId && this.groupId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/' + this.groupId;
    // } else if (this.accountId && this.group?.groupId) {
    //   url = DynamicUrl.long.guestWeb + this.accountId + '/0/' + this.group.groupId;
    // } else if (this.groupId) {
    //   url = this.groupId;
    // } else if (this.group?.groupId) {
    //   url = this.group.groupId;
    // }

    // this.config = this.qrcodeService.generateQRcodeConfig(url, 230, 230, 10, true);
    return url;
  }

  // getLogoSize() {
  //   if (this.logoSize) {
  //     return this.logoSize;
  //   } else {
  //     return 0.3;
  //   }
  // }

  // getLogoWidth() {
  //   if (!this.config) {
  //     this.config = this.getDefaultConfig();
  //   }
  //   return this.config.width * this.getLogoSize();
  // }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  getWebCheckinBtn() {
    if (this.websiteDetail?.checkin?.btn) {
      return this.websiteDetail.checkin.btn;
    }
    return this.translate.instant('WEBSITE.checkin.btn');
  }

  toggleWebCheckinMode() {
    this.websiteTypeService.setupWebsiteType('checkin');
  }

  back() {
    if (this.pageMode) {

    } else {
      this.dismissModal();
    }
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  async presentWebsitePdfModal() {
    let name = '';
    if (this.guestId === 'test') {
      name = this.translate.instant('WEBSITE.qrcode.guest.test');
    } else {
      name = this.guest?.name ? this.guest.name : this.group?.groupName;
    }

    const modal = await this.modalController.create({
      component: WebsitePdfComponent,
      cssClass: 'modal-full-screen',
      componentProps: {
        websiteDetail: this.websiteDetail,
        websiteThemeColor: this.websiteThemeColor,
        accountInfo: this.accountInfo,
        accountId: this.accountId,
        guestId: this.guestId,
        groupId: this.groupId,
        guest: this.guest,
        group: this.group,
        memberList: this.memberList,
        url: this.url,
        name
      }
    });
    modal.present();
  }

  download() {
    
    this.presentWebsitePdfModal();
    // const content = document.getElementById('qrcode-div');
    // if (content) {
    //   html2canvas(content).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //     pdf.link(0, 0, pdfWidth, pdfHeight, { url: this.url });
    //     pdf.save(name + ' QR Code.pdf');
    //   });
    // }

    // this.websitePdfService.setupQrcodePdfDD(this.websiteDetail, this.accountInfo, this.url, name);
  }

}
