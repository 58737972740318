<ion-header class="ion-no-border header-light">
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="statisticType === 'attending' || statisticType === 'seating' || statisticType === 'qrcode' || statisticType === 'group' || statisticType === 'checkin' || statisticType === 'giftReceived' else titleBlock">
        {{'LBL.statistics' | translate }}
      </ng-container>
      <ng-template #titleBlock>
        <ng-container *ngIf="statisticType === 'invitedBy'">{{ 'GUEST.lbl.invited_by' | translate }}</ng-container>
        <ng-container *ngIf="statisticType === 'category'">{{ 'LBL.category' | translate }}</ng-container>
        <ng-container *ngIf="statisticType === 'dietaryReq'">{{ 'GUEST.lbl.dietary_req' | translate }}</ng-container>
        <ng-container *ngIf="statisticType === 'specialReq'">{{ 'GUEST.lbl.special_req' | translate }}</ng-container>
        <ng-container *ngIf="statisticType === 'session'">{{ 'LBL.session' | translate }}</ng-container>
      </ng-template>
    </ion-title>
    <ion-buttons slot="end" *ngIf="currentPrivilege?.['account']?.['setting']">
      <ion-button (click)="openSetting()"  *ngIf="statisticType === 'invitedBy' || statisticType === 'category' || statisticType === 'dietaryReq' || statisticType === 'specialReq' || statisticType === 'session'">
        <ion-icon name="settings-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding ion-padding-horizontal background-transparent" *ngIf="statisticType !== 'invitedBy' && invitedByList?.length > 1">
    <ion-segment class="full-width" [(ngModel)]="invitedBy" scrollable>
      <ion-segment-button [value]="''">
        <ion-text>
          <div>{{'LBL.all' | translate }}</div>
        </ion-text>
      </ion-segment-button>
      <ng-container *ngIf="invitedByList">
        <ion-segment-button [value]="invitedBy" *ngFor="let invitedBy of invitedByList; trackBy: trackByFn;">
          <ion-text>
            <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
            <ng-container *ngIf="!invitedBy.custom">
              <div>{{ 'LIST.invited_by.' + invitedBy.value | translate }}</div>
            </ng-container>
          </ion-text>
        </ion-segment-button>
      </ng-container>
    </ion-segment>
  </ion-toolbar>
  <ion-toolbar color="background-transparent ion-no-padding ">
    <div>
      <ng-container *ngIf="statisticType === 'attending' || statisticType === 'seating' || statisticType === 'qrcode' || statisticType === 'group' || statisticType === 'checkin' || statisticType === 'giftReceived' else headerBlock">
        <swiper-container class="header-swiper" (swiperafterinit)="setupHeaderSwiper($event)" (swiperactiveindexchange)="onHeaderSlideChange($event)">
          <ng-container *ngIf="statisticType === 'attending'">
            <swiper-slide *ngIf="attendingType === 'attending' || !attendingType">
              <div class="full-width">
                <app-guest-statistic-item [status]="{ attending: 'attending' }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.attending_status.attending_guest' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
            <swiper-slide *ngIf="attendingType === 'pending' || !attendingType">
              <div class="full-width">
                <app-guest-statistic-item [status]="{ attending: 'pending' }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.attending_status.pending_guest' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
            <swiper-slide *ngIf="attendingType === 'not_attending' || !attendingType">
              <div class="full-width">
                <app-guest-statistic-item [status]="{ attending: 'not_attending' }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.attending_status.not_attending_guest' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
          </ng-container>
          <ng-container *ngIf="statisticType === 'seating'">
            <swiper-slide *ngIf="seatingType === 'assigned' || !seatingType">
              <div class="full-width">
                <app-guest-statistic-item [seating]="'assigned'" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.assign_status.assigned' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
            <swiper-slide *ngIf="seatingType === 'not_assign' || !seatingType">
              <div class="full-width">
                <app-guest-statistic-item [seating]="'not_assign'" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.assign_status.not_yet_assign' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
          </ng-container>
          <ng-container *ngIf="statisticType === 'qrcode'">
            <swiper-slide *ngIf="qrcodeType === 'sent' || !qrcodeType">
              <div class="full-width">
                <app-guest-statistic-item [status]="{ qrcode: true }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.send_status.sent' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
            <swiper-slide *ngIf="qrcodeType === 'not_send' || !qrcodeType">
              <div class="full-width">
                <app-guest-statistic-item [status]="{ qrcode: false }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.send_status.not_send' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
          </ng-container>
          <ng-container *ngIf="statisticType === 'group'">
            <swiper-slide *ngIf="groupType === 'group' || !groupType">
              <div class="full-width">
                <app-guest-statistic-item [name]="'LIST.group.group' | translate" [statisticType]="statisticType" [all]="true" [setGroupType]="groupType" [setInvitedBy]="invitedBy" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
            <swiper-slide *ngIf="groupType === 'individual' || !groupType">
              <div class="full-width">
                <app-guest-statistic-item [name]="'LIST.group.individual' | translate" [statisticType]="statisticType" [all]="true" [setGroupType]="groupType" [setInvitedBy]="invitedBy" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
          </ng-container>
          <ng-container *ngIf="statisticType === 'checkin'">
            <swiper-slide >
              <div class="full-width">
                <app-guest-statistic-item [status]="{ checkin: true }" [statisticType]="statisticType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="'LIST.checkin.attended' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
            <swiper-slide >
              <div class="full-width">
                <app-guest-statistic-item [status]="{ checkin: false }" [statisticType]="statisticType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="'LIST.checkin.not_attend' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
              </div>
            </swiper-slide>
          </ng-container>
          <ng-container *ngIf="statisticType === 'giftReceived'">
            <swiper-slide *ngIf="giftReceivedType === 'received' || !giftReceivedType">
              <div class="full-width">
                <app-guest-statistic-item [status]="{ gift: true }" [statisticType]="statisticType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="'GIFT.lbl.received' | translate" [all]="true" [showNumber]="true" [showTotal]="false"></app-guest-statistic-item>
              </div>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        
        <!-- <swiper-container (swiperactiveindexchange)="onHeaderSlideChange($event)" *ngIf="statisticType === 'attending'">
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ attending: 'attending' }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.attending_status.attending_guest' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ attending: 'pending' }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.attending_status.pending_guest' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ attending: 'not_attending' }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.attending_status.not_attending_guest' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
        </swiper-container>
        <swiper-container (swiperactiveindexchange)="onHeaderSlideChange($event)" *ngIf="statisticType === 'seating'">
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [seating]="'assigned'" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.assign_status.assigned' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [seating]="'not_assign'" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.assign_status.not_yet_assign' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
        </swiper-container>
        <swiper-container (swiperactiveindexchange)="onHeaderSlideChange($event)" *ngIf="statisticType === 'qrcode'">
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ qrcode: true }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.send_status.sent' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ qrcode: false }" [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setInvitedBy]="invitedBy" [name]="'LIST.send_status.not_send' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
        </swiper-container>
        <swiper-container (swiperactiveindexchange)="onHeaderSlideChange($event)" *ngIf="statisticType === 'group'">
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [name]="'LIST.group.group' | translate" [statisticType]="statisticType" [all]="true" [setGroupType]="groupType" [setInvitedBy]="invitedBy" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [name]="'LIST.group.individual' | translate" [statisticType]="statisticType" [all]="true" [setGroupType]="groupType" [setInvitedBy]="invitedBy" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
        </swiper-container>
        <swiper-container (swiperactiveindexchange)="onHeaderSlideChange($event)" *ngIf="statisticType === 'checkin'">
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ checkin: true }" [statisticType]="statisticType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="'LIST.checkin.attended' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ checkin: false }" [statisticType]="statisticType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="'LIST.checkin.not_attend' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </swiper-slide>
        </swiper-container>
        <swiper-container (swiperactiveindexchange)="onHeaderSlideChange($event)" *ngIf="statisticType === 'giftReceived'">
          <swiper-slide>
            <div class="full-width">
              <app-guest-statistic-item [status]="{ gift: true }" [statisticType]="statisticType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="'GIFT.lbl.received' | translate" [all]="true" [showNumber]="true" [showTotal]="false"></app-guest-statistic-item>
            </div>
          </swiper-slide>
        </swiper-container> -->
      </ng-container>
      <ng-template #headerBlock>
        <div class="statistic-item">
          <app-guest-statistic-item [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setInvitedBy]="invitedBy" [name]="'LBL.total' | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
        </div>
      </ng-template>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <ng-container *ngIf="statisticType === 'invitedBy' && invitedByList?.length">
    <!-- <div class="full-width viewport-div">
      <cdk-virtual-scroll-viewport [itemSize]="84">
        <ion-list class="full-width-height ion-padding-bottom">
          <div *cdkVirtualFor="let invitedBy of invitedByList; trackBy: trackByFn;">
            <app-guest-statistic-item [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="invitedBy.custom ? invitedBy.value : 'LIST.invited_by.' + invitedBy.value | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
          </div>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div> -->
    <div class="statistic-item" *ngFor="let invitedBy of invitedByList; trackBy: trackByFn;">
      <app-guest-statistic-item [statisticType]="statisticType" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="invitedBy.custom ? invitedBy.value : 'LIST.invited_by.' + invitedBy.value | translate" [all]="true" [showNumber]="true"></app-guest-statistic-item>
    </div>
  </ng-container>
  <ng-container *ngIf="statisticType !== 'invitedBy'">
    <!-- [config]="sliderConfig" (swiper)="setContentSwiper($event)"  (slideChange)="contentSwiperChange()"" -->
    <swiper-container class="content-swiper" navigation="true" [allowSlideNext]="swipeEnable" [allowSlidePrev]="swipeEnable" (swiperactiveindexchange)="contentSwiperChange($event)">
      <swiper-slide>
        <div class="full-width">
          <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none" *ngIf="swipeEnable">
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="start" (click)="prevSlide()" disabled="true">
              <ion-icon color="dark" name="chevron-back"></ion-icon>
            </ion-button> -->
            <ion-text class="center">
              <ion-icon class="icon-img font-22" color="dark" src="./assets/wedding/icon/guest/category.svg"></ion-icon>
              <ion-text class="font-14 font-bold" color="dark">
                {{ 'LBL.category' | translate }}
              </ion-text>
            </ion-text>
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="end" (click)="nextSlide()">
              <ion-icon color="dark" name="chevron-forward"></ion-icon>
            </ion-button> -->
          </ion-item>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="full-width">
          <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none" *ngIf="swipeEnable">
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="start" (click)="prevSlide()">
              <ion-icon color="dark" name="chevron-back"></ion-icon>
            </ion-button> -->
            <ion-text class="center">
              <ion-icon class="icon-img font-22" color="dark" src="./assets/wedding/icon/guest/dietary-req.svg"></ion-icon>
              <ion-text class="font-14 font-bold" color="dark">
                {{ 'GUEST.lbl.dietary_req' | translate }}
              </ion-text>
            </ion-text>
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="end" (click)="nextSlide()">
              <ion-icon color="dark" name="chevron-forward"></ion-icon>
            </ion-button> -->
          </ion-item>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="full-width">
          <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none" *ngIf="swipeEnable">
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="start" (click)="prevSlide()">
              <ion-icon color="dark" name="chevron-back"></ion-icon>
            </ion-button> -->
            <ion-text class="center">
              <ion-icon class="icon-img font-22" color="dark" src="./assets/wedding/icon/guest/special-req.svg"></ion-icon>
              <ion-text class="font-14 font-bold" color="dark">
                {{ 'GUEST.lbl.special_req' | translate }}
              </ion-text>
            </ion-text>
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="end" color="grey" (click)="nextSlide()" disabled="false">
              <ion-icon color="dark" name="chevron-forward"></ion-icon>
            </ion-button> -->
          </ion-item>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="full-width">
          <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none" *ngIf="swipeEnable">
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="start" (click)="prevSlide()">
              <ion-icon color="dark" name="chevron-back"></ion-icon>
            </ion-button> -->
            <ion-text class="center">
              <ion-icon class="icon-img font-22" color="dark" src="./assets/wedding/icon/guest/special-req.svg"></ion-icon>
              <ion-text class="font-14 font-bold" color="dark">
                {{ 'LBL.session' | translate }}
              </ion-text>
            </ion-text>
            <!-- <ion-button class="ion-no-margin" fill="clear" slot="end" color="grey" (click)="nextSlide()" disabled="true">
              <ion-icon color="dark" name="chevron-forward"></ion-icon>
            </ion-button> -->
          </ion-item>
        </div>
      </swiper-slide>
    </swiper-container>

    <ng-container *ngIf="listType === 'category'">
      <!-- <div class="full-width viewport-div" *ngIf="categoryList?.length">
        <cdk-virtual-scroll-viewport [itemSize]="84">
          <ion-list class="full-width-height ion-padding-bottom">
            <div *cdkVirtualFor="let category of categoryList; trackBy: trackByFn;">
              <app-guest-statistic-item [category]="category" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="category.custom ? category.value : 'LIST.category.' + category.value | translate" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </div> -->
      <div class="statistic-item" *ngFor="let category of categoryList; trackBy: trackByFn;">
        <app-guest-statistic-item [category]="category" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="category.custom ? category.value : 'LIST.category.' + category.value | translate" [showNumber]="true"></app-guest-statistic-item>
      </div>
    </ng-container>
    <ng-container *ngIf="listType === 'dietaryReq'">
      <!-- <div class="full-width viewport-div" *ngIf="categoryList?.length">
        <cdk-virtual-scroll-viewport [itemSize]="84">
          <ion-list class="full-width-height ion-padding-bottom">
            <div *cdkVirtualFor="let dietaryReq of dietaryReqList; trackBy: trackByFn;">
              <app-guest-statistic-item [dietaryReq]="dietaryReq" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="dietaryReq?.custom ? dietaryReq?.value : 'LIST.dietary_req.' + dietaryReq?.value | translate" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </div> -->
      <div class="statistic-item" *ngFor="let dietaryReq of dietaryReqList; trackBy: trackByFn;">
        <app-guest-statistic-item [dietaryReq]="dietaryReq" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="dietaryReq?.custom ? dietaryReq?.value : 'LIST.dietary_req.' + dietaryReq?.value | translate" [showNumber]="true"></app-guest-statistic-item>
      </div>
    </ng-container>
    <ng-container *ngIf="listType === 'specialReq'">
      <!-- <div class="full-width viewport-div" *ngIf="specialReqList?.length">
        <cdk-virtual-scroll-viewport [itemSize]="84">
          <ion-list class="full-width-height ion-padding-bottom">
            <div *cdkVirtualFor="let specialReq of specialReqList; trackBy: trackByFn;">
              <app-guest-statistic-item [specialReq]="specialReq" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="specialReq.custom ? specialReq.value : 'LIST.special_req.' + specialReq.value | translate" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </div> -->
      <div class="statistic-item" *ngFor="let specialReq of specialReqList; trackBy: trackByFn;">
        <app-guest-statistic-item [specialReq]="specialReq" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="specialReq.custom ? specialReq.value : 'LIST.special_req.' + specialReq.value | translate" [showNumber]="true"></app-guest-statistic-item>
      </div>
    </ng-container>
    <ng-container *ngIf="listType === 'session'">
      <!-- <div class="full-width viewport-div" *ngIf="sessionList?.length">
        <cdk-virtual-scroll-viewport [itemSize]="84">
          <ion-list class="full-width-height ion-padding-bottom">
            <div *cdkVirtualFor="let session of sessionList; trackBy: trackByFn;">
              <app-guest-statistic-item [session]="session" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="session?.custom ? session.value : 'LIST.session.' + session.value | translate" [showNumber]="true"></app-guest-statistic-item>
            </div>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </div> -->
      <div class="statistic-item" *ngFor="let session of sessionList; trackBy: trackByFn;">
        <app-guest-statistic-item [session]="session" [setAttendingType]="attendingType" [setSeatingType]="seatingType" [setQrcodeType]="qrcodeType" [setGroupType]="groupType" [setCheckinType]="checkinType" [setGiftReceivedType]="giftReceivedType" [setInvitedBy]="invitedBy" [name]="session?.custom ? session.value : 'LIST.session.' + session.value | translate" [showNumber]="true"></app-guest-statistic-item>
      </div>
    </ng-container>
  </ng-container>
    
</ion-content>
