import { Injectable } from '@angular/core';
import { WebsiteProfile } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UpdateByService } from '../user/update-by.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class WebsiteGuestProfileService {

  accountId: string;

  websiteProfile: WebsiteProfile;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteProfile>(this.websiteProfile);
  }

  setup(websiteProfile: WebsiteProfile) {
    this.websiteProfile = websiteProfile;
    this.observable.next(this.websiteProfile);
  }

  getWebsiteProfile(): WebsiteProfile {
    if (this.websiteProfile) {
      return this.websiteProfile;
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        showSeating: this.getShowSeating(),
        design: this.getDesign(),
        field: {
          home: this.getHome(),
          status: this.getStatus(),
          pax: this.getPax(),
          dietaryReq: this.getDietaryReq(),
          specialReq: this.getSpecialReq(),
          email: this.getEmail(),
          mobile: this.getMobile(),
          seating: this.getSeating(),
          member: this.getMember(),
        }
      }
    }
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.profile.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><strong class="ql-size-large">' + title + '</strong></p>';
  }

  getButton() {
    return this.translate.instant('WEBSITE.profile.btn');
  }

  getMsg(text?: boolean) {
    const msg = this.translate.instant('WEBSITE.profile.msg');
    if (text) {
      return msg;
    }
    return '<p class="ql-align-center">' + msg + '</p>';
  }

  getHome() {
    return this.translate.instant('WEBSITE.profile.field.home');
  }

  getStatus() {
    return this.translate.instant('WEBSITE.profile.field.status');
  }

  getPax() {
    return this.translate.instant('WEBSITE.profile.field.pax');
  }

  getDietaryReq() {
    return this.translate.instant('WEBSITE.profile.field.dietary_req');
  }

  getSpecialReq() {
    return this.translate.instant('WEBSITE.profile.field.special_req');
  }

  getEmail() {
    return this.translate.instant('WEBSITE.profile.field.email');
  }

  getMobile() {
    return this.translate.instant('WEBSITE.profile.field.mobile');
  }

  getSeating() {
    return this.translate.instant('WEBSITE.profile.field.seating');
  }

  getMember() {
    return this.translate.instant('WEBSITE.profile.field.member');
  }

  getDesign() {
    return 1;
  }

  getShowSeating() {
    return false;
  }

  async save(profile: WebsiteProfile) {
    if (this.accountId && !this.functionService.isEqual(profile, this.websiteProfile)) {
      profile.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ profile }, { merge: true });
    }
  }
}
