<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()" *ngIf="!forceUpdate">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'SETTING.checkin.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="settingForm" *ngIf="settingForm" (ngSubmit)="submit()">
    <ion-list class="background-transparent">
      <ion-card class="ion-no-margin">
        <ion-item lines="none">
          <ion-icon src="./assets/wedding/icon/guest/status/pending.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="pendingGuest" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'SETTING.checkin.pending_guest.title' | translate">
              </div>
              <div class="font-12">
                <div *ngIf="settingForm.value?.pendingGuest" [innerHtml]="'SETTING.checkin.pending_guest.on' | translate"></div>
                <div *ngIf="!settingForm.value?.pendingGuest" [innerHTML]="'SETTING.checkin.pending_guest.off' | translate"></div>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>

      <ion-card class="ion-no-margin">
        <ion-item lines="none">
          <ion-icon src="./assets/wedding/icon/guest/status/not-attending.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="notAttendingGuest" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'SETTING.checkin.not_attending_guest.title' | translate">
              </div>
              <div class="font-12">
                <div *ngIf="settingForm.value?.notAttendingGuest" [innerHtml]="'SETTING.checkin.not_attending_guest.on' | translate"></div>
                <div *ngIf="!settingForm.value?.notAttendingGuest" [innerHTML]="'SETTING.checkin.not_attending_guest.off' | translate"></div>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>

      <ion-card class="ion-no-margin">
        <ion-item lines="none">
          <ion-icon src="./assets/wedding/icon/checkin/tick-round.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="partialCheckin" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'SETTING.checkin.partial_checkin.title' | translate">
              </div>
              <div class="font-12">
                <div *ngIf="settingForm.value?.partialCheckin" [innerHtml]="'SETTING.checkin.partial_checkin.on' | translate"></div>
                <div *ngIf="!settingForm.value?.partialCheckin" [innerHTML]="'SETTING.checkin.partial_checkin.off' | translate"></div>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>

      <ion-card class="ion-no-margin">
        <ion-item lines="none">
          <ion-icon src="./assets/wedding/icon/general/sound.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="scanSound" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'SETTING.checkin.scan_sound.title' | translate">
              </div>
              <div class="font-12">
                <div *ngIf="settingForm.value?.scanSound" [innerHTML]="'SETTING.checkin.scan_sound.on' | translate"></div>
                <div *ngIf="!settingForm.value?.scanSound" [innerHTML]="'SETTING.checkin.scan_sound.off' | translate"></div>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>
      
      <ion-card class="ion-no-margin">
        <ion-item lines="none">
          <ion-icon src="./assets/wedding/icon/gift/record.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="giftRecord" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'SETTING.checkin.gift_record.title' | translate">
              </div>
              <div class="font-12">
                <div *ngIf="settingForm.value?.giftRecord" [innerHTML]="'SETTING.checkin.gift_record.on' | translate"></div>
                <div *ngIf="!settingForm.value?.giftRecord" [innerHTML]="'SETTING.checkin.gift_record.off' | translate"></div>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>
      
      <ng-container *ngIf="settingForm.value?.giftRecord">
        <ion-card class="ion-no-margin">
          <ion-item lines="none">
            <ion-icon src="./assets/wedding/icon/gift/detail.svg" slot="start"></ion-icon>
            <ion-toggle formControlName="giftDetail" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHTML]="'SETTING.checkin.gift_detail.title' | translate">
                </div>
                <div class="font-12">
                  <div *ngIf="settingForm.value?.giftDetail" [innerHTML]="'SETTING.checkin.gift_detail.on' | translate"></div>
                  <div *ngIf="!settingForm.value?.giftDetail" [innerHTML]="'SETTING.checkin.gift_detail.off' | translate"></div>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
  
        <ion-card class="ion-no-margin">
          <ion-item lines="none">
            <ion-icon src="./assets/wedding/icon/gift/prompt-gift.svg" slot="start"></ion-icon>
            <ion-toggle formControlName="promptGift" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHTML]="'SETTING.checkin.prompt_gift.title' | translate">
                </div>
                <div class="font-12">
                  <ion-text>
                    <div *ngIf="settingForm.value?.promptGift" [innerHTML]="'SETTING.checkin.prompt_gift.on' | translate"></div>
                    <div *ngIf="!settingForm.value?.promptGift" [innerHTML]="'SETTING.checkin.prompt_gift.off' | translate"></div>
                  </ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <!-- <ion-card class="ion-no-margin">
          <ion-item lines="none">
            <ion-icon src="./assets/wedding/icon/gift/prompt-checkin.svg"></ion-icon>
            <ion-label class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHTML]="'SETTING.checkin.prompt_checkin.title' | translate">
              </div>
              <div class="font-12">
                <ion-text>
                  <div *ngIf="settingForm.value?.promptCheckin" [innerHTML]="'SETTING.checkin.prompt_checkin.on' | translate"></div>
                  <div *ngIf="!settingForm.value?.promptCheckin" [innerHTML]="'SETTING.checkin.prompt_checkin.off' | translate"></div>
                </ion-text>
              </div>
            </ion-label>
            <ion-toggle formControlName="promptCheckin" color="success"></ion-toggle>
          </ion-item>
        </ion-card> -->
      </ng-container>
      
    </ion-list>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="submit()">
      {{ 'BTN.save' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
