<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="center">
      <ion-card-content>
        <form [formGroup]="inputForm" (ngSubmit)="inputFormSubmit()" *ngIf="inputForm">
          <div >
            <div class="font-bold" *ngIf="title" [innerHTML]="title"></div>
            <div class="ion-margin-vertical" *ngIf="subtitle" [innerHTML]="subtitle"></div>
            <ion-img class="center img" [src]="img" *ngIf="img"></ion-img>
            <div class="font-14 ion-margin-vertical ion-text-center" *ngIf="msg" [innerHTML]="msg"></div>
          </div>
          <div class="ion-margin-top">
            <ion-input formControlName="input" class="prompt-input ion-text-left" [placeholder]="placeholder" [type]="type" [inputmode]="type === 'number' ? 'numeric' : type" [autocapitalize]="autocapitalize" autocomplete="autocomplete" clearInput></ion-input>
            <div class="error-div ion-text-left">
              <ng-container *ngFor="let validation of validationMsg?.input">
                <div class="error-message" *ngIf="inputForm.get('input').hasError(validation.type) && (inputForm.get('input').dirty || inputForm.get('input').touched)">
                  <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </div>
          <ion-grid fixed>
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size-xs="0" size-sm="1"></ion-col>
              <ion-col class="ion-align-self-center ion-text-center">
                <ion-button class="ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="cancel()">
                  {{ cancelBtnTxt ? cancelBtnTxt : 'BTN.cancel' | translate }}
                </ion-button>
              </ion-col>
              <ion-col size-xs="1" size-sm="2"></ion-col>
              <ion-col class="ion-align-self-center center">
                <ion-button class="ion-no-margin" expand="block" color="primary" fill="solid" type="submit" [disabled]="!inputForm.valid">
                  {{ confirmBtnTxt ? confirmBtnTxt : 'BTN.save' | translate }}
                </ion-button>
              </ion-col>
              <ion-col size-xs="0" size-sm="1"></ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>