<ng-container *ngIf="websiteDetail?.contact?.enable">
  <div class="full-width">
    <quill-view [content]="title" *ngIf="title"></quill-view>
    <quill-view [content]="msg" *ngIf="msg"></quill-view>

    <div class="ion-margin-bottom" *ngIf="websiteDetail?.contact?.media?.url">
      <ng-container *ngIf="websiteDetail?.contact?.media.type === 'youtube'">
        <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.contact?.media.url"></app-youtube>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.contact?.media.type === 'base64' || websiteDetail?.contact?.media.type === 'firebase'">
        <ion-img class="full-width center" [src]="websiteDetail?.contact?.media.url"></ion-img>
      </ng-container>
    </div>

    <ng-container *ngIf="websiteDetail?.contact?.contactsList && contactList?.length">
      <ion-grid class="full-width" fixed>
        <ion-row class="ion-align-items-start ion-justify-content-center" [class]="largeMode ? 'large-mode' : 'small-mode'">
          <ion-col class="ion-text-center" *ngFor="let contact of contactList" (click)="openContact(contact)">
            <ng-container *ngIf="contact?.type">
              <ng-container *ngIf="contact.type === 'whatsapp'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/whatsapp.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <!-- <ng-container *ngIf="true then phone"></ng-container> -->
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'phone'">
                <ion-icon class="icon phone-icon center" name="call-outline"></ion-icon>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'email'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/email.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then email"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'website'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/website.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then url"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'line'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/line.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
                <ng-container *ngIf="true then url"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'messenger'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/messenger.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'wechat'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/wechat.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'telegram'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/telegram.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>

              <ng-container *ngIf="contact.type === 'threads'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/threads.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then url"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'viber'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/viber.webp"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'kaokao'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/kaokao.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'signal'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/signal.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'snapchat'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/snapchat.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then qrcode"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'instagram'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/instagram.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'facebook'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/facebook.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'skype'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/skype.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
                <ng-container *ngIf="true then phone"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'discord'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/discord.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then username"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="contact.type === 'slack'">
                <ion-img class="icon center" src="./assets/wedding/icon/website/social/slack.svg"></ion-img>
                <ng-container *ngIf="true then name"></ng-container>
                <ng-container *ngIf="true then email"></ng-container>
                <ng-container *ngIf="true then url"></ng-container>
              </ng-container>

              <ng-template #name>
                <div class="ion-text-wrap">
                  <ion-text class="font-14" *ngIf="contact?.name">{{ contact.name }}</ion-text>
                </div>
              </ng-template>
  
              <ng-template #qrcode>
                <div>
                  <ion-img class="qrcode center" [src]="contact.qrcode" *ngIf="contact?.qrcode"></ion-img>
                </div>
              </ng-template>
              
              <ng-template #phone>
                <div>
                  <ion-text class="font-14 font-underline" *ngIf="contact?.phone?.no"><a [href]="'tel:' + (contact.phone.no)">{{ contact.phone.no }}</a></ion-text>
                </div>
              </ng-template>
              
              <ng-template #username>
                <div>
                  <ion-text class="font-14 font-italic" *ngIf="contact?.username">{{ contact.username }}</ion-text>
                </div>
              </ng-template>
              
              <ng-template #url>
                <div>
                  <ion-text class="font-14 font-underline" *ngIf="contact?.url"><a [href]="contact.url" target="_blank"></a>{{ contact.url }}</ion-text>
                </div>
              </ng-template>
              
              <ng-template #email>
                <div>
                  <ion-text class="font-14 font-italic" *ngIf="contact?.email"><a [href]="'email:' + (contact.email)">{{ contact.email }}</a></ion-text>
                </div>
              </ng-template>
  
            </ng-container>
          </ion-col>
        </ion-row>
      </ion-grid>
      <!-- <ion-item class="background-transparent ion-text-center" *ngFor="let contact of contactList" lines="none">
        <ion-text class="full-width">

        </ion-text>
      </ion-item> -->
    </ng-container>
  </div>
</ng-container>

