<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'LBL.role' | translate }}
    </ion-title>
    <ion-buttons *ngIf="!newAccount" slot="end">
      <ion-button class="ion-margin" (click)="presentSettingFieldNewModal()">
        <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="search-toolbar ion-no-padding" color="light" *ngIf="!newAccount">
    <app-search [type]="'role'" (action)="search($event)" [disableFilter]="true"></app-search>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="full-width-height viewport-div" *ngIf="roleList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="48">
      <ion-list class="full-width-height background-transparent ion-padding-vertical" lines="none" #list>
        <ng-container *cdkVirtualFor="let roleObj of roleList; let i = index;">
          <ion-item-sliding  *ngIf="roleObj?.role?.type && !roleObj?.role?.coupleId" #slidingItem>
            <ion-item class="item" [color]="selected === roleObj.role ? 'tertiary' : 'white'" (click)="selectRole(roleObj.role)">
              <ion-label class="font-14">
                <ng-container *ngIf="roleObj?.role?.custom">{{ roleObj.role.type }}</ng-container>
                <ng-container *ngIf="!roleObj?.role?.custom">{{ 'LIST.role.' + roleObj.role.type | translate }}</ng-container>
              </ion-label>
              <ion-text slot="end" *ngIf="selected === roleObj.role">
                <ion-icon name="checkmark-outline"></ion-icon>
              </ion-text>
            </ion-item>
            <ion-item-options side="end" (ionSwipe)="checkSwipe(roleObj.role.type)" *ngIf="!newAccount">
              <ion-item-option color="success" (click)="presentRolePrompt(i, roleObj.role, slidingItem)" *ngIf="checkSlideItem(roleObj.role.type)"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
              <ion-item-option color="medium" (click)="deleteRolePrompt(i, slidingItem)" *ngIf="checkSlideItem(roleObj.role.type)"><ion-icon name="trash-outline"></ion-icon></ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ng-container>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>
  
</ion-content>
