<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'FILTER.title_field' | translate: { field: 'GIFT.title.list' | translate } }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="reset()" [disabled]="isEmptyForm() && !sorting">
        <ion-icon slot="icon-only" name="refresh"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" color="light">
  <ion-item class="ion-item-no-padding full-width" color="light" lines="none">
    <ion-label position="stacked" color="dark">{{ 'LBL.sorting' | translate }}</ion-label>
    <ion-item class="ion-item-no-padding full-width" color="light" lines="none">
      <ion-select class="prompt-input-item select-padding" [(ngModel)]="sorting">
        <ion-select-option *ngIf="sortingField?.name" [value]="''">
          {{ 'GIFT.lbl.name' | translate }} & {{ 'LBL.amount' | translate }} ({{ 'LBL.default' | translate }})
        </ion-select-option>
        <ion-select-option *ngIf="sortingField?.currency" value="currency">
          {{ 'LBL.currency' | translate }}
        </ion-select-option>
        <ion-select-option *ngIf="sortingField?.amount" value="amount">
          {{ 'LBL.amount' | translate }}
        </ion-select-option>
        <ion-select-option *ngIf="sortingField?.checkinTime" value="checkinTime">
          {{ 'CHECKIN.lbl.time' | translate }}
        </ion-select-option>
        <ion-select-option *ngIf="sortingField?.createdTime" value="createdTime">
          {{ 'LBL.created_time' | translate }}
        </ion-select-option>
        <ion-select-option *ngIf="sortingField?.updatedTime" value="updatedTime">
          {{ 'LBL.updated_time' | translate }}
        </ion-select-option>
        <ion-select-option *ngIf="sortingField?.deletedTime" value="deletedTime">
          {{ 'LBL.delete_time' | translate }}
        </ion-select-option>
      </ion-select>
      <ion-buttons slot="end">
        <ion-button class="margin-right-none" fill="clear" (click)="desc = !desc;">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/sort-alpha-asc.svg" *ngIf="!desc"></ion-icon>
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/sort-alpha-desc.svg" *ngIf="desc"></ion-icon>
        </ion-button> 
      </ion-buttons>
    </ion-item>
    <div class="error-div">
    </div>
  </ion-item>
  <form *ngIf="filterForm" [formGroup]="filterForm" (ngSubmit)="filterFormSubmit()">
    <ion-list class="background-transparent">
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.type">
        <ion-label position="stacked" color="dark">{{ 'LBL.type' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="type">
          <ion-select-option value="cash">
            {{ 'LIST.gift.cash' | translate }}
          </ion-select-option>
          <ion-select-option value="other">
            {{ 'LIST.gift.other' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.currency && currencyList?.length">
        <ion-label position="stacked" color="dark">{{ 'LBL.currency' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="currency">
          <ion-select-option *ngFor="let currency of currencyList" [value]="currency">
            {{ currency }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.amount && amount?.upper">
        <ion-label position="stacked" color="dark">
          {{ 'LBL.amount' | translate }} 
          <span class="font-12" *ngIf="filterForm?.value?.amount?.lower || filterForm?.value?.amount?.upper">
            ( {{ filterForm?.value?.amount?.lower ? filterForm.value.amount.lower : 'min' }} - {{ filterForm?.value?.amount?.upper ? filterForm.value.amount.upper : 'max' }} )
          </span>
        </ion-label>
        <ion-range formControlName="amount" debounce="500" dualKnobs="true" [min]="amount.lower" [max]="amount.upper" pin="true" snaps="false" step="1"></ion-range>
        <div class="error-div">
        </div>
      </ion-item>
      <div *ngIf="showField?.invitedBy && invitedByList?.length > 1">
        <ion-label class="custom-stacked-label" position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}</ion-label>
        <ion-segment scrollable formControlName="invitedBy" *ngIf="invitedByList">
          <ion-segment-button [value]="invitedBy" *ngFor="let invitedBy of invitedByList">
            <ion-text>
              <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
              <ng-container *ngIf="!invitedBy.custom">{{ 'LIST.invited_by.' + invitedBy.value | translate }}</ng-container>
            </ion-text>
          </ion-segment-button>
        </ion-segment>
        <div class="error-div">
        </div>
      </div>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.category" (click)="presentSettingFieldModal('category')">
        <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ category }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>
      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.checkinStatus">
        <ion-label position="stacked" color="dark">{{ 'CHECKIN.lbl.status' | translate }}</ion-label>
        <ion-select class="prompt-input-item" formControlName="checkinStatus">
          <ion-select-option value="attended">
            {{ 'LIST.checkin.attended' | translate }}
          </ion-select-option>
          <ion-select-option value="not_attend">
            {{ 'LIST.checkin.not_attend' | translate }}
          </ion-select-option>
        </ion-select>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.checkinBy" (click)="presentAccountUserModal('checkinBy')">
        <ion-label position="stacked" color="dark">{{ 'CHECKIN.lbl.by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.checkinBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.createdBy" (click)="presentAccountUserModal('createBy')">
        <ion-label position="stacked" color="dark">{{ 'LBL.created_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm?.value?.createBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.updatedBy" (click)="presentAccountUserModal('updateBy')">
        <ion-label position="stacked" color="dark">{{ 'LBL.updated_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.updateBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="showField?.deleteBy" (click)="presentAccountUserModal('deleteBy')">
        <ion-label position="stacked" color="dark">{{ 'LBL.delete_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.deleteBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>
    </ion-list>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="filterFormSubmit()" [disabled]="!filterForm || !filterForm.valid">
      {{ 'BTN.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>