<ng-container *ngIf="guest">
  <ng-container *ngIf="!group else groupCardBlock">
    <ion-card class="guest-card ion-no-margin" [color]="(selectedGuestList && selectedGuestList?.indexOf(guest?.guestId) !== -1) || selected ? 'tertiary' : 'white'">
      <app-guest-item [mode]="mode" [blastUsageId]="blastUsageId" [blastTemplate]="blastTemplate" [setGuest]="guest" [setupGuest]="disableWatchGuest ? '' : guest?.guestId" [editMode]="editMode" [selectMode]="selectMode" [selected]="selected" [selectedGuestList]="selectedGuestList" [index]="index" [setHideField]="hideField" [disableClick]="disableClick" [disableQrcode]="disableQrcode" [disableSeating]="disableSeating" [disableEdit]="disableEdit" [disableSlide]="disableSlide" [showGuestSeating]="showGuestSeating || groupModal ? true: false" (click)="guestClick(guest)"></app-guest-item>
    </ion-card>
  </ng-container>
  <!-- [disabled]="editMode || selectMode" -->
  <ng-template #groupCardBlock>
    <ion-card class="group-card ion-no-margin" [color]="(selectedGroupList && selectedGroupList?.indexOf(group.groupId) !== -1 ) || selected ? 'tertiary' : 'white'">
      <ion-item-sliding [disabled]="disableSlide" #groupNameItem>
        <ion-item [class]="mode === 'invites' ? 'invites-member-item' : 'member-item'"  [color]="(selectedGroupList && selectedGroupList?.indexOf(group.groupId) !== -1 ) || selected ? 'tertiary' : 'white'" lines="full" [button]="disableClick || (groupModal && (module === 'trash' || module === 'gift')) ? false : true" [detail]="mode === 'invites'" (click)="guestClick(guest, null, true)">
          <ion-text slot="start" *ngIf="mode !== 'visitor'">
            <!-- mode !== 'invites' ||  -->
            <div *ngIf="groupModal">
              <ion-icon class="icon-large" src="./assets/wedding/icon/guest/group-green.svg"></ion-icon>
            </div>
            <!-- mode === 'invites' &&  -->
            <div *ngIf="group?.status?.attending && !groupModal">
              <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/guest/status/pending.svg" *ngIf="group.status.attending === 'pending'"></ion-icon>
              <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/guest/status/attending.svg" *ngIf="group.status.attending === 'attending'"></ion-icon>
              <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/guest/status/not-attending.svg" *ngIf="group.status.attending === 'not_attending'"></ion-icon>
            </div>
          </ion-text>
          
          <ion-label>
            <div class="ion-no-margin" *ngIf="group?.groupName">
              <ion-text class="font-14 font-bold">{{ group.groupName }}</ion-text>
            </div>
            <div class="font-12" *ngIf="group?.memberList?.length">
              <ng-container *ngIf="groupTotal && groupTotal > group.memberList.length else groupCountBlock">
                {{ 'SUMMARY.no_of_total_field' | translate: { no: group.memberList.length, total: groupTotal, field: groupTotal === 1 ? ('GUEST.lbl.guest' | translate) : ('GUEST.lbl.guests' | translate) } }}
              </ng-container>
              <ng-template #groupCountBlock>
                <ng-container *ngIf="group?.memberList?.length">
                  {{ 'GUEST.lbl.guests_no' | translate: { pax: group.memberList.length } }}
                </ng-container>
              </ng-template>
            </div>
            <div class="font-10" *ngIf="mode === 'invites' && group?.rsvp?.createBy?.time?.seconds">
              {{ format(group.rsvp.createBy.time.seconds * 1000) }}
            </div>
          </ion-label>
          <ion-text slot="end" *ngIf="module === 'guest' && mode !== 'visitor' &&  showField.qrcodeStatus">
            <div>
              <ion-icon class="icon-small" src="./assets/wedding/icon/qrcode/qrcode.svg" *ngIf="!group?.status?.qrcode"></ion-icon>
              <ion-icon class="icon-small" src="./assets/wedding/icon/qrcode/qrcode-green.svg" *ngIf="group?.status?.qrcode"></ion-icon>
            </div>
          </ion-text>
          <ion-text slot="end" *ngIf="module === 'gift' && group?.giftList?.length">
            <div *ngIf="giftCount?.cash > 0"><ion-icon class="icon-small vertical-middle" src="./assets/wedding/icon/gift/cash.svg"></ion-icon></div>
            <div *ngIf="giftCount?.other > 0"><ion-icon class="icon-small vertical-middle" src="./assets/wedding/icon/gift/gift.svg"></ion-icon></div>
          </ion-text>
          <ion-text slot="end" *ngIf="module === 'blast' && blastUsageId && (mode === 'sms-result' || mode === 'whatsapp-result')">
            <div *ngIf="blastMsg">
              <div *ngIf="deliveredStatus">
                <ion-icon color="dark" name="time-outline" *ngIf="deliveredStatus === 'processing'"></ion-icon>
                <ion-icon color="dark" name="checkmark-outline" *ngIf="deliveredStatus === 'sent'"></ion-icon>
                <ion-icon color="dark" name="checkmark-done-outline" *ngIf="deliveredStatus === 'delivered'"></ion-icon>
                <ion-icon color="danger" name="alert-circle" *ngIf="deliveredStatus === 'failed'"></ion-icon>
                <ion-icon class="wa-read-icon" name="checkmark-done-outline" *ngIf="deliveredStatus === 'read'"></ion-icon>
              </div>
            </div>
          </ion-text>
          <!-- <ion-text class="font-12" slot="end" *ngIf="mode === 'invites' && group?.status?.attending">
            {{ 'LIST.attending_status.' + group.status.attending | translate }}
          </ion-text> -->
        </ion-item>
        <ion-item-options side="end">
          <ng-container *ngIf="module === 'guest'">
            <ion-item-option color="tertiary" (click)="presentGuestQrcodeModal(group, groupNameItem)" *ngIf="!disableQrcode"><ion-icon src="./assets/wedding/icon/qrcode/qrcode.svg"></ion-icon></ion-item-option>
            <ion-item-option color="primary" (click)="seating(groupNameItem)" *ngIf="!disableSeating"><ion-icon src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon></ion-item-option>
          </ng-container>
          <ng-container *ngIf="module === 'checkin'">
            <ion-item-option color="success" (click)="promptCheckin(group, groupNameItem)" *ngIf="!group?.status?.checkin && !disableCheckin">
              <ion-text class="ion-padding-horizontal">
                <ion-icon class="checkin-btn-icon vertical-middle" src="./assets/wedding/icon/checkin/tick-round.svg"></ion-icon> <ion-text class="font-12">{{ 'MODULE.list.checkin' | translate }}</ion-text>
              </ion-text>
            </ion-item-option>
            <ion-item-option color="medium" (click)="promptUncheck(group, groupNameItem)" *ngIf="group?.status?.checkin && !disableUncheck">
              <ion-text class="ion-padding-horizontal">
                <ion-icon class="checkin-btn-icon vertical-middle" src="./assets/wedding/icon/checkin/close-round.svg"></ion-icon> <ion-text class="font-12">{{ 'MODULE.action.checkin.uncheck' | translate }}</ion-text>
              </ion-text>
            </ion-item-option>
          </ng-container>
          <ion-item-option color="success" (click)="edit(groupNameItem)" *ngIf="!disableEdit && (module === 'guest' || module === 'blast')"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
          <ion-item-option color="success" (click)="restore(groupNameItem)" *ngIf="!disableRestore && module === 'trash'"><ion-icon name="arrow-undo-outline"></ion-icon></ion-item-option>
          <ion-item-option color="danger" (click)="deleteFunc(groupNameItem)" *ngIf="!disableDelete && (module === 'guest')"><ion-icon name="trash-outline"></ion-icon></ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
      
      <ng-container *ngIf="mode !== 'invites' || groupModal">
        <ng-container *ngIf="guestList?.length && memberList?.length else memberListBlock">
          <div class="full-width" [style.height.px]="memberList.length * 100" *ngIf="memberList?.length">
            <app-guest-item *ngFor="let guest of memberList; trackBy: trackByFn" [style.height.px]="100" [mode]="mode" [blastUsageId]="blastUsageId" [blastTemplate]="blastTemplate" [watchGuest]="false" [setGuest]="guest" [editMode]="editMode" [selectMode]="selectMode" [selected]="selected" [selectedGuestList]="selectedGuestList" [index]="index" [setHideField]="hideField" [groupModal]="groupModal" [seatingTypeName]="seatingTypeName" [disableClick]="disableClick" [disableQrcode]="disableQrcode" [disableSeating]="disableSeating" [disableEdit]="disableEdit" [disableSlide]="disableSlide" [showGuestSeating]="showGuestSeating ? true: false"  (click)="guestClick(null, guestId)"></app-guest-item>
            <!-- <cdk-virtual-scroll-viewport [itemSize]="100" [minBufferPx]="300" [maxBufferPx]="300" [style.height.px]="memberList.length * 100">
              <ion-list class="full-width-height background-transparent" [style.height.px]="memberList.length * 100" #list>
                <app-guest-item *ngFor="let guest of memberList; trackBy: trackByFn" [style.height.px]="100" [mode]="mode" [watchGuest]="false" [setGuest]="guest" [editMode]="editMode" [selectMode]="selectMode" [selected]="selected" [selectedGuestList]="selectedGuestList" [index]="index" [setHideField]="hideField" [groupModal]="groupModal" [seatingTypeName]="seatingTypeName" [disableClick]="disableClick" [disableQrcode]="disableQrcode" [disableSeating]="disableSeating" [disableEdit]="disableEdit" [disableSlide]="disableSlide" [showGuestSeating]="showGuestSeating ? true: false"  (click)="guestClick(null, guestId)"></app-guest-item>
              </ion-list>
            </cdk-virtual-scroll-viewport> -->
          </div>
          <!-- <app-guest-item *ngFor="let guest of memberList; trackBy: trackByFn" [mode]="mode" [watchGuest]="false" [setGuest]="guest" [editMode]="editMode" [selectMode]="selectMode" [selected]="selected" [selectedGuestList]="selectedGuestList" [index]="index" [setHideField]="hideField" [groupModal]="groupModal" [seatingTypeName]="seatingTypeName" [disableClick]="disableClick" [disableQrcode]="disableQrcode" [disableSeating]="disableSeating" [disableEdit]="disableEdit" [disableSlide]="disableSlide" [showGuestSeating]="showGuestSeating ? true: false"  (click)="guestClick(null, guestId)"></app-guest-item> -->
        </ng-container>
        <ng-template #memberListBlock>
          <ng-container *ngIf="!disableGroupMemberList && group?.memberList?.length">
            <div class="full-width" [style.height.px]="group.memberList.length * 100" *ngIf="group?.memberList?.length">
              <app-guest-item *ngFor="let guestId of group.memberList; trackBy: trackByFn" [style.height.px]="100" [mode]="mode" [blastUsageId]="blastUsageId" [blastTemplate]="blastTemplate" [watchGuest]="true" [setupGuest]="guestId" [editMode]="editMode" [selectMode]="selectMode" [selected]="selected" [selectedGuestList]="selectedGuestList" [index]="index" [setHideField]="hideField" [groupModal]="groupModal" [seatingTypeName]="seatingTypeName" [disableClick]="disableClick" [disableQrcode]="disableQrcode" [disableSeating]="disableSeating" [disableEdit]="disableEdit" [disableSlide]="disableSlide" [showGuestSeating]="showGuestSeating ? true: false" (click)="guestClick(null, guestId)"></app-guest-item>
              <!-- <cdk-virtual-scroll-viewport [itemSize]="100" [minBufferPx]="300" [maxBufferPx]="300"  [style.height.px]="group.memberList.length * 100">
                <ion-list class="full-width-height background-transparent" [style.height.px]="group.memberList.length * 100" #list>
                  <app-guest-item *ngFor="let guestId of group.memberList; trackBy: trackByFn" [style.height.px]="100" [mode]="mode" [watchGuest]="true" [setupGuest]="guestId" [editMode]="editMode" [selectMode]="selectMode" [selected]="selected" [selectedGuestList]="selectedGuestList" [index]="index" [setHideField]="hideField" [groupModal]="groupModal" [seatingTypeName]="seatingTypeName" [disableClick]="disableClick" [disableQrcode]="disableQrcode" [disableSeating]="disableSeating" [disableEdit]="disableEdit" [disableSlide]="disableSlide" [showGuestSeating]="showGuestSeating ? true: false" (click)="guestClick(null, guestId)"></app-guest-item>
                </ion-list>
              </cdk-virtual-scroll-viewport> -->
            </div>
          </ng-container>
        </ng-template>
      </ng-container>

    </ion-card>
  </ng-template>
</ng-container>