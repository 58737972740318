<ion-card class="filter-card" color="tertiary" *ngIf="filter">
  <ion-item color="tertiary" class="ion-item-no-padding" lines="none">
    <ion-label>
      <span class="font-10" (click)="toggleFilter()">
        {{ 'FILTER.conditions' | translate }}:
        <ion-icon name="chevron-up" *ngIf="filter?.show"></ion-icon>
        <ion-icon name="chevron-down" *ngIf="!filter?.show"></ion-icon>
      </span>
    </ion-label>
    <ion-buttons class="ion-no-margin" slot="end">
      <ion-button class="filter-btn font-10 ion-no-margin" size="small" fill="outline" (click)="presentFilterModal(true)">
        {{ 'CRUD.edit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-item>
  <ion-label class="font-10 value-lbl" color="dark" *ngIf="filter.show">
    <ng-container *ngIf="type === 'accountList'">
      <ng-container *ngIf="true then accountType"></ng-container>
      <ng-container *ngIf="true then startDate"></ng-container>
      <ng-container *ngIf="true then endDate"></ng-container>
      <ng-container *ngIf="true then role"></ng-container>
      <ng-container *ngIf="true then createBy"></ng-container>
      <ng-container *ngIf="true then updateBy"></ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'settingField'">
      <ng-container *ngIf="true then settingFieldSorting"></ng-container>
      <ng-container *ngIf="true then settingFieldType"></ng-container>
      <ng-container *ngIf="true then createBy"></ng-container>
      <ng-container *ngIf="true then updateBy"></ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'seating'">
      <ng-container *ngIf="true then seatingSort"></ng-container>
      <ng-container *ngIf="true then guestStatus"></ng-container>
      <ng-container *ngIf="true then invitedBy"></ng-container>
      <ng-container *ngIf="true then category"></ng-container>
      <ng-container *ngIf="true then seating"></ng-container>
      <ng-container *ngIf="true then group"></ng-container>
      <ng-container *ngIf="true then dietaryReq"></ng-container>
      <ng-container *ngIf="true then specialReq"></ng-container>
      <ng-container *ngIf="true then session"></ng-container>
      <ng-container *ngIf="true then mobile"></ng-container>
      <ng-container *ngIf="true then email"></ng-container>
      <ng-container *ngIf="true then reserved"></ng-container>
      <ng-container *ngIf="true then createBy"></ng-container>
      <ng-container *ngIf="true then updateBy"></ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'guestList'">
      <ng-container *ngIf="true then guestListSort"></ng-container>
      <ng-container *ngIf="true then guestStatus"></ng-container>
      <ng-container *ngIf="true then rsvpStatus"></ng-container>
      <ng-container *ngIf="true then checkinStatus"></ng-container>
      <ng-container *ngIf="true then giftReceived"></ng-container>
      <ng-container *ngIf="true then invitedBy"></ng-container>
      <ng-container *ngIf="true then category"></ng-container>
      <ng-container *ngIf="true then seating"></ng-container>
      <ng-container *ngIf="true then group"></ng-container>
      <ng-container *ngIf="true then dietaryReq"></ng-container>
      <ng-container *ngIf="true then specialReq"></ng-container>
      <ng-container *ngIf="true then session"></ng-container>
      <ng-container *ngIf="true then mobile"></ng-container>
      <ng-container *ngIf="true then email"></ng-container>
      <ng-container *ngIf="true then createBy"></ng-container>
      <ng-container *ngIf="true then updateBy"></ng-container>
      <ng-container *ngIf="true then checkinBy"></ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'giftList'">
      <ng-container *ngIf="true then giftListSort"></ng-container>
      <ng-container *ngIf="true then giftType"></ng-container>
      <ng-container *ngIf="true then giftCurrency"></ng-container>
      <ng-container *ngIf="true then giftAmount"></ng-container>
      <ng-container *ngIf="true then guestStatus"></ng-container>
      <ng-container *ngIf="true then checkinStatus"></ng-container>
      <ng-container *ngIf="true then invitedBy"></ng-container>
      <ng-container *ngIf="true then category"></ng-container>
      <ng-container *ngIf="true then seating"></ng-container>
      <ng-container *ngIf="true then group"></ng-container>
      <ng-container *ngIf="true then dietaryReq"></ng-container>
      <ng-container *ngIf="true then specialReq"></ng-container>
      <ng-container *ngIf="true then session"></ng-container>
      <ng-container *ngIf="true then createBy"></ng-container>
      <ng-container *ngIf="true then updateBy"></ng-container>
      <ng-container *ngIf="true then checkinBy"></ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'inbox'">
      <ng-container *ngIf="true then accountTitle"></ng-container>
      <ng-container *ngIf="true then readStatus"></ng-container>
      <ng-container *ngIf="true then startDateTime"></ng-container>
      <ng-container *ngIf="true then endDateTime"></ng-container>
      <ng-container *ngIf="true then createBy"></ng-container>
    </ng-container>
  </ion-label>
</ion-card>

<ng-template #accountType>
  <div *ngIf="filter.type">
    <ion-text class="filter-label font-bold">{{ 'LBL.type' | translate }}</ion-text>
    {{ 'ACCOUNT.lbl.' + filter.type | translate }}
  </div>
</ng-template>

<ng-template #startDate>
  <div *ngIf="filter.startDate">
    <ion-text class="filter-label font-bold">{{ 'LBL.start' | translate }}</ion-text>
    {{ format(filter.startDate, 'date') }}
  </div>
</ng-template>

<ng-template #endDate>
  <div *ngIf="filter.endDate">
    <ion-text class="filter-label font-bold">{{ 'LBL.end' | translate }}</ion-text>
    {{ format(filter.endDate, 'date') }}
  </div>
</ng-template>

<ng-template #startDateTime>
  <div *ngIf="filter.startDate">
    <ion-text class="filter-label font-bold">{{ 'LBL.start' | translate }}</ion-text>
    {{ format(filter.startDate) }}
  </div>
</ng-template>

<ng-template #endDateTime>
  <div *ngIf="filter.endDate">
    <ion-text class="filter-label font-bold">{{ 'LBL.end' | translate }}</ion-text>
    {{ format(filter.endDate) }}
  </div>
</ng-template>

<ng-template #role>
  <div *ngIf="filter.role">
    <ion-text class="filter-label font-bold">{{ 'LBL.role' | translate }}</ion-text>
    {{ 'LIST.role.' + filter.role | translate }}
  </div>
</ng-template>

<ng-template #guestStatus>
  <ng-container *ngIf="filter.status">
    <div *ngIf="filter.status.attending">
      <ion-text class="filter-label font-bold">{{ 'LBL.status' | translate }}: </ion-text>
      <ion-text>{{ 'LIST.attending_status.' + filter.status.attending | translate }}</ion-text>
    </div>
    <div *ngIf="filter.status.qrcode">
      <ion-text class="filter-label font-bold">{{ 'QRCODE.lbl.guest_qrcode' | translate }}: </ion-text>
      <ion-text>{{ 'LIST.send_status.' + filter.status.qrcode | translate }}</ion-text>
    </div>
  </ng-container>
</ng-template>

<ng-template #rsvpStatus>
  <ng-container *ngIf="filter.rsvp === true || filter.rsvp === false">
    <ion-text class="filter-label font-bold">{{ 'WEBSITE.form.rsvp_status' | translate }}: </ion-text>
    <ion-text>{{ (filter?.rsvp ? 'BTN.yes' : 'BTN.no') | translate }}</ion-text>
  </ng-container>
</ng-template>

<ng-template #checkinStatus>
  <ng-container *ngIf="filter.checkinStatus">
    <ion-text class="filter-label font-bold">{{ 'CHECKIN.lbl.status' | translate }}: </ion-text>
    <ion-text>{{ 'LIST.checkin.' + filter.checkinStatus | translate }}</ion-text>
  </ng-container>
</ng-template>

<ng-template #giftReceived>
  <ng-container *ngIf="filter.giftReceived">
    <ion-text class="filter-label font-bold">{{ 'GIFT.lbl.received_gift_checkin' | translate }}: </ion-text>
    <ion-text>{{ 'GIFT.lbl.' + filter.giftReceived | translate }}</ion-text>
  </ng-container>
</ng-template>

<ng-template #invitedBy>
  <div *ngIf="filter.invitedBy">
    <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.invited_by' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="filter.invitedBy.value">
        <ng-container *ngIf="!filter.invitedBy.custom">
          {{ 'LIST.invited_by.' + filter.invitedBy.value | translate }}
        </ng-container>
        <ng-container *ngIf="filter.invitedBy.custom">
          {{ filter.invitedBy.value }}
        </ng-container>
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #category>
  <div *ngIf="filter.category">
    <ion-text class="filter-label font-bold">{{ 'LBL.category' | translate }}: </ion-text>
    <ion-text>
      {{ getSettingField('category', filter.category) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #group>
  <div *ngIf="filter.group">
    <ion-text class="filter-label font-bold">{{ 'LIST.group.group' | translate }}: </ion-text>
    <ng-container *ngIf="filter.group === 'individual'">
      {{ 'LIST.group.individual' | translate }}
    </ng-container>
    <ng-container *ngIf="filter.group === 'group'">
      {{ 'LIST.group.group' | translate }}
    </ng-container>
  </div>
</ng-template>

<ng-template #seating>
  <div *ngIf="filter.seating">
    <ion-text class="filter-label font-bold">{{ getSeatingTypeName() }}: </ion-text>
    <ng-container *ngIf="type === 'seating'">
      <ng-container *ngIf="filter.seating === 'full'">
        {{ 'LIST.occupancy.full' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.seating === 'half'">
        {{ 'LIST.occupancy.half' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.seating === 'empty'">
        {{ 'LIST.occupancy.empty' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.seating === 'overload'">
        {{ 'LIST.occupancy.overload' | translate }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="type !== 'seating'">
      <ng-container *ngIf="filter.seating === 'assigned'">
        {{ 'LIST.assign_status.assigned' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.seating === 'not_assign'">
        {{ 'LIST.assign_status.not_yet_assign' | translate }}
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #dietaryReq>
  <div *ngIf="filter.dietaryReq">
    <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.dietary_req' | translate }}: </ion-text>
    <ion-text>
      {{ getSettingField('dietary_req', filter.dietaryReq) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #specialReq>
  <div *ngIf="filter.specialReq">
    <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.special_req' | translate }}: </ion-text>
    <ion-text>
      {{ getSettingField('special_req', filter.specialReq) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #session>
  <div *ngIf="filter.session">
    <ion-text class="filter-label font-bold">{{ 'LBL.session' | translate }}: </ion-text>
    <ion-text>
      {{ getSettingField('session', filter.session) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #mobile>
  <div *ngIf="filter.mobile">
    <ion-text class="filter-label font-bold">{{ 'MOBILE.lbl.no' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="filter.mobile === 'assigned'">
        {{ 'LIST.assign_status.assigned' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.mobile === 'not_assign'">
        {{ 'LIST.assign_status.not_yet_assign' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #email>
  <div *ngIf="filter.email">
    <ion-text class="filter-label font-bold">{{ 'LBL.email' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="filter.email === 'assigned'">
        {{ 'LIST.assign_status.assigned' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.email === 'not_assign'">
        {{ 'LIST.assign_status.not_yet_assign' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #reserved>
  <div *ngIf="filter.reserved">
    <ion-text class="filter-label font-bold">{{ 'SEATING.lbl.reserved' | translate }}: </ion-text>
    <ng-container *ngIf="filter.reserved === 'none'">
      {{ 'LBL.none' | translate }}
    </ng-container>
    <ng-container *ngIf="filter.reserved === 'reserved'">
      {{ 'SEATING.lbl.reserved' | translate }}
    </ng-container>
  </div>
</ng-template>

<ng-template #giftType>
  <div *ngIf="filter.type">
    <ion-text class="filter-label font-bold">{{ 'GIFT.lbl.type' | translate }}: </ion-text>
    <ion-text>{{ 'LIST.gift.' + filter.type | translate }}</ion-text>
  </div>
</ng-template>

<ng-template #giftCurrency>
  <div *ngIf="filter.currency">
    <ion-text class="filter-label font-bold">{{ 'LBL.currency' | translate }}: </ion-text>
    <ion-text>{{ filter.currency }}</ion-text>
  </div>
</ng-template>

<ng-template #giftAmount>
  <div *ngIf="filter?.amount?.lower || filter?.amount?.upper">
    <ion-text class="filter-label font-bold">{{ 'LBL.amount' | translate }}: </ion-text>
    <ion-text>{{ filter?.amount?.lower ? filter.amount.lower : 'min' }} - {{ filter?.amount?.upper ? filter.amount.upper : 'max' }}</ion-text>
  </div>
</ng-template>

<ng-template #accountTitle>
  <div *ngIf="filter?.accountId && getAccountTitle(filter.accountId) as accountTitle">
    <ion-text class="filter-label font-bold">{{ 'MODULE.list.account' | translate }}: </ion-text>
    <ion-text>{{ accountTitle }}</ion-text>
  </div>
</ng-template>

<ng-template #readStatus>
  <div *ngIf="filter?.readStatus === true">
    <ion-text class="filter-label font-bold">{{ 'INBOX.status.read' | translate }}: </ion-text>
    <ion-text>{{ 'INBOX.status.list.readed' | translate }}</ion-text>
  </div>
  <div *ngIf="filter?.readStatus === false">
    <ion-text class="filter-label font-bold">{{ 'INBOX.status.read' | translate }}: </ion-text>
    <ion-text>{{ 'INBOX.status.list.unread' | translate }}</ion-text>
  </div>
</ng-template>

<ng-template #createBy>
  <div *ngIf="filter?.createBy">
    <ion-text class="filter-label font-bold">{{ 'LBL.created_by' | translate }}: </ion-text>
    <ion-text *ngIf="filter.createBy">
      {{ getUserName(filter.createBy) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #updateBy>
  <div *ngIf="filter.updateBy">
    <ion-text class="filter-label font-bold">{{ 'LBL.updated_by' | translate }}: </ion-text>
    <ion-text *ngIf="filter.updateBy">
      {{ getUserName(filter.updateBy) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #checkinBy>
  <div *ngIf="filter.checkinBy">
    <ion-text class="filter-label font-bold">{{ 'CHECKIN.lbl.by' | translate }}: </ion-text>
    <ion-text *ngIf="filter?.createBy">
      {{ getUserName(filter?.createBy) }}
    </ion-text>
  </div>
</ng-template>

<ng-template #settingFieldType>
  <div *ngIf="filter.type">
    <ion-text class="filter-label font-bold">{{ 'LBL.type' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="filter.type === 'system'">
        {{ 'LBL.system' | translate }}
      </ng-container>
      <ng-container *ngIf="filter.type === 'user'">
        {{ 'LBL.user' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #settingFieldSorting>
  <div *ngIf="sorting || desc">
    <ion-text class="filter-label font-bold">{{ 'LBL.sorting' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="sorting">
        <ng-container *ngIf="sorting === 'type'">
          {{ 'lbl.type' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'createdTime'">
          {{ 'LBL.created_time' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'updatedTime'">
          {{ 'LBL.updated_time' | translate }}
        </ng-container>
        ,
      </ng-container>
      <ng-container *ngIf="desc">
        {{ 'LBL.descending' | translate }}
      </ng-container>
      <ng-container *ngIf="!desc && sorting">
        {{ 'LBL.ascending' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #guestListSort>
  <div *ngIf="sorting || desc">
    <ion-text class="filter-label font-bold">{{ 'LBL.sorting' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="sorting">
        <ng-container *ngIf="sorting === 'name'">
          {{ 'GUEST.lbl.guest_name' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'invitedBy'">
          {{ 'GUEST.lbl.invited_by' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'category'">
          {{ 'LBL.category' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'seating'">
          {{ 'SEATING.lbl.name' | translate: { seating: getSeatingTypeName() } }}
        </ng-container>
        <ng-container *ngIf="sorting === 'createdTime'">
          {{ 'LBL.created_time' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'updatedTime'">
          {{ 'LBL.updated_time' | translate }}
        </ng-container>
        ,
      </ng-container>
      <ng-container *ngIf="desc">
        {{ 'LBL.descending' | translate }}
      </ng-container>
      <ng-container *ngIf="!desc && sorting">
        {{ 'LBL.ascending' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #seatingSort>
  <div *ngIf="sorting || desc">
    <ion-text class="filter-label font-bold">{{ 'LBL.sorting' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="sorting">
        <ng-container *ngIf="sorting === 'name'">
          {{ 'SEATING.lbl.name' | translate: { seating: getSeatingTypeName() } }}
        </ng-container>
        <ng-container *ngIf="sorting === 'count'">
          {{ 'SEATING.lbl.count' | translate: { seating: getSeatingTypeName() } }}
        </ng-container>
        <ng-container *ngIf="sorting === 'maxGuest'">
          {{ 'SEATING.lbl.max_guest' | translate: { seating: getSeatingTypeName() } }}
        </ng-container>
        <ng-container *ngIf="sorting === 'availableSeating'">
          {{ 'SEATING.lbl.available' | translate: { seating: getSeatingTypeName() } }}
        </ng-container>
        <ng-container *ngIf="sorting === 'guestCount'">
          {{ 'SEATING.lbl.guest_count' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'createdTime'">
          {{ 'LBL.created_time' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'updatedTime'">
          {{ 'LBL.updated_time' | translate }}
        </ng-container>
        ,
      </ng-container>
      <ng-container *ngIf="desc">
        {{ 'LBL.descending' | translate }}
      </ng-container>
      <ng-container *ngIf="!desc && sorting">
        {{ 'LBL.ascending' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>

<ng-template #giftListSort>
  <div *ngIf="sorting || desc">
    <ion-text class="filter-label font-bold">{{ 'LBL.sorting' | translate }}: </ion-text>
    <ion-text>
      <ng-container *ngIf="sorting">
        <ng-container *ngIf="sorting === 'name'">
          {{ 'GUEST.lbl.guest_name' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'invitedBy'">
          {{ 'GUEST.lbl.invited_by' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'category'">
          {{ 'LBL.category' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'seating'">
          {{ 'SEATING.lbl.name' | translate: { seating: getSeatingTypeName() } }}
        </ng-container>
        <ng-container *ngIf="sorting === 'createdTime'">
          {{ 'LBL.created_time' | translate }}
        </ng-container>
        <ng-container *ngIf="sorting === 'updatedTime'">
          {{ 'LBL.updated_time' | translate }}
        </ng-container>
        ,
      </ng-container>
      <ng-container *ngIf="desc">
        {{ 'LBL.descending' | translate }}
      </ng-container>
      <ng-container *ngIf="!desc && sorting">
        {{ 'LBL.ascending' | translate }}
      </ng-container>
    </ion-text>
  </div>
</ng-template>


