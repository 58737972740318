<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'CRUD.new_field' | translate: { field: settingFieldTitle } }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding" color="light">
    <ion-item class="prompt-input-item" lines="none">
      <ion-input [(ngModel)]="newValue" class="font-14 ion-text-left" type="text" inputmode="text" [placeholder]="'CRUD.enter_new_field' | translate: { field: settingFieldTitle }" [autocapitalize]="true" autocomplete="on" clearInput>
        <ion-button class="btn" size="large" color="primary" fill="solid" slot="end" (click)="checkSettingField(newValue)" [disabled]="!newValue">
          {{ 'CRUD.add' | translate }}
        </ion-button>
      </ion-input>
    </ion-item>  
    <div class="msg-div">
      <ion-text class="font-12"> {{ 'SETTING.lbl.select_new_field' | translate: { field: settingFieldTitle } }}</ion-text>
    </div>   
  </ion-toolbar>
</ion-header>
<ion-content color="light" [scrollY]="false">
  <div class="viewport-div ion-content-scroll-host">
    <ion-list class="full-width-height ion-padding-bottom" *ngIf="stdList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="48" #cdkVirtualScrollViewport>
        <ion-item-sliding *cdkVirtualFor="let settingField of stdList; let i = index;" [disabled]="true" #slidingItem>
          <ion-item class="item" [color]="checkSelected(settingField) ? 'tertiary' : 'white'" lines="none" (click)="select(settingField)">
            <ion-reorder slot="start" class="reorder-button">
              <ion-icon name="reorder-two-outline" color="medium"></ion-icon>
            </ion-reorder>
            <ion-label class="font-14 ion-text-wrap">
              {{ getValue(settingField) }}
            </ion-label>
            <ion-text slot="end" *ngIf="checkSelected(settingField)">
              <ion-icon name="checkmark-outline"></ion-icon>
            </ion-text>
          </ion-item>
        </ion-item-sliding>
      </cdk-virtual-scroll-viewport>
    </ion-list>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-no-padding">
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="save()" [disabled]="!selected?.length">
      {{ 'BTN.select' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
