<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'FILTER.title_field' | translate: { field: 'GUEST.lbl.special_req' | translate } }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="reset()" [disabled]="isEmptyForm() && !sorting">
        <ion-icon slot="icon-only" name="refresh"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" color="light">
  <ion-item class="ion-item-no-padding full-width" color="light" lines="none">
    <ion-label position="stacked" color="dark">{{ 'LBL.sorting' | translate }}</ion-label>
    <ion-item class="ion-item-no-padding full-width" color="light" lines="none">
      <ion-select class="prompt-input-item" [(ngModel)]="sorting">
        <ion-select-option value="">
          {{ 'LBL.name' | translate }} ({{ 'LBL.default' | translate }})
        </ion-select-option>
        <ion-select-option value="createdTime">
          {{ 'LBL.created_time' | translate }}
        </ion-select-option>
        <ion-select-option value="updatedTime">
          {{ 'LBL.updated_time' | translate }}
        </ion-select-option>
      </ion-select>
      <ion-buttons slot="end">
        <ion-button fill="clear" (click)="desc = !desc;" style="margin-right: 0;">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/sort-alpha-asc.svg" *ngIf="!desc"></ion-icon>
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/sort-alpha-desc.svg" *ngIf="desc"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
    <div class="error-div">
    </div>
  </ion-item>
  <form [formGroup]="filterForm" (ngSubmit)="filterFormSubmit()" *ngIf="filterForm">
    <ion-list>
      <ion-item class="ion-item-no-padding" color="light" lines="none" (click)="presentAccountUserModal('createBy', true)">
        <ion-label position="stacked" color="dark">{{ 'LBL.created_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.createBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" color="light" lines="none" (click)="presentAccountUserModal('updateBy')">
        <ion-label position="stacked" color="dark">{{ 'LBL.updated_by' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none">
          <ion-label class="ion-no-margin" >{{ getUserName(filterForm.value.updateBy) }}</ion-label>
          <ion-note slot="end"><ion-icon name="chevron-down" class="font-18"></ion-icon></ion-note>
        </ion-item>
        <div class="error-div">
        </div>
      </ion-item>
    </ion-list>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="filterFormSubmit()" [disabled]="!filterForm || !filterForm.valid">
      {{ 'BTN.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>