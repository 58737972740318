<ion-card class="ion-no-margin {{ cardType }}-card" (click)="presentModal()" [color]="whiteBackground ? 'white' : ''" style="--background: linear-gradient(90deg, var(--ion-color-tertiary) 0%, var(--ion-color-tertiary) {{ percent }}%, white {{ percent }}%, white 100%)" *ngIf="showCard()" button>
  <ng-container *ngIf="cardType === 'square'">
    <ion-item class="square-item background-transparent" [ngClass]="cardType === 'square' ? 'ion-item-no-padding' : ''" [style.margin]="margin" lines="none">
      <ion-label class="font-normal ion-text-center ion-no-margin">
        <div style="margin-top: 6px;"><ng-container *ngIf="all && statisticType !== 'checkin' then iconTemplate"></ng-container></div>
        <ion-label class="font-12 line-110 font-med ion-text-wrap ion-no-margin" color="dark">{{ name }}</ion-label>
        <div class="font-24"><ng-container *ngIf="true then valueTemplate"></ng-container></div>
      </ion-label>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="cardType === 'rect'">
    <ion-item class="rect-item background-transparent" [color]="color ? color : ''" lines="none">
      <ng-container *ngIf="all then iconTemplate"></ng-container>
      <ion-label class="font-20 ion-text-right ion-no-margin" style="margin-left: 6px;">
        <ng-container *ngIf="true then valueTemplate"></ng-container>
      </ion-label>
    </ion-item>
    <ion-item class="full-width" style="--min-height: 44px;" color="white" lines="none">
      <ion-label class="font-12 line-110 font-med ion-text-wrap ion-no-margin ion-text-center" color="dark">{{ name }}</ion-label>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="cardType === 'block'">
    <ion-item class="background-transparent"[style.margin]="margin" lines="none">
      <ng-container *ngIf="all then iconTemplate"></ng-container>
      <div style="margin-right: 6px;">
        <ion-text class="font-14 line-120 font-bold ion-text-wrap" color="dark">
          {{ name }}
        </ion-text>
      </div>
      <ng-container *ngIf="true then fullValueTemplate"></ng-container>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="cardType === 'dashboard'">
    <ion-item class="dashboard-item ion-item-no-padding background-transparent" [style.margin]="margin" lines="none">
      <ion-label class="ion-no-margin ion-text-center">
        <div>
          <ion-icon *ngIf="statisticType === 'attending'" class="icon-small" color="primary" src="./assets/wedding/icon/general/envelope-outline.svg"></ion-icon>
          <ion-icon *ngIf="statisticType === 'checkin'" class="icon-small" color="primary" name="checkmark-circle-outline"></ion-icon>
        </div>
        <div>
          <ion-label class="font-10 line-110 font-med ion-text-wrap ion-no-margin" color="dark">{{ name }}</ion-label>
        </div>
        <div class="font-20">
          <ng-container *ngIf="true then valueTemplate"></ng-container>
        </div>
      </ion-label>
    </ion-item>
  </ng-container>  
</ion-card>

<ng-template #iconTemplate>
  <ng-container *ngIf="statisticType === 'checkin'">
    <ng-container *ngIf="cardType === 'block'">
      <ion-icon class="icon-large" [class]="cardType !== 'block' ? 'ion-no-margin' : ''" slot="start" *ngIf="checkinType === 'attended'" src="./assets/wedding/icon/checkin/tick-round.svg"></ion-icon>
      <ion-icon class="icon-large" [class]="cardType !== 'block' ? 'ion-no-margin' : ''" slot="start" *ngIf="checkinType === 'not_attend'" src="./assets/wedding/icon/checkin/close-round.svg"></ion-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="statisticType === 'attending'">
    <ng-container *ngIf="cardType === 'block'">
      <ion-icon class="font-40" slot="start" *ngIf="attendingType === 'attending'" src="./assets/wedding/icon/guest/status/attending-round-background.svg"></ion-icon>
      <ion-icon class="font-40" slot="start" *ngIf="attendingType === 'pending'" src="./assets/wedding/icon/guest/status/pending-round-background.svg"></ion-icon>
      <ion-icon class="font-40" slot="start" *ngIf="attendingType === 'not_attending'" src="./assets/wedding/icon/guest/status/not-attending-round-background.svg"></ion-icon>
    </ng-container>
    <ng-container *ngIf="cardType !== 'block'">
      <ion-icon class="icon-large ion-no-margin" slot="start" *ngIf="attendingType === 'attending'" src="./assets/wedding/icon/guest/status/attending.svg"></ion-icon>
      <ion-icon class="icon-large ion-no-margin" slot="start" *ngIf="attendingType === 'pending'" src="./assets/wedding/icon/guest/status/pending.svg"></ion-icon>
      <ion-icon class="icon-large ion-no-margin" slot="start" *ngIf="attendingType === 'not_attending'" src="./assets/wedding/icon/guest/status/not-attending.svg"></ion-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="statisticType === 'seating'">
    <ng-container *ngIf="cardType === 'block'">
      <ion-icon class="font-34" slot="start" *ngIf="seatingType === 'assigned'" src="./assets/wedding/icon/guest/seating-green.svg"></ion-icon>
      <ion-icon class="font-34" slot="start" *ngIf="seatingType === 'not_assign'" src="./assets/wedding/icon/guest/seating.svg"></ion-icon>
    </ng-container>
    <ng-container *ngIf="cardType !== 'block'">
      <ion-icon class="font-34 ion-no-margin" slot="start" src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="statisticType === 'qrcode'">
    <ng-container *ngIf="cardType === 'block'">
      <ion-icon class="font-34" slot="start" *ngIf="qrcodeType === 'sent'" src="./assets/wedding/icon/qrcode/qrcode-green.svg"></ion-icon>
        <ion-icon class="font-34" slot="start" *ngIf="qrcodeType === 'not_send'" src="./assets/wedding/icon/qrcode/qrcode-grey.svg"></ion-icon>
    </ng-container>
    <ng-container *ngIf="cardType !== 'block'">
      <ion-icon class="font-34 ion-no-margin" slot="start" src="./assets/wedding/icon/qrcode/qrcode.svg"></ion-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="statisticType === 'group'">
    <ng-container *ngIf="cardType === 'block'">
      <ion-icon class="font-34" slot="start" *ngIf="groupType === 'individual'" src="./assets/wedding/icon/guest/individual.svg"></ion-icon>
      <ion-icon class="font-34" slot="start" *ngIf="groupType === 'group'" src="./assets/wedding/icon/guest/group.svg"></ion-icon>
    </ng-container>
    <ng-container *ngIf="cardType !== 'block'">
      <ion-icon class="font-34 ion-no-margin" slot="start" *ngIf="groupType === 'individual'" src="./assets/wedding/icon/guest/individual.svg"></ion-icon>
      <ion-icon class="font-34 ion-no-margin" slot="start" *ngIf="groupType === 'group'" src="./assets/wedding/icon/guest/group.svg"></ion-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="statisticType === 'giftReceived'">
    <ion-icon class="font-34" slot="start" src="./assets/wedding/icon/gift/gift-outline.svg"></ion-icon>
  </ng-container>
</ng-template>

<ng-template #fullValueTemplate>
  <ion-text class="ion-text-right ion-no-margin" slot="end">
    <ion-text class="font-30 font-bold" color="dark"><ng-container *ngIf="showPercent then percentBlock"></ng-container></ion-text>
    <ion-text class="font-30 font-bold" color="dark"><ng-container *ngIf="showNumber then countTemplate"></ng-container></ion-text>
    <ion-text class="font-10" color="medium"><ng-container *ngIf="all then totalTemplate"></ng-container></ion-text>
  </ion-text>
</ng-template>

<ng-template #valueTemplate>
  <ion-text class="ion-no-margin">
    <ng-container *ngIf="showNumber then countTemplate"></ng-container>
    <ng-container *ngIf="showTotal then totalTemplate"></ng-container>
    <ng-container *ngIf="showPercent then percentBlock"></ng-container>
  </ion-text>
</ng-template>

<ng-template #percentBlock>
  <div><ion-text class="font-bold">{{ percent > 100 ? 100 : percent < 0 ? 0 : percent }}%</ion-text></div>
</ng-template>

<ng-template #countTemplate>
  <div><ion-text class="font-bold">{{ count }}</ion-text></div>
</ng-template>

<ng-template #totalTemplate>
  <div class="font-normal font-10 ion-text-right" *ngIf="statisticType !== 'giftReceived'">{{ 'LBL.of_total' | translate: { total: total } }}</div>
</ng-template>