<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="center ion-no-margin ion-padding">
      <ion-button class="close-btn ion-no-margin" fill="clear" color="dark" (click)="dismissModal()">
        <ion-icon class="font-24" color="white" name="close" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-img class="full-width center" [src]="'./assets/wedding/img/website/setting/upgrade.webp'">
      </ion-img>
      <div class="ion-padding-vertical">
        <div>
          <ion-text class="font-16 font-bold">
            {{ 'WEBSITE.upgrade.popup.title' | translate }}
          </ion-text>
        </div>
        <div class="ion-padding-top">
          <ion-text class="font-14">
            {{ replaceEventType('WEBSITE.upgrade.popup.msg' | translate) }}
          </ion-text>
        </div>
      </div>
      <ion-button class="full-width" (click)="upgradeNow()">
        {{ 'EXPIRY.upgrade' | translate }}
      </ion-button>
    </ion-card>
  </div>
</ion-content>
