import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo } from 'src/app/interfaces/account';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteDetail, WebsiteTheme, WebsiteThemeColor } from 'src/app/interfaces/website';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { ModalController, Platform } from '@ionic/angular';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { ErrorService } from 'src/app/services/general/error.service';
import { PopupService } from 'src/app/services/general/popup.service';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-website-pdf',
  templateUrl: './website-pdf.component.html',
  styleUrls: ['./website-pdf.component.scss'],
})
export class WebsitePdfComponent  implements OnInit, OnDestroy {

  accountId: string;
  guestId: string;
  groupId: string;
  linkId: string;
  accountInfo: AccountInfo;
  websiteDetail: WebsiteDetail;
  websiteTheme: WebsiteTheme;
  websiteThemeColor: WebsiteThemeColor;
  guest: Guest;
  group: Group;
  memberList: Guest[];

  url: string;
  name: string;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.popupService.presentLoading();
  }

  ionViewDidEnter() {
  }

  ionViewWillLeave() {
    this.popupService.dismissLoading();
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }
  
  async download() {
    const content = document.getElementById('website-pdf-content');
    // Save the original styles
    // const originalOverflow = content.style.overflow;

    // // Temporarily expand the container to show all content
    // content.style.overflow = 'visible';

    if (content) {
      html2canvas(content, {
        scrollY: 0, // Prevent scrolling issues
        useCORS: true, // Enable CORS for cross-origin images
        height: content.scrollHeight, // Full height of the content
      }).then(async (canvas) => {
        // Restore the original styles
        // content.style.overflow = originalOverflow;

        const imgData = canvas.toDataURL('image/png');

        // A4 page size in pixels (72 DPI) - Portrait orientation
        // const pdfMaxWidth = 595; // A4 width in px (72 DPI)
        // const pdfMaxHeight = 842; // A4 height in px (72 DPI)

        const canvasWidth = canvas.width; // Canvas width in px
        const canvasHeight = canvas.height; // Canvas height in px

        const pxToMm = 0.264583; // 1 pixel = 0.264583 mm
        const pdfWidth = canvasWidth * pxToMm;
        const pdfHeight = canvasHeight * pxToMm;

        // Create a PDF with the same dimensions as the canvas
        const pdf = new jsPDF('p', 'px', [pdfWidth - 1, pdfHeight]);
      
        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        // Calculate aspect ratio
        // const aspectRatio = canvasWidth / canvasHeight;

        // let pdfWidth = pdfMaxWidth;
        // let pdfHeight = pdfMaxHeight;

        // // Scale image based on height if it exceeds max height
        // if (canvasHeight > pdfMaxHeight) {
        //   pdfHeight = pdfMaxHeight;
        //   pdfWidth = pdfHeight * aspectRatio; // Adjust width according to aspect ratio
        // }

        // // If the calculated width exceeds the max width, scale it based on width instead
        // if (pdfWidth > pdfMaxWidth) {
        //   pdfWidth = pdfMaxWidth;
        //   pdfHeight = pdfWidth / aspectRatio; // Adjust height based on width
        // }

        // // Create the PDF with the adjusted page size
        // const pdf = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);

        // Add the image (canvas) to the PDF
        // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        pdf.link(0, 0, pdfWidth, pdfHeight, { url: this.url });

        const fileName = this.name + ' QR Code.pdf';

        if (this.platform.is('hybrid')) {
          try {
            const output = pdf.output('datauristring');
            if (output) {
              const pdfBase64 = output.split(',')[1];
              if (pdfBase64) {
                // Save the file using Capacitor Filesystem
                const result = await Filesystem.writeFile({
                  path: fileName,
                  data: pdfBase64,
                  directory: Directory.Documents, // Use Documents directory for easy access
                  // encoding: Encoding.UTF8, // Base64 string encoding
                });
                if (result?.uri) {
                  await FileOpener.open({ 
                    filePath: result?.uri, 
                    contentType: 'application/pdf'
                  });
                }
              }
            }
          } catch (error) {
            this.errorService.logError(error);
          }
        } else {
          await pdf.save(fileName);
        }
        this.popupService.dismissLoading();
        setTimeout(() => {
          this.dismissModal();
        }, 800);
      });
    }

    // const canvasWidth = canvas.width; // Width in pixels
    // const canvasHeight = canvas.height; // Height in pixels
    // console.log(canvasWidth);
    // console.log(canvasHeight);
    // // Convert canvas dimensions from pixels to millimeters
    // const pxToMm = 0.264583; // 1 pixel = 0.264583 mm
    // const pdfWidth = canvasWidth * pxToMm;
    // const pdfHeight = canvasHeight * pxToMm;
    // console.log(pdfWidth);
    // console.log(pdfHeight);
    // const imgData = canvas.toDataURL('image/png');
    // const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);
    // // const pdfWidth = pdf.internal.pageSize.getWidth();
    // // // const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    // // const pdfHeight = pdf.internal.pageSize.getHeight();
    // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  }
}
