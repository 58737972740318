<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="discard()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="type">
        <ng-container *ngIf="type === 'user' && ownPrivilege else titleBlock">
          {{ 'ACCOUNT_PRIVILEGE.user.own_title' | translate }}
        </ng-container>
        <ng-template #titleBlock>
          {{ 'ACCOUNT_PRIVILEGE.' + type + '.title' | translate }}
        </ng-template>
      </ng-container>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-text-center" *ngIf="type === 'user' && accountUser?.name" color="light">
    <ion-text class="font-14">{{ accountUser.name }}</ion-text>
  </ion-toolbar>
  <ion-toolbar class="role-toolbar ion-no-padding" color="light">
    <ion-list class="ion-padding-top" color="light">
      <ion-item-divider class="ion-item-no-padding" color="light">
        <ion-icon class="title-icon" name="alert-circle-outline"></ion-icon> 
        <ion-text class="font-12" color="dark" *ngIf="type">
          {{ 'ACCOUNT_PRIVILEGE.' + (type === 'user' && !editRole ? 'preview_user' : type) + '.role' | translate }}
        </ion-text>
      </ion-item-divider>
      <ion-item lines="none" [button]="editRole" [detail]="editRole" (click)="presentRoleModal(selectedRole)">
        <ion-label class="font-16 font-med" color="dark">{{ 'LBL.role' | translate }} </ion-label>
        <ion-note class="font-14" color="dark" slot="end" *ngIf="selectedRole?.type">
          <ng-container *ngIf="selectedRole.custom">{{ selectedRole.type }}</ng-container>
          <ng-container *ngIf="!selectedRole.custom">{{ 'LIST.role.' + selectedRole.type | translate }}</ng-container>
        </ion-note>
      </ion-item>
    </ion-list>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding" *ngIf="selectedRole" color="light">
    <ion-list class="ion-padding-top" color="light">
      <ion-item-divider class="ion-item-no-padding" color="light">
        <ion-icon class="title-icon" name="alert-circle-outline"></ion-icon> 
        <ion-text class="font-12" color="dark" *ngIf="type">
          {{ 'ACCOUNT_PRIVILEGE.' + (type === 'user' && !editPrivilege ? 'preview_user' : type) + '.privilege' | translate }}
        </ion-text>
      </ion-item-divider>
      <ion-item color="light" lines="none">
        <div class="font-12" slot="start" (click)="selectAllModule()" *ngIf="editPrivilege">
          <ng-container *ngIf="!selectAll"><ion-icon name="checkmark-circle"></ion-icon> <ion-text> {{ 'BTN.select_all' | translate }}</ion-text></ng-container>
          <ng-container *ngIf="selectAll"><ion-icon name="checkmark-circle"></ion-icon> <ion-text> {{ 'BTN.unselect_all' | translate }}</ion-text></ng-container>
        </div>
        <div class="font-12" slot="end" (click)="expandAllModule()">
          <ng-container *ngIf="!expandAll"><ion-icon name="chevron-down"></ion-icon> <ion-text> {{ 'BTN.expand_all' | translate }}</ion-text></ng-container>
          <ng-container *ngIf="expandAll"><ion-icon name="chevron-up"></ion-icon> <ion-text> {{ 'BTN.unexpand_all' | translate }}</ion-text></ng-container>
        </div>
      </ion-item>
    </ion-list>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal" color="light">
  <div *ngIf="selectedRole && moduleList?.length">
    <ion-card class="ion-padding module-card" [color]="selectedPrivilege && selectedPrivilege[module] && selectedPrivilege[module].length ? 'tertiary' : 'white'" *ngFor="let module of moduleList">
      <ion-card-header class="ion-no-padding">
        <ion-item class="background-transparent" lines="none">
          <ion-text class="font-14 font-bold" (click)="expandModule(module)">
            {{ 'MODULE.list.' + module | translate }}
            <ion-icon name="chevron-down" class="icon-expand" *ngIf="!expandedModule[module]"></ion-icon>
            <ion-icon name="chevron-up" class="icon-expand" *ngIf="expandedModule[module]"></ion-icon>
          </ion-text>
          <ion-note  slot="end">
            <ion-toggle color="success" (ionChange)="selectModule(module)" [(ngModel)]="selectedModule[module]" *ngIf="editPrivilege"></ion-toggle>
          </ion-note>
        </ion-item>
      </ion-card-header>
  
      <ion-card-content class="ion-no-padding" *ngIf="actionList[module] && expandedModule[module]">
        <ion-grid fixed>
          <ion-row>
            <ng-container *ngFor="let action of actionList[module];">
              <ion-col size="auto" *ngIf="action && action !== 'view'">
                <ion-card class="btn-privilege ion-padding center vertical-center ion-no-margin" [color]="selectedPrivilegeList?.[module]?.[action] ? 'success' : 'light'" (click)="selectAction(module, action)" button>
                  <ion-text class="font-12" [color]="selectedPrivilegeList?.[module]?.[action] ? 'white' : 'dark'">
                    {{ 'MODULE.action.' + module + '.' + action | translate }}
                  </ion-text>
                </ion-card>
              </ion-col>
            </ng-container>
            
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
<ion-footer *ngIf="type !== 'preview' && (editPrivilege || editRole)">
  <ion-toolbar class="ion-text-center" color="white">
    <ng-container *ngIf="!accountUser?.owner else ownerBlock">
      <ion-button class="ion-margin" expand="block" (click)="next()" [disabled]="!selectedRole || !selectedRole.type">
        <ng-container *ngIf="type === 'new'">{{ 'BTN.next' | translate }}</ng-container>
        <ng-container *ngIf="type === 'edit' || type === 'user'">{{ 'BTN.save' | translate }}</ng-container>
      </ion-button>
    </ng-container>
    <ng-template #ownerBlock>
      <div class="ion-margin">
        <ion-button expand="block" (click)="next()" [disabled]="!selectedRole || !selectedRole.type">
          {{ 'BTN.save' | translate }}
        </ion-button>
        <ion-text class="font-12">{{ 'ACCOUNT_PRIVILEGE.user.owner' | translate }}</ion-text>
      </div>
    </ng-template>
  </ion-toolbar>
</ion-footer>
