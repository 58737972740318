<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="prompt-card">
      <ion-card-header>
        <div class="ion-padding-vertical ion-text-center">
          <ion-text>
            <ng-container *ngIf="step === 1">
              {{ 'GIFT.btn.receive' | translate }}?
            </ng-container>
            <ng-container *ngIf="step === 2">
              <ng-container *ngIf="giftType === 'other'">
                {{ 'GIFT.msg.present_detail' | translate }}
              </ng-container>
              <ng-container *ngIf="giftType === 'cash'">
                {{ 'GIFT.msg.cash_detail' | translate }}
              </ng-container>
            </ng-container>
          </ion-text>
        </div>
      </ion-card-header>
      <ion-card-content class="ion-text-center">
        <ion-grid fixed *ngIf="step === 1">
          <ion-row>
            <ion-col>
              <ion-card class="ion-padding ion-no-margin" [color]="giftType === 'cash' ? 'tertiary' : 'grey'" (click)="setGiftType('cash')">
                <ion-icon class="icon-large center" src="./assets/wedding/icon/gift/cash.svg"></ion-icon>
                <ion-text class="font-12">{{ 'LIST.gift.cash' | translate }}</ion-text>
              </ion-card>
            </ion-col>
            <ion-col>
              <ion-card class="ion-padding ion-no-margin" [color]="giftType === 'other' ? 'tertiary' : 'grey'" (click)="setGiftType('other')">
                <ion-icon class="icon-large center" src="./assets/wedding/icon/gift/gift.svg"></ion-icon>
                <ion-text class="font-12">{{ 'LIST.gift.other' | translate }}</ion-text>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="ion-margin-top">
            <ion-col>
              <ion-button expand="block" color="grey" (click)="dismissModal()">
                {{ 'BTN.no' | translate }}
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button expand="block" color="primary" (click)="next()" [disabled]="!giftType">
                {{ 'BTN.yes' | translate }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ng-container *ngIf="step === 2">
          <form [formGroup]="form" (ngSubmit)="formSubmit()" *ngIf="giftType === 'cash'">
            <ion-item class="ion-item-no-padding full-width" lines="none">
              <ion-label position="stacked" color="dark">{{ 'LBL.amount' | translate }}</ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding full-width" lines="none">
                <ion-text class="currency-text" (click)="openCurrency()" slot="start">{{ form?.value?.currency }} <ion-icon class="vertical-middle" name="chevron-down"></ion-icon></ion-text>
                <ion-input class="ion-text-right" formControlName="amount" [placeholder]="" type="number" inputmode="decimal" enterkeyhint="done" (ionChange)="checkAmount()" tabindex="1" clearOnEdit></ion-input>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.amount">
                  <div class="error-message" *ngIf="form?.get('amount').hasError(validation.type) && (form?.get('amount').dirty || form?.get('amount').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-label position="stacked" color="dark">{{ 'LBL.description' | translate }}</ion-label>
              <ion-input class="prompt-input" formControlName="name" [placeholder]="" type="text" inputmode="text" autocapitalize="words" enterkeyhint="done" tabindex="2" clearInput></ion-input>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.name">
                  <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
          </form>
          <form [formGroup]="form" (ngSubmit)="formSubmit()" *ngIf="giftType === 'other'">
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-label position="stacked" color="dark">{{ 'GIFT.lbl.name' | translate }}</ion-label>
              <ion-input class="prompt-input" formControlName="name" [placeholder]="" type="text" inputmode="text" autocapitalize="words" enterkeyhint="next" tabindex="3" clearInput></ion-input>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.name">
                  <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-label position="stacked" color="dark">{{ 'LBL.qty' | translate }}</ion-label>
              <ion-item class="ion-item-no-padding full-width" lines="none">
                <ion-input class="prompt-input ion-text-center" formControlName="qty" min="1" type="number" inputmode="numeric" tabindex="4">
                  <ion-button class="qty-btn" slot="start" (click)="minusQty()" fill="clear">
                    <ion-icon name="remove"></ion-icon>
                  </ion-button>
                  <ion-button class="qty-btn" slot="end" (click)="addQty()" fill="clear">
                    <ion-icon name="add"></ion-icon>
                  </ion-button>
                </ion-input>
                
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.qty">
                  <div class="error-message" *ngIf="form?.get('qty').hasError(validation.type) && (form?.get('qty').dirty || form?.get('qty').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
          </form>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-button expand="block" color="grey" (click)="step = 1">
                  {{ 'BTN.cancel' | translate }}
                </ion-button>
              </ion-col>
              <ion-col>
                <ion-button expand="block" color="primary" (click)="formSubmit()">
                  {{ 'BTN.save' | translate }}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-container>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>

