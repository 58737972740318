import { MediaType } from '../interfaces/general';
import { WebsiteMediaList, WebsiteStdContentList, WebsiteThemeBackground, WebsiteThemeColor } from '../interfaces/website';

export const StdWebsiteItineraryList: string[] = [
    'ceremony',
    'cocktail',
    'reception',
    'dinner',
    'lunch',
    'toast_speeches',
    'dancing',
    'bouquet',
    'after_party',
    'mehndi',
    'nikah',
    'tea_ceremony',
    'pithi'
];

export const StdEventWebsiteItineraryList: string[] = [
    'ceremony',
    'cocktail',
    'reception',
    'dinner',
    'lunch',
    'toast_speeches',
    'dancing',
    'bouquet',
    'after_party',
    'mehndi',
    'nikah',
    'tea_ceremony',
    'pithi'
];

export const StdWebsiteContentsList: WebsiteStdContentList = {
    'time': {
        contentId: 'time',
        order: 1,
    },
    'host_msg': {
        contentId: 'host_msg',
        order: 2,
    },
    'save_the_date': {
        contentId: 'save_the_date',
        order: 3,
    },
    'qrcode': {
        contentId: 'qrcode',
        order: 4,
    },
    'profile': {
        contentId: 'profile',
        order: 5,
    },
    'web_checkin': {
        contentId: 'web_checkin',
        order: 6,
    },
    'venue': {
        contentId: 'venue',
        order: 7,
    },
    'dress_code': {
        contentId: 'dress_code',
        order: 8,
    },
    'itinerary': {
        contentId: 'itinerary',
        order: 9,
    },
    'gallery': {
        contentId: 'gallery',
        order: 10,
    },
    'contact': {
        contentId: 'contact',
        order: 11,
    }
};

export const StdWebsiteContactType: string[] = [
    'whatsapp',
    'phone',
    'email',
    'line',
    'messenger',
    'wechat',
    'telegram',
    'viber',
    'kaokao',
    'signal',
    'snapchat',
    'threads',
    'instagram',
    'facebook',
    'skype',
    'discord',
    'slack',
    'website'
];

export const WebsiteGallerySample: WebsiteMediaList = {
    '1': {
        mediaId: '1',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 1,
    },
    '2': {
        mediaId: '2',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/2.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/2.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 2,
    },
    '3': {
        mediaId: '3',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/3.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/3.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 3,
    },
    '4': {
        mediaId: '4',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 4,
    },
    '5': {
        mediaId: '5',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/4.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/4.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 5,
    },
    '6': {
        mediaId: '6',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/5.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/5.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 6,
    },
    '7': {
        mediaId: '7',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/6.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/6.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 7,
    },
    '8': {
        mediaId: '8',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/2.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/2.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 8,
    },
    '9': {
        mediaId: '9',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/7.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/7.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 9,
    },
    '10': {
        mediaId: '10',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/8.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/8.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 10,
    },
    '11': {
        mediaId: '11',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/9.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/9.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 11,
    },
    '12': {
        mediaId: '12',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/3.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/3.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 12,
    },
    '13': {
        mediaId: '13',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/4.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/4.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 13,
    },
    '14': {
        mediaId: '14',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/10.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/10.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 14,
    },
    '15': {
        mediaId: '15',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/11.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/11.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 15,
    },
    '16': {
        mediaId: '16',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 16,
    },
    '17': {
        mediaId: '17',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/13.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/13.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 17,
    },
    '18': {
        mediaId: '18',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/5.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/5.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 18,
    },
    '19': {
        mediaId: '19',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/14.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/14.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 19,
    },
    '20': {
        mediaId: '20',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/15.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/15.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 20,
    },
    '21': {
        mediaId: '21',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/6.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/6.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 21,
    },
    '22': {
        mediaId: '22',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/16.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/16.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 22,
    },
    '23': {
        mediaId: '23',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/7.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/7.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 23,
    },
    '24': {
        mediaId: '24',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/17.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/17.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 24,
    },
    '25': {
        mediaId: '25',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/18.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/18.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 25,
    },
    '26': {
        mediaId: '26',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/8.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/8.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 26,
    },
    '27': {
        mediaId: '27',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/9.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/9.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 27,
    },
    '28': {
        mediaId: '28',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/10.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/10.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 28,
    },
    '29': {
        mediaId: '29',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/19.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/19.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 29,
    },
    '30': {
        mediaId: '30',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/20.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/20.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 30,
    },
    '31': {
        mediaId: '31',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/21.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/21.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 31,
    },
    '32': {
        mediaId: '32',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/11.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/11.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 32,
    },
    '33': {
        mediaId: '33',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 33,
    },
    '34': {
        mediaId: '34',
        mediaUrl: {
            url: './assets/wedding/img/website/cover/21.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/thumbnail/21.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 34,
    },
    '35': {
        mediaId: '35',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/13.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/13.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 35,
    },
    '36': {
        mediaId: '36',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/14.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/thumbnail/14.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        sample: true,
        order: 36,
    }
}

export const WebsiteCoverSample: WebsiteMediaList = {
    1: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 1,
    },
    2: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/2.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/2.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 2,
    },
    3: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/3.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/3.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 3,
    },
    4: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/4.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/4.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 4,
    },
    5: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/5.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/5.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 5,
    },
    6: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/6.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/6.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 6,
    },
    7: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/7.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/7.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 7,
    },
    8: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/8.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/8.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 8,
    },
    9: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/9.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/9.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 9,
    },
    10: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/10.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/10.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 10,
    },
    11: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/11.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/11.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 11,
    },
    12: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 12,
    },
    13: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/13.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/13.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 13,
    },
    14: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/14.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/14.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 14,
    },
    15: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/15.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/15.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 15,
    },
    16: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/16.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/16.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 16,
    },
    17: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/17.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/17.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 17,
    },
    18: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/18.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/18.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 18,
    },
    19: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/19.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/19.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 19,
    },
    20: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/20.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/20.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 20,
    },
    21: {
        mediaUrl: {
            url: './assets/wedding/img/website/cover/21.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/cover/preview/21.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 21,
    },
    22: {
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/preview/1.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 22,
    },
    23: {
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        thumbnailUrl: {
            url: './assets/wedding/img/website/gallery/preview/12.webp',
            type: 'local',
            mimeType: 'image/webp'
        },
        order: 23,
    }
};
