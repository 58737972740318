import { Injectable } from '@angular/core';
import { VisitorService } from './visitor.service';
import { WebsiteDetailService } from '../website/website-detail.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorModeService {

  constructor(
    private visitorService :VisitorService,
    private websiteDetailService: WebsiteDetailService,
  ) { }


  checkMode(): boolean {
    if (this.visitorService.pageData?.info) {
      return true;
    }
    return false;
  }

  checkNewWebsite(): boolean {
    if (this.visitorService?.pageData?.setting?.newWebsiteMode || (this.websiteDetailService?.websiteDetail?.info && !this.visitorService.pageData?.info)) {
      return true;
    }
    return false;
  }

}
