<ion-item class="ion-item-no-padding background-transparent" lines="none"> 
  <ng-container *ngIf="editMode && total else elseBlock">
    <span (click)="toggleSelectAll()" *ngIf="total && total > 0">
      <ion-text class="font-10" color="dark" *ngIf="!selectAll"><ion-icon class="icon-small" name="checkmark-circle" color="medium"></ion-icon>&nbsp;{{ 'BTN.select_all' | translate }}</ion-text>
      <ion-text class="font-10" color="dark" *ngIf="selectAll"><ion-icon class="icon-small" name="checkmark-circle" color="success"></ion-icon>&nbsp;{{ 'BTN.unselect_all' | translate }}</ion-text>
    </span>
    <ion-text class="font-10 vertical-middle ion-text-right ion-no-margin" slot="end">
      {{ 'LBL.selected' | translate }}: {{ selected }} / {{ total ? total : 0 }}
    </ion-text>
  </ng-container>
  <ng-template #elseBlock>
    <ng-container *ngIf="expandMode">
      <span (click)="toggleExpandAll()" *ngIf="total && total > 0">
        <ion-text class="font-10" color="dark" *ngIf="!expandAll"><ion-icon class="icon-small" name="checkmark-circle" color="medium"></ion-icon>&nbsp;{{ 'BTN.expand_all' | translate }}</ion-text>
        <ion-text class="font-10" color="dark" *ngIf="expandAll"><ion-icon class="icon-small" name="checkmark-circle" color="success"></ion-icon>&nbsp;{{ 'BTN.unexpand_all' | translate }}</ion-text>
      </span>
      <ion-text class="font-10 vertical-middle ion-text-right ion-no-margin" *ngIf="!hideTotal" slot="end">
        {{ 'LBL.total' | translate }}: {{ total ? total : 0 }}
      </ion-text>
    </ng-container>
    <ng-container *ngIf="!expandMode">
      <ion-text class="font-10 vertical-middle ion-text-right ion-no-margin" *ngIf="!hideTotal" slot="end">
        {{ 'LBL.total' | translate }}: {{ total ? total : 0 }}
      </ion-text>
    </ng-container>
  </ng-template>
</ion-item>