import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingField } from 'src/app/interfaces/database';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteDetail, WebsiteProfile, WebsiteThemeColor } from 'src/app/interfaces/website';
import { FunctionService } from 'src/app/services/general/function.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { WebsiteGuestProfileService } from 'src/app/services/website/website-guest-profile.service';
import { SettingFieldType } from 'src/app/types/general';

@Component({
  selector: 'app-website-guest-profile',
  templateUrl: './website-guest-profile.component.html',
  styleUrls: ['./website-guest-profile.component.scss'],
})
export class WebsiteGuestProfileComponent  implements OnInit {

  @Input() set setWebsiteDetail(websiteDetail : WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    if (!this.websiteDetail?.profile) {
      this.websiteProfile = this.websiteProfileService.getWebsiteProfile();
    } else {
      this.websiteProfile = this.websiteDetail.profile;
    }

    if (!this.functionService.sanitizeHtml(this.websiteProfile?.msg)) {
      if (!this.websiteProfile) {
        this.websiteProfile = this.websiteProfileService.getWebsiteProfile();
      }
      this.websiteProfile.msg = '';
    }
  }

  @Input() set setGuest(guest: Guest) {
    this.guest = guest;
    this.setup();
  }
  @Input() set setGroup(group: Group) {
    this.group = group;
    this.setup();
  }
  @Input() set setMemberList(memberList: Guest[]) {
    this.memberList = memberList;
    this.setup();
  }

  @Input() set setType(type: string) {
    this.type = type;
  }

  @Input() set setCheckinStatus(checkinStatus: boolean) {
    this.checkinStatus = checkinStatus;
    if (this.checkinStatus) {
      this.type = 'status';
    } else {
      if (this.type === 'status') {
        this.type = 'profile';
      }
    }
  }

  @Input() websiteThemeColor: WebsiteThemeColor;
  
  websiteDetail : WebsiteDetail
  websiteProfile: WebsiteProfile;

  checkinStatus: boolean;

  type: string;

  guest: Guest;
  group: Group;
  memberList: Guest[];

  name: string;
  seating: string;
  dietaryReq: string;
  specialReq: string;
  mobile: string;
  email: string;

  status: string;

  constructor(
    private translate: TranslateService,
    private websiteProfileService: WebsiteGuestProfileService,
    private settingFieldService: SettingFieldService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  setup() {
    if (this.guest?.guestId) {
      this.name = this.guest.name;
      if (!this.checkinStatus) {
        this.checkinStatus = this.guest?.status?.checkin ? true : false;
      }
      
      this.seating = this.guest?.seating;
      this.mobile = this.guest?.mobile?.no;
      this.email = this.guest?.email;
      this.dietaryReq = this.settingFieldService.getSettingField('dietary_req', this.guest?.dietaryReq?.[0]);
      this.specialReq = this.settingFieldService.getSettingField('special_req', this.guest?.specialReq?.[0]);

      this.status = '';
      if (this.guest?.status?.attending === 'attending') {
        this.status = this.websiteDetail?.form?.fields?.attending ? this.websiteDetail.form.fields.attending : this.translate.instant('LIST.attending_status.attending');
      } else if (this.guest?.status?.attending === 'not_attending') {
        this.status = this.websiteDetail?.form?.fields?.not_attending ? this.websiteDetail.form.fields.not_attending : this.translate.instant('LIST.attending_status.not_attending');
      }

    } else if (this.group?.groupId) {
      this.name = this.group.groupName;
      if (!this.checkinStatus) {
        this.checkinStatus = this.group?.status?.checkin ? true : false;
      }
      
      this.seating = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => x?.seating)
            ?.map((x: Guest) => x?.seating)
        )
      ).join(', ');

      this.mobile = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => x?.mobile?.no)
            ?.map((x: Guest) => x?.mobile?.no)
        )
      ).join(', ');

      this.email = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => x?.email)
            ?.map((x: Guest) => x?.email)
        )
      ).join(', ');

      this.dietaryReq = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => 
            x?.dietaryReq?.[0]
          )?.map((x: Guest) => 
            this.settingFieldService.getSettingField('dietary_req', x?.dietaryReq?.[0])
          )
        )
      ).join(', ');

      this.specialReq = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => 
            x?.specialReq?.[0]
          )?.map((x: Guest) => 
            this.settingFieldService.getSettingField('special_req', x?.specialReq?.[0])
          )
        )
      ).join(', ');

      this.status = '';
      this.status = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => 
            x?.status?.attending
          )?.map((x: Guest) => {
            if (x?.status?.attending === 'attending') {
              return this.websiteDetail?.form?.fields?.attending ? this.websiteDetail.form.fields.attending : this.translate.instant('LIST.attending_status.attending');
            } else if (x?.status?.attending === 'not_attending') {
              return this.websiteDetail?.form?.fields?.not_attending ? this.websiteDetail.form.fields.not_attending : this.translate.instant('LIST.attending_status.not_attending');
            }
          })
        )
      ).join(', ');
    }
    
  }

  getSettingField(settingFieldType: SettingFieldType, settingField: SettingField) {
    return this.settingFieldService.getSettingField(settingFieldType, settingField);
  }



}
