<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="discard()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="title">
        <ng-container *ngIf="!settingMode">{{ title }}</ng-container>
        <ng-container *ngIf="settingMode">{{ 'SETTING.lbl.field' | translate: { field: title | translate } }}</ng-container>
      </ng-container>
    </ion-title>
    <ion-buttons slot="end" *ngIf="!reorderMode && currentPrivilege?.['guest']?.['edit']">
      <ion-button class="ion-margin" expand="block" (click)="presentSettingFieldNewModal()">
        <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding" color="light" *ngIf="!reorderMode">
    <div class="ion-padding-bottom">
      <app-search [type]="'settingField'" [disableFilter]="true" (action)="search($event)"></app-search>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <div>
    <ion-list class="full-width-height" *ngIf="list?.length">
      <ion-reorder-group [disabled]="!settingMode || !reorderMode" (ionItemReorder)="doReorder($any($event))">
        <div *ngFor="let settingField of list | slice : 0 : limit;">
          <ion-item-sliding [disabled]="reorderMode" #slidingItem>
            <ion-item class="item" [class]="settingMode && reorderMode ? 'ion-item-no-padding' : ''" [color]="checkSelected(settingField) ? 'tertiary' : 'white'" lines="none" (click)="select(settingField)">
              <ion-reorder slot="start" class="reorder-button">
                <ion-icon name="reorder-two-outline" color="medium"></ion-icon>
              </ion-reorder>
              <ion-label class="font-14 ion-text-wrap">
                {{ getValue(settingField) }}
              </ion-label>
              <ion-text slot="end" *ngIf="!reorderMode && checkSelected(settingField)">
                <ion-icon name="checkmark-outline"></ion-icon>
              </ion-text>
            </ion-item>
            <ion-item-options side="end" (ionSwipe)="checkSwipe(settingField)">
              <ion-item-option color="success" (click)="presentInput(settingField, slidingItem)" *ngIf="settingField?.custom && settingField.value !== getReservedField() && currentPrivilege?.['guest']?.['edit']"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
              <ion-item-option color="medium" (click)="deletePrompt(settingField, slidingItem)" *ngIf="settingField.value !== getReservedField() && currentPrivilege?.['guest']?.['delete']"><ion-icon name="trash-outline"></ion-icon></ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </ion-reorder-group>
      <ion-infinite-scroll threshold="15%" (ionInfinite)="loadLimit($event)" *ngIf="list?.length > limit">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          [loadingText]="'LOADING.loading' | translate">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-list>
  </div>
  <div class="full-width-height vertical-center center ion-margin" *ngIf="!list?.length && !reorderMode">
    <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
      <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
      <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
    </ion-label>
  </div>
</ion-content>
<!-- <ion-content color="light" [scrollY]="false">
  <div class="viewport-div ion-content-scroll-host" *ngIf="list?.length">
    <ion-list class="full-width-height">
      <cdk-virtual-scroll-viewport [itemSize]="48">
        <ion-reorder-group [disabled]="!settingMode || !reorderMode" (ionItemReorder)="doReorder($any($event))">
          <ion-item-sliding [disabled]="reorderMode" *cdkVirtualFor="let settingField of list; trackBy: trackByFn; let i = index;" #slidingItem>
            <ion-item class="item" [class]="settingMode && reorderMode ? 'ion-item-no-padding' : ''" [color]="checkSelected(settingField) ? 'tertiary' : 'white'" lines="none" (click)="select(settingField)">
              <ion-reorder slot="start" class="reorder-button">
                <ion-icon name="reorder-two-outline" color="medium"></ion-icon>
              </ion-reorder>
              <ion-label class="font-14 ion-text-wrap">
                {{ getValue(settingField) }}
              </ion-label>
              <ion-text slot="end" *ngIf="!reorderMode && checkSelected(settingField)">
                <ion-icon name="checkmark-outline"></ion-icon>
              </ion-text>
            </ion-item>
            <ion-item-options side="end" (ionSwipe)="checkSwipe(settingField)">
              <ion-item-option color="success" (click)="presentInput(settingField, slidingItem)" *ngIf="settingField?.custom && settingField.value !== getReservedField() && currentPrivilege?.['guest']?.['edit')"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
              <ion-item-option color="medium" (click)="deletePrompt(settingField, slidingItem)" *ngIf="settingField.value !== getReservedField() && currentPrivilege?.['guest']?.['delete')"><ion-icon name="trash-outline"></ion-icon></ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-reorder-group>
      </cdk-virtual-scroll-viewport>
    </ion-list>
  </div>
  <div class="full-width-height vertical-center center ion-margin" *ngIf="!list?.length && !reorderMode">
    <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
      <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
      <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
    </ion-label>
  </div>
</ion-content> -->

<ion-footer *ngIf="settingMode">
  <ion-toolbar class="ion-no-padding">
    <div class="ion-text-center" *ngIf="settingFieldType === 'session'">
      <ion-text class="font-12 font-underline" (click)="presentVisitorSetting('setting')">
        {{ 'VISITOR.setting.session.on' | translate }}
      </ion-text>
    </div>
    <ion-grid class="ion-no-padding" fixed>
      <ion-row *ngIf="!reorderMode">
        <ion-col>
          <ion-button class="ion-margin" expand="block" color="tertiary" fill="solid" type="button" (click)="toggleReorder(true)">
            {{ 'BTN.reorder' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="reorderMode">
        <ion-col>
          <ion-button class="ion-margin" expand="block" color="grey" fill="solid" type="button" (click)="toggleReorder(false)">
            {{ 'BTN.cancel' | translate }}
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="save(list)">
            {{ 'BTN.save' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
