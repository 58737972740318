<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'USER.lbl.avatar_gallery' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="ion-margin-horizontal ion-margin-top ion-padding-horizontal">
    <ion-text color="dark">{{ 'USER.msg.choose_avatar' | translate }}</ion-text>
  </div>
  <ion-grid>
    <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center">
      <ion-col class="ion-align-self-center ion-text-center" size-xs="4" size-sm="3" *ngFor="let avatar of avatarList">
        <ion-avatar class="image-container" *ngIf="avatar" (click)="dismissModal(avatar)">
          <ng-container  *ngIf="avatar?.indexOf('./assets/wedding/img/avatar/') === 0 else cachedImg">
            <ion-img [src]="avatar" *ngIf="avatar?.indexOf('/blank.svg') === -1"></ion-img>
            <ion-icon color="medium" *ngIf="avatar?.indexOf('/blank.svg') !== -1" [src]="avatar"></ion-icon>
          </ng-container>
          <ng-template #cachedImg>
            <app-cached-image [src]="avatar" [spinner]="true"></app-cached-image>
          </ng-template>
        </ion-avatar>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>