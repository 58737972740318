<ion-item class="background-transparent" lines="none">
  <ion-label class="ion-no-margin">
    <form (ngSubmit)="search()">
      <ion-searchbar name="search" class="searchbar" [ngClass]="disabled ? 'disabled-searchbar' : ''" color="white" [(ngModel)]="keyword" (ionInput)="search()" (keypress)="closeKeyboard($event)" [placeholder]="placeholder ? placeholder : 'BTN.search' | translate" [showClearButton]="disabled ? 'never' : 'always'" [disabled]="disabled" debounce="800" enterkeyhint="search" inputmode="search" type="text" autocomplete="on" searchIcon="search-outline"></ion-searchbar>
    </form> 
  </ion-label>
  
  <ion-button class="vertical-center filter-button" fill="clear" slot="end" (click)="filterAction()" *ngIf="!disableFilter">
    <ion-icon color="dark" name="refresh" *ngIf="filter?.enable || sorting || desc else filterBtnBlock"></ion-icon>
    <ng-template #filterBtnBlock>
      <ion-icon color="dark" src="./assets/wedding/icon/general/filter.svg"></ion-icon>
    </ng-template>
  </ion-button>
</ion-item>
<ion-item class="background-transparent ion-item-no-padding" *ngIf="(!disableFilter && filter?.enable) || (sorting || desc)" lines="none">
  <app-filter-value class="full-width" [type]="type" [filter]="filter" [sorting]="sorting" [desc]="desc" (editFilterModal)="presentFilterModal(true)" (checkHeight)="checkHeight()"></app-filter-value>
</ion-item>
