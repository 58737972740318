import { AccountPromptService } from './services/account/account-prompt.service';
import { AccountTaskService } from 'src/app/services/account/account-task.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Platform, MenuController, ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TextZoom } from '@capacitor/text-zoom';
import { Subscription } from 'rxjs';

import { AccountService } from 'src/app/services/account/account.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { MenuService } from 'src/app/services/general/menu.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { LoginService } from 'src/app/services/general/login.service';
import { LinkService } from 'src/app/services/general/link.service';
import { AppService } from 'src/app/services/general/app.service';
import { UrlService } from 'src/app/services/general/url.service';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';

import { User } from 'src/app/interfaces/user';
import { Menu } from 'src/app/interfaces/menu';
import { AccountInfo, AccountUser } from 'src/app/interfaces/account';

import { StripeExchangeRateService } from 'src/app/services/payment/stripe/stripe-exchange-rate.service';
import { DeviceDownloadService } from './services/device/device-download.service';
import { KeyboardService } from './services/general/keyboard.service';
import { BlastRedeemService } from './services/blast/blast-redeem.service';
import { DeviceSafeAreaService } from './services/device/device-safe-area.service';
import { InboxCountService } from './services/inbox/inbox-count.service';
import { InboxSetupService } from './services/inbox/inbox-setup.service';
import { SupportSetupService } from './services/support/support-setup.service';
import { VisibilityService } from './services/general/visibility.service';

import firebase from "firebase/compat/app";
import { DebugModeService } from './services/general/debug-mode.service';
import { AccountsAddComponent } from './components/accounts/accounts-add/accounts-add.component';
import { SupportAdminService } from './services/support/support-admin.service';
import { CheckI18nService } from './services/general/check-i18n.service';
import { AccountEventModeService } from './services/account/account-event-mode.service';

// import "firebase/compat/analytics";

/**
 * App initial code
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * App version
   */
  appVersion: string;
  /**
   * Menu List
   */
  menuList: Menu[];
  /**
   * Current user
   */
  user: User;
  /**
   * User online status
   */
  online: boolean;
  /**
   * Current URL
   */
  selectedPath: string;

  /**
   * Login ready
   */
  loginReady: boolean;
  /**
   * Account title
   */
  accountTitle: string;
  /**
   * Account info
   */
  accountInfo: AccountInfo;
  /**
   * Account user
   */
  currentUser: AccountUser;

  showLaunchApp: boolean;

  pendingTask: number;

  activeItems: boolean[] = [];

  disableSplitPane: boolean;

  debugMode: boolean;

  /**
   * Login ready subscription
   */
  private loginReadySubscription: Subscription;
  private urlSubscription: Subscription;
  private userSubscription: Subscription;
  private menuSubscription: Subscription;

  private onlineSubscription: Subscription;
  private accountInfoSubscription: Subscription;
  private accountUserSubscription: Subscription;

  private accountTaskSubscription: Subscription;

  /**
   * Constructor
   * @param platform Platform
   * @param menuCtrl Menu controller
   * @param popoverCtrl Popover controller
   * @param modalController Modal controller
   * @param router Router
   * @param translate Translate Service
   * @param onlineService Online Service
   * @param accountService Account Service
   * @param languageService Language Service
   * @param deviceService Device Service
   * @param userService User Service
   * @param authService Authentication Service
   * @param menuService Menu Service
   * @param popupService Popup Service
   */
  constructor(
    private platform: Platform,
    private menuCtrl: MenuController,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private router: Router,
    private translate: TranslateService,
    private onlineService: OnlineService,
    private urlService: UrlService,
    private userService: UserService,
    private authService: AuthService,
    private appService: AppService,
    private accountEventModeService: AccountEventModeService,
    private deviceDownloadService: DeviceDownloadService,
    private deviceSafeAreaService: DeviceSafeAreaService,
    private accountService: AccountService,
    private debugModeService: DebugModeService,
    private accountPromptService: AccountPromptService,
    private accountInfoService: AccountInfoService,
    private accountUserService: AccountUserService,
    private accountTaskService: AccountTaskService,
    private blastRedeemService: BlastRedeemService,
    private stripeExchangeRateService: StripeExchangeRateService,
    private inboxCountService: InboxCountService,
    private menuService: MenuService,
    private linkService: LinkService,
    private inboxSetupService: InboxSetupService,
    private supportSetupService: SupportSetupService,
    private loginService: LoginService,
    private keyboardService: KeyboardService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private visibilityService: VisibilityService,
    // private checkI18nService: CheckI18nService,
  ) {
  }

  ngOnInit(): void {
    this.initializeApp();
    this.disableAndroidBackBtn();
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Initialize App
   * Watch necessary info
   * Disable android hardware back btn
   * Hide splash screen
   */
  async initializeApp() {
    await this.platform.ready();
    this.watch();
    this.checkShowLaunchApp();
    this.setupStatusBar();
    this.disableAndroidBackBtn();
    this.appVersion = await this.appService.getAppVersion();
    this.setupTextzoom();
    this.visibilityService.onvisibilitychange();

    if (this.debugModeService.debugMode) {
      firebase.firestore.setLogLevel('error');
    } else {
      firebase.firestore.setLogLevel('silent');
    }
    // this.checkI18nService.compareJsonFiles('zh-TW.json', 'zh.json').subscribe(missingKeys => {
    //   console.log('Missing keys:', missingKeys);
    // });
  }

  async checkShowLaunchApp() {
    if (!this.platform.is('hybrid') && this.platform.is('desktop')) {
      this.showLaunchApp = true;
    }
  }

  /**
   * Disable Android hardware back button
   */
  disableAndroidBackBtn() {
    if (this.platform.is('hybrid') && this.platform.is('android')) {
      this.platform.backButton.subscribeWithPriority(0, () => {
      });
    }
  }

  setupActiveItems() {
    this.activeItems = this.menuList?.map((menu: Menu) => this.checkSelectedMenu(menu));
  }

  /**
   * Setup status bar
   */
  async setupStatusBar() {
    if (this.platform.is('hybrid')) {
      if (this.platform.is('ios')) {
        StatusBar.setStyle({ style: Style.Light });
      }
    }
  }

  async setupTextzoom() {
    if (this.platform.is('hybrid')) {
      TextZoom.set({ value: 1 });
    }
  }

  /**
   * Watch login ready status
   * Watch online status
   */
  watch() {
    this.watchUrl();
    this.watchUser();
    this.watchOnline();
    this.watchAccountInfo();
    this.watchAccountUser();
    this.watchLoginReady();
    this.watchAccountTask();
  }

  unwatch() {
    this.unwatchUrl();
    this.unwatchUser();
    this.unwatchOnline();
    this.unwatchLoginReady();
    this.unwatchMenu();
    this.unwatchAccounInfo();
    this.unwatchAccountUser();
    this.unwatchAccountTask();
  }

  /**
   * Watch account info
   */
  async watchAccountInfo() {
    if (!this.accountInfoSubscription) {
      this.accountInfoSubscription = this.accountInfoService.observableAccountInfo.subscribe((accountInfo: AccountInfo) => {
        this.accountTitle = this.accountInfoService.accountTitle;
        this.accountInfo = accountInfo;
        this.accountPromptService.setupAccountInfo(accountInfo);
      });
    }
    
  }

  async unwatchAccounInfo() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
      this.accountInfoSubscription = null;
    }
  }

  /**
   * Watch Account User
   */
  async watchAccountUser() {
    if (!this.accountUserSubscription) {
      this.accountUserSubscription = this.accountUserService.observableAccountUser.subscribe(() => {
        this.currentUser = this.accountUserService.getCurrenctUser();
        this.accountPromptService.setupCurrentUser(this.currentUser);
      });
    }
    
  }

  async unwatchAccountUser() {
    if (this.accountUserSubscription) {
      this.accountUserSubscription.unsubscribe();
      this.accountUserSubscription = null;
    }
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe((url: string) => {
        this.selectedPath = url;
        this.setupActiveItems();
        this.checkDisableSplitPane(url);
      });
    }
    
  }

  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }

  /**
   * Watch login ready
   */
  async watchLoginReady() {
    if (!this.loginReadySubscription) {
      this.loginReadySubscription = this.authService.observableLoginReady.subscribe(async (loginReady: boolean) => {
        this.loginReady = loginReady;
        this.unwatchLoginReady();
      });
    }
    
  }

  /**
   * Unwatch login ready
   */
  async unwatchLoginReady() {
    if (this.loginReadySubscription) {
      if (this.online && this.loginReady) {
        this.loginService.initialize();
        this.loginReadySubscription.unsubscribe();
        this.loginReadySubscription = null;
        SplashScreen.hide();
      }
    }
  }

  /**
   * Watch logged user info to display on side menu
   * Watch menu if user logged
   */
  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        this.user = user;
        if (this.user?.uid) {
          this.accountService.readAccountId();
          this.checkDebugMode();
        }
        this.watchMenu();
      });
    }
    
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  /**
   * Watch menu based on user privilege
   */
  async watchMenu() {
    if (!this.menuSubscription) {
      this.menuSubscription = this.menuService.observableMenu.subscribe((menuList: Menu[]) => {
        this.menuList = menuList;
        this.setupActiveItems();
      });
    }
    
  }

  async unwatchMenu() {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
      this.menuSubscription = null;
    }
  }

  /**
   * Watch device online status
   */
  async watchOnline() {
    if (!this.onlineSubscription) {
      this.onlineSubscription = this.onlineService.observableOnline.subscribe((online: boolean) => {
        this.online = online;
        this.unwatchLoginReady();
      });
    }
    
  }

  async unwatchOnline() {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
      this.onlineSubscription = null;
    }
  }

  async watchAccountTask() {
    if (!this.accountTaskSubscription) {
      this.accountTaskSubscription = this.accountTaskService.observablePending.subscribe((pendingTask: number) => {
        this.pendingTask = pendingTask;
      });
    }
  }

  async unwatchAccountTask() {
    if (this.accountTaskSubscription) {
      this.accountTaskSubscription.unsubscribe();
      this.accountTaskSubscription = null;
    }
  }

  checkDebugMode() {
    this.debugMode = this.debugModeService.debugMode || this.checkAdmin() ? true : false;
  }

  checkAdmin() {
    if (this.user.uid === '3SAfCuWtlWXAvSnk4at4zezSct72' || this.user.uid === 'bhHqzdpkZIS4boEtyY1lfoUUr532' || this.user.uid === '4wM05ZZyNmdebOI18F5jzYDdGXo2') {
      return true;
    }
    return false;
  }

  checkDisableSplitPane(url: string) {
    if (url?.indexOf('/website/') !== -1 || url?.indexOf('/website-setting/') !== -1) {
      this.disableSplitPane = true;
    } else {
      this.disableSplitPane = false;
    }
  }

  checkSelectedMenu(menu: Menu) {
    if ((menu?.url && this.selectedPath?.indexOf(menu.url) !== -1)) {
      return true;
    } else if (menu?.path && this.selectedPath?.indexOf(menu.path) !== -1) {
      if (menu.path === '/about/' && this.selectedPath?.indexOf('/about/app-rating') !== -1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  /**
   * Get count down to current date
   * @param time Time
   * @returns count down number
   */
  getCountDown(time: any): any {
    const coundown: number = this.functionService.getCountDown(time);
    return coundown === 0 ? coundown.toString() : coundown;
  }

  // getPendingChecklistCount(): number {
  //   return this.accountTaskService.getPendingChecklistCount();
  // }

  // getUnreadInbox(): number {
  //   return this.inboxCountService.getUnreadCount();
  // }

  /**
   * Go to user profile page
   */
  goProfile() {
    if (this.accountService?.accountId) {
      this.goPage('/user/profile');
    } else {
      this.goPage('/tabs-accounts/profile');
    }
  }

  /**
   * Go to page from side menu
   * @param url Menu URL
   * Notes: Confirm prompt for user-logout / account-logout
   */
  goPage(url: string) {
    if (url) {
      this.menuCtrl.toggle();
      if (url === 'help_center') {
        this.linkService.goHelpCenter();
      } else if (url === '/user/logout') {
        this.userLogout();
      } else if (url === '/accounts/logout') {
        this.accountsLogout();
      } else if (url === '/about/app-rating') {
        this.goAppRatingPage();
      } else if (url === '/accounts/add') {
        this.presentAccountsAddModal();
      } else {
        this.router.navigate([url]);
      }
    }
  }

  goStore() {
    this.appService.goStore();
  }

  goAppDownloadPage() {
    this.router.navigate(['/about/app-download']);
  }

  goAppRatingPage() {
    const navigationExtras: NavigationExtras = {
      state: { backHelpPage: true, showPage: true }
    };
    this.router.navigate(['/about/app-rating/true'], navigationExtras);
  }

  /**
   * User logout confirmation
   */
  async userLogout() {
    if (this.onlineService.isOnline()) {
      if (this.userService?.user?.isAnonymous) {
        const modal = await this.popupService.presentConfirm(
          this.translate.instant('LOGIN.logout.anonymous_msg'), 'logout'
        );
        modal.onWillDismiss().then((result: any) => {
          if (result?.data?.confirm) {
            this.router.navigate(['/user/logout']);
          }
        });
      } else {
        const modal = await this.popupService.presentConfirm(
          this.translate.instant('LOGIN.logout.confirm_msg'), 'logout'
        );
        modal.onWillDismiss().then((result: any) => {
          if (result?.data?.confirm) {
            this.router.navigate(['/user/logout']);
          }
        });
      }
    }
  }

  async presentAccountsAddModal() {
    const modal = await this.modalController.create({
      component: AccountsAddComponent,
      cssClass: 'modal-transparent modal-login',
      initialBreakpoint: 0.5,
      breakpoints: [0.5, 0.9],
      componentProps: {

      }
    });
    modal.present();
    modal.onDidDismiss().then(() => {

    });
  }

  /**
   * Present invite action sheet
   * @param coupleId Couple ID
   */
  async presentAboutActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('MENU.about'),
      buttons: [{
        text: this.translate.instant('APP_RATING.title'),
        handler: () => {
          this.goPage('/about/app-rating/true');
        }
      }, {
        text: this.translate.instant('COMPANY.website'),
        handler: () => {
          this.menuCtrl.toggle();
          this.linkService.goWebsite();
        }
      }, {
        text: this.translate.instant('LBL.platforms'),
        handler: () => {
          this.menuCtrl.toggle();
          this.linkService.goAppDownloadPage(true);
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Account logout confirmation
   */
  async accountsLogout() {
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('ACCOUNT.msg.logout')
    );
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        this.router.navigate(['/accounts/logout']);
      }
    });
  }

  trackByFn(index: number, item: Menu) {
    if (item?.url) {
      return item?.url;
    }
    return index;
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }
}
