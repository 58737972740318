<ion-header class="ion-no-border header-light" #header>
  <ion-toolbar class="gradient-primary-right">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BLAST.calculator.title' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-segment class="full-width" scrollable [(ngModel)]="blastType">
      <ion-segment-button [value]="'sms'">
        <ion-text class="line-100">
          <div>{{ 'BLAST.lbl.sms' | translate }}</div>
          <div>
            <ion-text class="font-10 font-reg" color="medium">
              ({{ 'BLAST.package.name_short' | translate: { count: smsCount } }})
            </ion-text>
          </div>
        </ion-text>
      </ion-segment-button>
      <ion-segment-button [value]="'whatsapp'">
        <ion-text class="line-100">
          <div>{{ 'BLAST.lbl.whatsapp' | translate }}</div>
          <div class="seg-credit-div">
            <ion-text class="font-10 font-reg" color="medium">
              ({{ 'BLAST.package.name_short' | translate: { count:  whatsAppCount } }})
            </ion-text>
          </div>
        </ion-text>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <ion-list>
    <div class="ion-text-center">
      <ion-text class="font-12">{{ 'BLAST.calculator.intro' | translate }}</ion-text>
    </div>
    <ng-container *ngIf="getBlastUsageDetailList() as blastUsageDetailList">
      <ion-card class="ion-padding-horizontal" *ngFor="let blastUsageDetail of blastUsageDetailList; let i = index;">
        <ion-button class="remove-btn" size="small" fill="clear" (click)="removeItem(i)">
          <ion-icon class="font-18" color="medium" slot="icon-only" name="close"></ion-icon>
        </ion-button>
        <div class="item-div">
          <ion-label color="dark" position="stacked">{{ 'LOCATION.lbl.country' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none" button detail (click)="openCountry(blastUsageDetail)">
            <ion-text class="font-14 font-med" color="medium" *ngIf="blastUsageDetail?.country">
              <ng-container *ngIf="blastUsageDetail?.country else selectCountryBlock">
                {{ countryList?.[blastUsageDetail.country] }}
              </ng-container>
              <ng-template #selectCountryBlock>
                {{ 'VALIDATION.select_field' | translate: { field: 'LOCATION.lbl.country' | translate } }}
              </ng-template>
            </ion-text>
          </ion-item>
          <ion-item class="note-item ion-item-no-padding" lines="none" *ngIf="blastUsageDetail?.country">
            <ion-text class="font-10" color="medium" [innerHTML]="'BLAST.credit.credit_no_per_msg' | translate: { no: blastUsageDetail.blastCredit }" *ngIf="blastUsageDetail?.blastCredit > 0">
            </ion-text>
            <ion-text class="font-10" color="danger" [innerHTML]="'BLAST.msg.sms_not_support' | translate" *ngIf="blastType === 'sms' && (!blastUsageDetail?.blastCredit || blastUsageDetail?.blastCredit <= 0)">
            </ion-text>
          </ion-item>
        </div>

        <div class="item-div" *ngIf="blastUsageDetail?.country && blastUsageDetail?.blastCredit && blastUsageDetail.blastCredit > 0">
          <ion-label color="dark" position="stacked">{{ 'BLAST.calculator.msg_num' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-button class="qty-btn" slot="start" (click)="minusQty(blastUsageDetail)" color="light">
              <ion-icon slot="icon-only" name="remove"></ion-icon>
            </ion-button>
            <ion-input class="full-width ion-text-center ion-no-padding" [min]="1" type="number" inputmode="numeric" (keyup)="setupTotalBlastCredit()" [(ngModel)]="blastUsageDetail.qty">
            </ion-input>
            <ion-button class="qty-btn" slot="end" (click)="addQty(blastUsageDetail)" color="light">
              <ion-icon slot="icon-only" name="add"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item class="note-item ion-item-no-padding" lines="none" *ngIf="blastUsageDetail?.qty">
            <ion-text class="font-10" color="medium" [innerHTML]="'BLAST.credit.total_credit_count' | translate: { count: blastUsageDetail.qty * blastUsageDetail.blastCredit }">
            </ion-text>
          </ion-item>
          <div class="error-div">
            <div class="error-message" *ngIf="!blastUsageDetail?.qty">
              <ion-icon name="alert-circle-outline"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'BLAST.calculator.msg_num' | translate } }}
            </div>
          </div>
        </div>
      </ion-card>
      <ion-item class="ion-text-center background-transparent ion-margin-vertical" lines="none" *ngIf="!blastUsageDetailList?.length || (blastUsageDetailList?.[blastUsageDetailList.length - 1]?.country && blastUsageDetailList?.[blastUsageDetailList.length - 1]?.qty)">
        <ion-button class="new-btn center" (click)="addItem()">
          <ion-icon slot="icon-only" name="add"></ion-icon>
        </ion-button>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-no-padding no-padding" color="tertiary" *ngIf="availableCredit">
    <ion-item class="full-width" color="tertiary" lines="none">
      <ion-toggle [(ngModel)]="minusAvailableCredit" [disabled]="availableCredit > totalBlastCredit" (ionChange)="setupTotalBlastCredit()" color="success">
        <ion-text class="font-12">{{ 'BLAST.package.minus_available' | translate: { credits: availableCredit } }}</ion-text>
      </ion-toggle>
    </ion-item>
  </ion-toolbar>
  <ion-toolbar class="ion-padding-horizontal" color="white">
    <ion-item lines="none">
      <ion-text>
        {{ 'LBL.total' | translate }}
      </ion-text>
      <ion-text class="font-20 font-med ion-text-right" color="dark" slot="end">
        <div>
          {{ totalAmount | currency : currency : 'symbol' }}
        </div>
        <div>
          <ion-text class="font-12">{{ 'BLAST.package.name_short' | translate: { count: totalBlastCredit } }}</ion-text>
        </div>
      </ion-text>
    </ion-item>
    <!-- || availableCredit > totalBlastCredit -->
    <ion-button class="ion-margin" expand="block" (click)="purchase(totalBlastCredit)" [disabled]="!totalBlastCredit || minCredit > totalBlastCredit">
      {{ 'BLAST.credit.purchase' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>