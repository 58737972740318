<ion-header class="header-light">
  <ion-toolbar class="gradient-primary-right">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'GIFT.title.profile' | translate }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="module !== 'trash' && gift?.giftId && currentPrivilege?.['gift']?.['edit']">
      <ion-button (click)="presentGiftManageModal(gift)">
        <ion-icon color="dark" class="font-20" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="deleted-titlebar" color="danger" *ngIf="module === 'trash'">
    <ion-title class="font-12">{{ 'MSG.deleted_title_field' | translate: { field: 'GIFT.lbl.gift' | translate } }}</ion-title>
  </ion-toolbar>
  <ion-toolbar class="gradient-primary-right">
    <div class="ion-text-center ion-margin ion-padding-top" *ngIf="gift">
      <ion-img class="icon-img center" *ngIf="gift.giftType === 'other'" src="./assets/wedding/img/gift/other.webp"></ion-img>
      <ion-img class="icon-img center" *ngIf="gift.giftType === 'cash'" src="./assets/wedding/img/gift/cash.webp"></ion-img>
      <div class="font-10" style="margin-top: 6px;">
        <ng-container *ngIf="gift.giftType === 'other'">{{'LIST.gift.other' | translate }}</ng-container>
        <ng-container *ngIf="gift.giftType === 'cash'">{{'LIST.gift.cash' | translate }}</ng-container>
      </div>
      <div class="font-20 line-110 ion-text-wrap font-bold ion-margin-vertical">
        <ng-container *ngIf="gift.giftType === 'other'">{{ gift.name }}</ng-container>
        <ng-container *ngIf="gift.giftType === 'cash'">{{ gift.amount | currency: gift.currency: 'symbol' }}</ng-container>
      </div>
      <div class="font-14 line-110 ion-text-wrap font-italic">
        <ng-container *ngIf="gift.giftType === 'other'">{{ gift.qty }} <ion-text class="font-10">{{ 'LBL.pcs' | translate | lowercase }}</ion-text></ng-container>
        <ng-container *ngIf="gift.giftType === 'cash'">{{ gift.name }}</ng-container>
      </div>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all gradient-primary-right">
    <app-select-all [total]="count" *ngIf="selectedGuestList && selectedGroupList && count" [selected]="selectedGuestList.length" [selectAll]="selectAll" [editMode]="editMode ? true : false" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <ng-container *ngIf="gift">
    <div class="font-12 ion-margin-horizontal ion-margin-top">
      {{ 'GIFT.msg.given_by_count' | translate: { count: count } }}
      <ng-container *ngIf="count <= 1">{{ 'GUEST.lbl.guest' | translate }}</ng-container>
      <ng-container *ngIf="count > 1">{{ 'GUEST.lbl.guests' | translate }}</ng-container>
    </div>
    <div class="guest-list">
      <div class="full-width viewport-div" [style.height.px]="viewportHeight">
        <cdk-virtual-scroll-viewport [itemSize]="estimateItemSize" [minBufferPx]="200" [maxBufferPx]="200">
          <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
            <app-guest-card *cdkVirtualFor="let guest of guestList; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [setGuest]="guest" [editMode]="editMode" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" [mode]="'gift'" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </div>
      <ion-card class="ion-no-margin ion-padding-vertical ion-margin-bottom" (click)="presentGiftLogModal()" button>
        <ion-item lines="none" detail>
          <ion-icon class="icon-small" slot="start" src="./assets/wedding/icon/guest/log.svg" style="vertical-align: middle;"></ion-icon>
          {{ 'BTN.history' | translate}}
        </ion-item>
      </ion-card>
    </div>
    
    <div class="full-width-height center vertical-center" *ngIf="!guestList?.length">
      <ion-label class="ion-text-center ion-margin" color="medium">
        <ion-icon class="center icon-cactus" color="medium" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-12"><ion-text color="medium">{{ 'LBL.no_found' | translate }}</ion-text></div>
      </ion-label>
    </div>
    <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="module !== 'account'">
      <ion-fab-button color="primary" (click)="toggleEditMode()">
        <ion-icon name="chevron-up" *ngIf="!editMode"></ion-icon>
        <ion-icon name="close" *ngIf="editMode"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>
</ion-content>
<ion-footer class="ion-no-border" #footer>
  <ng-container *ngIf="editMode">
    <app-bulk-action [mode]="'gift'" [selectedList]="selectedGuestList" [count]="count" [setReady]="true" (action)="bulkAction($event)"></app-bulk-action>
  </ng-container>
  <ng-container *ngIf="!editMode">
    <ion-toolbar *ngIf="module === 'trash' && gift?.giftId && !editMode">
      <ion-grid class="full-width" fixed>
        <ion-row>
          <ion-col>
            <ion-button expand="block" color="success" (click)="promptRestoreGift()">
              {{ 'CRUD.restore' | translate }}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" color="danger" (click)="promptDeleteGift()">
              {{ 'CRUD.delete' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ng-container>
</ion-footer>