import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-website-success',
  templateUrl: './website-success.component.html',
  styleUrls: ['./website-success.component.scss'],
})
export class WebsiteSuccessComponent  implements OnInit, OnDestroy {

  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.count = 0;
    this.setupSize();
  }
  
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.count = 0;
    this.setupSize();
  }

  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;

  @Input() ratio: number;
  
  size: string;
  count: number;
  
  constructor() { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ngAfterViewInit() {
    this.count = 0;
    this.setupSize();
  }

  setupSize() {
    const contentWidth = this.getContentWidth();
    if (contentWidth && this.count < 5) {
      let size = contentWidth * (this.ratio ? this.ratio : 0.3);
      if (size > 180) {
        size = 180;
      } else if (size < 100) {
        size = 100;
      }
      this.size = size.toString() + 'px';
    } else {
      setTimeout(() => {
        this.count += 1;
        this.setupSize();
      }, 300);
    }
  }

  getContentWidth(): number {
    return this.contentElement?.nativeElement?.offsetWidth ? this.contentElement.nativeElement.offsetWidth : window?.innerWidth? window.innerWidth : 0;
  }
}
