import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { GroupDeleteService } from 'src/app/services/group/group-delete.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GuestGroupComponent } from 'src/app/components/group/guest-group/guest-group.component';
import { GuestQrcodeComponent } from 'src/app/components/qrcode/guest-qrcode/guest-qrcode.component';
import { GiftGroupComponent } from 'src/app/components/group/gift-group/gift-group.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';

import { Guest, GuestItemField } from 'src/app/interfaces/guest';
import { CheckinSetting } from 'src/app/interfaces/setting';
import { Gift } from 'src/app/interfaces/gift';
import { Group } from 'src/app/interfaces/group';
import { GuestListMode } from 'src/app/types/guest';
import { ModuleType } from 'src/app/types/general';
import { GroupEditComponent } from 'src/app/components/group/group-edit/group-edit.component';
import { MobileComponent } from 'src/app/components/general/mobile/mobile.component';
import { Mobile } from 'src/app/interfaces/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { GiftType } from 'src/app/types/gift';
import { BlastPricingService } from 'src/app/services/blast/blast-pricing.service';
import { BlastMsgService } from 'src/app/services/blast/blast-msg.service';
import { BlastMsg, BlastTemplate } from 'src/app/interfaces/blast';
import { BlastDeliveredType } from 'src/app/types/blast';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { WebsiteLinkService } from 'src/app/services/website/website-link.service';

/**
 * Guest card component for display guest group item.
 */
@Component({
  selector: 'app-guest-card',
  templateUrl: './guest-card.component.html',
  styleUrls: ['./guest-card.component.scss'],
})
export class GuestCardComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  
  @Input() blastUsageId: string;
  /**
   * Select modoa
   */
  @Input() selectMode: boolean;
  /**
   * Edit mode
   */
  @Input() editMode: boolean;
  /**
   * Guest list mode
   */
  @Input() mode: GuestListMode;
  /**
   * Guest id
   */
  @Input() guestId: string;
  /**
   * Guest
   */
  @Input() guest: Guest;
  /**
   * Guest list for preview mode.
   */
  @Input() guestList: Guest[];
  /**
   * Selected guest list
   */
  @Input() selectedGuestList: string[];
  /**
   * Selected group list
   */
  @Input() selectedGroupList: string[];
  /**
   * Index of current guest item in the list.
   * Coloring based on even / odd to differentiate record
   */
  @Input() index: number;
  /**
   * Selected flag. Highlight the color of selected records.
   */
  @Input() selected: boolean;
  /**
   * Guest group modal. Dismiss current modal instead of present new guest group modal
   */
  @Input() groupModal: boolean;
  /**
   * Disable group member list, show group card only.
   */
  @Input() disableGroupMemberList: boolean;
  /**
   * Disable guest item click
   */
  @Input() disableClick: boolean;
  /**
   * Disable QR code sliding item.
   */
  @Input() disableQrcode: boolean;
  /**
   * Disable seating sliding item.
   */
  @Input() disableSeating: boolean;
  /**
   * Disable edit sliding item.
   */
  @Input() disableEdit: boolean;
  /**
   * Disable delete sliding item.
   */
  @Input() disableDelete: boolean;
  /**
   * Disable restore sliding item
   */
  @Input() disableRestore: boolean;
  /**
   * Disable checkin sliding item
   */
  @Input() disableCheckin: boolean;
  /**
   * Disable uncheck sliding item
   */
  @Input() disableUncheck: boolean;
  /**
   * Disable sliding item
   */
  @Input() disableSlide: boolean;
  /**
   * Disable watch guest
   */
  @Input() disableWatchGuest: boolean;

  @Input() seatingTypeName: string;

  @Input() blastTemplate: BlastTemplate;
  /**
   * Set hide field
   */
   @Input() set setHideField(hideField: GuestItemField) {
    this.hideField = hideField;
    if (hideField) {
      Object.keys(hideField)?.forEach((key: string) => {
        if (key && this.showField?.[key]) {
          this.showField[key] = false;
        }
      });
    }
  }
  // @Input() set setModule(module: ModuleType) {
  //   if (this.module !== module) {
  //     this.module = module;
  //     this.setupModule();
  //     if (this.module === 'checkin' || this.module === 'gift') {
  //       this.watchCheckinSetting();
  //     } else {
  //       this.unwatchCheckinSetting();
  //     }

  //     if (this.module === 'gift' && this.group) {
  //       this.setupGiftCount(this.group);
  //     }
  //   }
  //   this.checkSlidingItem();
  // }
  /**
   * Show guest seating
   */
  @Input() showGuestSeating: boolean;
  /**
   * Set guest
   */
  @Input() set setGuest(guest: any) {
    if (!this.functionService.isEqual(guest, this.guest)) {
      this.guest = guest;
      this.setupGuest();
    }
  }

  /**
   * Set selected guest
   */
  @Output() setSelectedGuest = new EventEmitter<string>();
  /**
   * Set selected group
   */
  @Output() setSelectedGroup = new EventEmitter<string>();
  /**
   * Check selected group
   */
  @Output() checkSelectedGroup = new EventEmitter<string>();

  /**
   * Hide field
   */
  hideField: GuestItemField;
  /**
   * Show field by default.
   */
  showField: GuestItemField = {
    name: true,
    nickname: true,
    invitedBy: true,
    category: true,
    dietaryReq: true,
    specialReq: true,
    remark: true,
    group: true,
    attendingStatus: true,
    qrcodeStatus: true,
    seatingStatus: true
  };
  
  /**
   * Guest group
   */
  group: Group;
  /**
   * Group total member count
   */
  groupTotal: number;
  /**
   * Member List for preview, not watching.
   */
  memberList: Guest[];
  /**
   * AccountModule type
   */
  module: ModuleType;
  /**
   * Check-in setting
   */
  checkinSetting: CheckinSetting;

  giftCount: {
    'cash': number,
    'other': number,
  };

  blastMsg: BlastMsg;
  deliveredStatus: BlastDeliveredType;
  
  /**
   * Group list subscripiton
   */
  private groupListSubscription: Subscription;
  /**
   * AccountModule subscription
   */
  private moduleSubscription: Subscription;
  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;

  private blastMsgSubscription: Subscription;


  /**
   * Constructor
   * @param modalController modal controller
   * @param actionSheetController action sheet controller
   * @param translate translate service
   * @param moduleService module service
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param groupService group service
   * @param groupManageService group manage service
   * @param checkinService check-in service
   * @param checkinSettingService checkin setting service
   * @param giftService gift service
   * @param giftCheckinService gift check-in service
   * @param userService user service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private groupDeleteService: GroupDeleteService,
    private seatingSettingService: SeatingSettingService,
    private checkinService: CheckinService,
    private checkinSettingService: CheckinSettingService,
    private blastMsgService: BlastMsgService,
    private blastPricingService: BlastPricingService,
    private giftService: GiftService,
    private websiteLinkService: WebsiteLinkService,
    private dateTimeService: DateTimeService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  /**
   * On init
   */
  ngOnInit() {
    this.watchModule();
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  unwatch() {
    this.unwatchModule();
    this.unwatchGroupList();
    this.unwatchCheckinSetting();
    this.unwatchBlastMsg();
  }
  
  /**
   * Watch group list
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      if (this.group) {
        this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
          this.setupGroup();
        });
      }
    }
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  /**
   * Watch module
   */
  async watchModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        if (this.module !== module) {
          this.module = module;
          this.setupModule();
          if (this.module === 'checkin' || this.module === 'gift') {
            this.watchCheckinSetting();
          } else {
            this.unwatchCheckinSetting();
          }

          if (this.module === 'blast') {
            this.watchBlastMsg();
          } else {
            this.unwatchBlastMsg();
          }
  
          // if (this.module === 'gift' && this.group) {
          //   this.setupGiftCount(this.group);
          // }
        }
        this.checkSlidingItem();
      });
    }
    
  }

  /**
   * Unwatch module
   */
  async unwatchModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  async watchBlastMsg() {
    if (!this.blastMsgSubscription) {
      this.blastMsgSubscription = this.blastMsgService.observableBlastMsgList.subscribe(() => {
        this.setupBlastMsg();
      });
    }
  }

  async unwatchBlastMsg() {
    if (this.blastMsgSubscription) {
      this.blastMsgSubscription.unsubscribe();
      this.blastMsgSubscription = null;
    }
  }

  /**
   * Setup module
   */
  setupModule() {
    if (this.module === 'checkin') {
      this.disableQrcode = true;
      this.disableDelete = true;
    } else if (this.module === 'trash') {
      this.disableEdit = true;
      this.disableQrcode = true;
      this.disableSeating = true;
    } else if (this.module === 'gift') {
      this.disableDelete = true;
      this.disableQrcode = true;
      this.disableSeating = true;
    } else if (this.module === 'blast') {
      this.disableSeating = true;
    }
    if (this.mode === 'qrcode') {
      this.disableQrcode = true;
    }

    if (this.module !== 'trash') {
      this.disableRestore = true;
    }

    if (this.module === 'trash' && this.mode === 'gift') {
      this.disableClick = true;
      this.disableDelete = true;
      this.disableRestore = true;
    }

    if (this.mode === 'preview') {
      this.disableSlide = true;
    }
  }

  /**
   * Setup guest
   */
  async setupGuest() {
    if (this.guest?.group) {
      this.group = this.guest.group;
      await this.setupMemberList();
      await this.watchGroupList();
      // this.setupViewport();
    } else {
      this.group = null;
    }
  }

  /**
   * Setup guest group & group total member count
   */
  setupGroup() {
    if (this.group?.groupId) {
      const group: Group = this.functionService.cloneDeep(this.groupService.getGroup(this.group.groupId));
      if (group) {
        if (group.status) {
          this.group.status = group.status;
        }
        this.group.groupName = group.groupName;

        this.setupMemberCount(group);
        this.setupGiftCount();
        // this.setupGiftCount(group);
      }
    }
  }

  /**
   * Setup memberlist
   */
  async setupMemberList() {
    if (this.guestList) {
      this.memberList = this.guestList.filter((guest: Guest) => {
        return guest.groupId === this.group.groupId ? true : false;
      });
    }
  }

  /**
   * Setup member count
   * @param group group
   */
  setupMemberCount(group?: Group) {
    if (group?.memberList?.length) {
      group.memberList = group.memberList.filter((guestId: string) => {
        return this.guestService.getGuest(guestId) ? true : false;
      });
      this.groupTotal = group.memberList.length;
    } else {
      this.groupTotal = 0;
    }
  }

  setupGiftCount() {
    this.giftCount = {
      cash: this.getGiftCount('cash'),
      other: this.getGiftCount('other'),
    };
  }

  getGiftCount(giftType: GiftType) {
    let count = 0;
    if (this.module === 'gift' && giftType) {
      if (this.group?.giftList?.length) {
        const giftList = this.giftService.getGiftListById(this.group.giftList);
        if (giftList?.length) {
          giftList?.forEach((gift: Gift) => {
            if (gift.giftType === giftType) {
              count++;
            }
          });
        }
      }
    }
    return count;
  }

  /**
   * Get guest
   * @param guestId Guest id
   * @retruns guest
   */
  getGuest(guestId: string): Guest {
    return this.guestService.getGuest(guestId);
  }

  getSeatingTypeName(): string {
    return this.seatingTypeName ? this.seatingTypeName : this.seatingSettingService.getSeatingTypeName();
  }

  getBlastMsg(): BlastMsg {
    if (this.module === 'blast' && this.blastUsageId && (this.mode === 'sms-result' || this.mode === 'whatsapp-result')) {
      const blastMsgList: BlastMsg[] = this.blastMsgService.getBlastMsgListByGroupId(this.group?.groupId, this.blastUsageId);
      return blastMsgList?.[0];
    }
    return null;
  }

  getBlastMsgDeliveredStatus(blastMsg: BlastMsg): BlastDeliveredType {
    if (blastMsg?.status) {
      if (blastMsg?.status?.read) {
        return 'read';
      } else if (blastMsg?.status?.delivered) {
        return 'delivered';
      } else if (blastMsg?.status?.failed) {
        return 'failed';
      } else if (blastMsg?.status?.sent) {
        return  'sent';
      }
    }
    return 'processing';
  }

  setupBlastMsg() {
    this.blastMsg = this.getBlastMsg();
    this.deliveredStatus = this.getBlastMsgDeliveredStatus(this.blastMsg);
  }

  /**
   * Check sliding item
   */
  checkSlidingItem() {
    // if (type === 'edit') {
    //   if (this.module === 'blast') {
    //     if (!this.disableEdit && this.checkPrivilege(this.module, 'send')) {
    //       return true;
    //     }
    //   } else if (!this.disableEdit && this.checkPrivilege(this.module, 'edit')) {
    //     return true;
    //   }
    // } else if (type === 'delete') {
    //   if (!this.disableDelete && this.checkPrivilege(this.module, 'delete')) {
    //     return true;
    //   }
    // } else if (type === 'restore') {
    //   if (!this.disableRestore && this.checkPrivilege(this.module, 'restore')) {
    //     return true;
    //   }
    // } else if (type === 'qrcode') {
    //   if (!this.disableQrcode && this.checkPrivilege(this.module, 'qrcode')) {
    //     return true;
    //   }
    // } else if (type === 'seating') {
    //   if (!this.disableSeating && this.checkPrivilege(this.module, 'seating')) {
    //     return true;
    //   }
    // } else if (type === 'checkin') {
    //   if (!this.disableCheckin && this.checkPrivilege(this.module, 'checkin')) {
    //     return true;
    //   }
    // } else if (type === 'uncheck') {
    //   if (!this.disableUncheck && this.checkPrivilege(this.module, 'uncheck')) {
    //     return true;
    //   }
    // } 
    // return false;
    if (this.module === 'guest') {
      if (!this.disableEdit && !this.checkPrivilege(this.module, 'edit')) {
        this.disableEdit = true;
      }
      if (!this.disableDelete && !this.checkPrivilege(this.module, 'delete')) {
        this.disableDelete = true;
      }
      if (!this.disableSeating && !this.checkPrivilege(this.module, 'seating')) {
        this.disableSeating = true;
      }
      if (!this.disableQrcode && !this.checkPrivilege(this.module, 'qrcode')) {
        this.disableQrcode = true;
      }
    } else if (this.module === 'checkin') {
      if (!this.disableCheckin && !this.checkPrivilege(this.module, 'checkin')) {
        this.disableCheckin = true;
      }
      if (!this.disableUncheck && !this.checkPrivilege(this.module, 'uncheck')) {
        this.disableUncheck = true;
      }
    } else if (this.module === 'gift') {
      if (!this.disableEdit && !this.checkPrivilege(this.module, 'edit')) {
        this.disableEdit = true;
      }
      if (!this.disableDelete && !this.checkPrivilege(this.module, 'delete')) {
        this.disableDelete = true;
      }
    } else if (this.module === 'trash') {
      if (!this.disableDelete && !this.checkPrivilege(this.module, 'delete')) {
        this.disableDelete = true;
      }
      if (!this.disableRestore && !this.checkPrivilege(this.module, 'restore')) {
        this.disableRestore = true;
      }
    } else if (this.module === 'blast') {
      if (!this.disableEdit && !this.checkPrivilege(this.module, 'send')) {
        this.disableEdit = true;
      }
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Guest click
   * @param guest Guest
   * @param group Group flag
   */
  guestClick(guest: Guest, guestId?: string, group?: boolean) {
    if (!this.disableClick) {
      if (this.selectMode || this.editMode) {
        if (!guest?.guestId && guestId) {
          guest = this.guestService.getGuest(guestId);
        }
        if (this.module === 'blast' && (this.mode === 'sms' || this.mode === 'whatsapp') && group && this.group?.groupId && !this.group?.mobile?.no) {
          this.presentMobileInputModal(this.group);
        } else if (this.module === 'blast' && (this.mode === 'sms' || this.mode === 'whatsapp') && guest?.guestId && !guest?.mobile?.no) {
        } else if (this.module === 'blast' && this.mode === 'sms' && guest?.guestId && guest?.mobile?.no && guest?.mobile?.code && this.blastPricingService.getSmsCredit(guest.mobile?.country, guest.mobile.code) === -1) {
          // present mobile not supported
        } else if (this.module === 'blast' && this.mode === 'whatsapp' && guest?.guestId && guest?.mobile?.no && guest?.mobile?.code && this.blastPricingService.getWhatsAppCredit(guest.mobile?.country, guest.mobile.code) === -1) {
          // present mobile not supported
        } else if (this.mode === 'sms' && this.blastTemplate?.url && this.guest?.mobile?.code === 60) {

        } else {
          if (group) {
            if (this.module === 'blast' && (this.mode === 'sms' || this.mode === 'whatsapp')) {
              if (this.mode === 'sms' && this.group?.mobile?.no && this.group?.mobile?.code && this.blastPricingService.getSmsCredit(this.group.mobile?.country, this.group.mobile.code) === -1) {
                this.presentMobileNotSupportModal(this.group);
              } else if (this.mode === 'whatsapp' && this.group?.mobile?.no && this.group?.mobile?.code && this.blastPricingService.getWhatsAppCredit(this.group.mobile?.country, this.group.mobile.code) === -1) {
                this.presentMobileNotSupportModal(this.group);
              } else if (this.mode === 'sms' && this.blastTemplate?.url && this.group?.mobile?.code === 60) {
                this.presentMobileNotSupportModal(this.group);
              } else {
                this.setSelectedGroup.next(this.group.groupId);
              }
            } else {
              this.setSelectedGroup.next(this.group.groupId);
            }
          } else if (guest?.guestId) {
            this.setSelectedGuest.next(guest.guestId);
            if (guest.groupId) {
              this.checkSelectedGroup.next(guest.groupId);
            }
          } else if (guestId) {
            this.setSelectedGuest.next(guestId);
            if (this.group?.groupId) {
              this.checkSelectedGroup.next(this.group.groupId);
            }
          }
        }
      } else {
        if (group && this.group) {
          if (this.module === 'gift') {
            if (!this.groupModal) {
              this.presentGiftGroupModal(this.group);
            }
          } else {
            if (this.mode === 'qrcode') {
              this.presentGuestQrcodeModal(this.group);
            } else if (this.mode === 'invites') {
              this.websiteLinkService.sharePriviteLink('', this.group.groupId);
              // this.presentGuestQrcodeModal(this.group);
            } else {
              if (this.groupModal) {
                if (this.module !== 'trash' && this.module !== 'checkin') {
                  this.presentGroupEditModal();
                }
              } else {
                this.presentGroupModal(this.group);
              }
            }
          }
        }
      }
    }
  }

  /**
   * Present group name prompt
   * @param slidingItem Sliding item
   */
  async presentGroupNamePrompt(slidingItem?: any) {
    if (this.checkPrivilege(this.module, 'edit')) {
      const value = this.group?.groupName;
      const modal = await this.popupService.presentInput(this.translate.instant('GROUP.lbl.name'), '', '', '', value);
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.input) {
          this.saveGroupName(result.data.input);
        }
      });
      if (slidingItem) { slidingItem.close(); }
    }
  }

  async presentGroupEditModal() {
    if (this.group && this.checkPrivilege(this.module, 'edit')) {
      const modal = await this.modalController.create({
        component: GroupEditComponent,
        cssClass: '',
        componentProps: {
          group: this.group,
          groupModal: this.groupModal
        }
      });
      modal.present();
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
        }
      });
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  async presentMobileNotSupportModal(group: Group, slidingItem?: any) {
    if (group?.groupId) {
      if (this.module === 'blast' && this.checkPrivilege(this.module, 'send')) {
        const confirm = await this.popupService.presentConfirm(
          this.translate.instant('BLAST.msg.country_sms_not_support'),
          '',
          this.translate.instant('BLAST.msg.mobile_not_support'),
          '',
          this.translate.instant('BTN.ok'),
          this.translate.instant('CRUD.edit'),
          false, '', [], false, [], true
        );
        confirm.onDidDismiss().then((result: any) => {
          if (!result?.data?.confirm && !result?.data?.cancel) {
            this.presentMobileInputModal(group);
          }
        });
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
      }
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  async presentMobileInputModal(group: Group, slidingItem?: any) {
    if (group?.groupId) {
      if (this.module === 'blast' && this.checkPrivilege(this.module, 'send')) {
        const modal = await this.modalController.create({
          component: MobileComponent,
          cssClass: 'modal-transparent',
          componentProps: {
            cssClass: '',
            modalMode: true,
            required: true,
            mobile: group?.mobile,
            lbl: this.translate.instant('MOBILE.lbl.no')
          }
        });
        modal.present();
        modal.onDidDismiss().then(async (result: any) => {
          if (result?.data?.mobile?.no) {
            if (group?.mobile?.no !== result.data.mobile.no) {
              await this.popupService.presentLoading();
              const mobile: Mobile = result.data.mobile;
              this.group.mobile = mobile;
              await this.groupManageService.saveGroupList([ this.group.groupId ], { mobile }, [ this.group ]);
              this.popupService.dismissLoading();
              this.popupService.saveSuccessToast();
            }
          }
        });
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
      }
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Present guest group modal
   * @param group Guest group
   */
  async presentGroupModal(group: Group) {
    if (group?.groupId) {
      const modal = await this.modalController.create({
        component: GuestGroupComponent,
        cssClass: '',
        componentProps: {
          group,
          mode: this.mode,
          deletedMode: this.module === 'trash' ? true : false
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.guest) {
          this.guest = result.data.guest;
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present gift group modal
   * @param group group
   */
  async presentGiftGroupModal(group: Group) {
    if (group?.groupId) {
      const modal = await this.modalController.create({
        component: GiftGroupComponent,
        cssClass: '',
        componentProps: {
          group,
          mode: this.mode,
          disableGroupWatch: this.module === 'trash' ? true : false,
          deletedMode: this.module === 'trash' ? true : false
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.guest) {
          this.guest = result.data.guest;
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prsent guest qrcode modal
   * @param group Guest group
   * @param slidingItem Sliding item
   */
  async presentGuestQrcodeModal(group: Group, slidingItem?: any) {
    if (group?.groupId) {
      const modal = await this.modalController.create({
        component: GuestQrcodeComponent,
        cssClass: '',
        componentProps: {
          group,
          module: this.module,
          mode: this.mode,
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Present seating list modal
   */
  async presentSeatingListModal(assignMode: boolean, swapMode: boolean) {
    const newGuestList = this.group.memberList.map((guestId: string) => {
      return this.getGuest(guestId);
    });
    const modal = await this.modalController.create({
      component: SeatingListComponent,
      cssClass: '',
      componentProps: {
        assignMode,
        swapMode,
        newGuestList,
      }
    });
    modal.present();
  }

  /**
   * Prompt unassign
   */
  async promptUnassign() {
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('SEATING.msg.confirm_unassign_seating', { seating: this.getSeatingTypeName() })
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        this.saveUnassignSeating();
      }
    });
  }

  /**
   * Prompt confirm restore modal
   */
  async promptCheckin(group: Group, slidingItem: any) {
    if (group?.memberList?.length) {
      await this.checkinService.checkin('', group.memberList, this.memberList, [ this.group.groupId ], [ this.group ], false, true, true);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptUncheck(group: Group, slidingItem: any) {
    if (group?.memberList?.length) {
      await this.checkinService.uncheck(group.memberList, this.memberList, [ this.group.groupId ], [ this.group ]);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete(group?: Group) {
    if (group?.groupId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field', {
          field: this.translate.instant('GROUP.lbl.guest_group') })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGroupAndMember(group);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present seating action sheet. Assign seating / unassign.
   */
  async presentSeatingActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.getSeatingTypeName(),
      buttons: [{
        text: this.translate.instant('SEATING.btn.move', { seating: this.getSeatingTypeName() }),
        handler: () => {
          this.presentSeatingListModal(true, false);
        }
      }, {
        text: this.translate.instant('SEATING.btn.unassign', { seating: this.getSeatingTypeName() }),
        handler: () => {
          this.promptUnassign();
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Present delete group action. Remove guest from group / delete guest directly.
   * @param groupId Group ID
   */
  async presentGroupDeleteActionSheet(group: Group) {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('CRUD.delete_field', { field: this.translate.instant('LIST.group.group') }),
      buttons: [{
        text: this.translate.instant('GROUP.delete.group_action_sheet.remove_group'),
        handler: () => {
          this.removeMemberFromGroup(group);
        }
      }, {
        text: this.translate.instant('GROUP.delete.group_action_sheet.delete_guest'),
        handler: () => {
          this.removeGroupAndMember(group);
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Edit
   * @param slidingItem Sliding item
   */
  async edit(slidingItem?: any) {
    await this.presentGroupEditModal();
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Seating action. Assign seating if no guest seating was asisgn, else present action sheet for more options.
   * @param slidingItem Sliding item
   */
  seating(slidingItem?: any) {
    if (this.group?.memberList?.length) {
      if (this.guestService.getGroupSeating(this.group.memberList).length) {
        this.presentSeatingActionSheet();
      } else {
        this.presentSeatingListModal(true, false);
      }
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Restore group
   * @param slidingItem sliding item
   */
  async restore(slidingItem?: any) {
    if (this.group?.groupId) {
      await this.popupService.presentLoading();
      await this.groupDeleteService.restoreGroupList([ this.group.groupId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Delete action, prompt action sheet for delete options.
   */
  async deleteFunc(slidingItem?: any) {
    if (this.module === 'trash') {
      this.promptDelete(this.group);
    } else {
      this.presentGroupDeleteActionSheet(this.group);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Remove guest group
   * @param groupId Group ID
   */
  async removeMemberFromGroup(group: Group) {
    if (group?.groupId) {
      await this.popupService.presentLoading();
      await this.groupDeleteService.removeMemberFromGroup(group.groupId, group, [], false);

      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Delete guest group and member
   * @param groupId group id
   */
  async removeGroupAndMember(group: Group) {
    if (group?.groupId) {
      await this.popupService.presentLoading();
      await this.groupDeleteService.removeGroupList([ group.groupId ], [ group ]);

      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Delete group and member
   * @param group Group
   */
  async deleteGroupAndMember(group: Group) {
    if (group?.groupId) {
      await this.popupService.presentLoading();
      await this.groupDeleteService.deleteGroupListMember([ group.groupId ], [ group ]);
      await this.groupDeleteService.deleteGroupList([ group.groupId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Save gift
   * @param group group
   * @param gift gift
   * @param slidingItem sliding item
   */
  saveGift(group: Group, gift: boolean, slidingItem?: any) {
    if (group?.groupId) {
      // this.checkinService.receiveGift(gift, [], [ this.group.groupId ], [], [ this.group ], false, false, true);
      const guestList = this.guestService.getGuestListById(this.group?.memberList);
      this.checkinService.receiveGift('', gift, this.group?.memberList, [ this.group.groupId ], guestList, [ this.group ], false, false, true);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Save guest group unassign seating
   */
  async saveUnassignSeating() {
    await this.popupService.presentLoading();
    if (this.group?.memberList) {
      await this.guestManageService.saveGuest({ seating: ''}, this.group.memberList);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  /**
   * Save group name
   * @param groupName group name
   */
  async saveGroupName(groupName: string) {
    await this.popupService.presentLoading();
    if (groupName && this.group && groupName !== this.group.groupName) {
      const data = {
        groupName,
        updateBy: this.updateByService.updateBy
      };
      this.group.groupName = groupName;
      this.group.updateBy = this.updateByService.updateBy;

      await this.groupManageService.saveGroupList([ this.group.groupId ], data, [ this.group ]);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  calculateCardHeight() {
    const margin = 0;
    const guestItem = 100;
    const groupItem = 80;

    if (this.memberList?.length) {
      return groupItem + (this.memberList.length * guestItem) + margin;
    } else if (this.group?.memberList?.length) {
      return groupItem + (this.group.memberList.length * guestItem) + margin;
    }
    return guestItem + margin;
  }

  trackByFn(index: number, item: any) {
    if (item?.guestId) {
      return item?.guestId;
    } else if (typeof item === 'string') {
      return item;
    }
    return index;
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

}
