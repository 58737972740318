<ion-content>
  <div class="full-height vertical-center" [style.--backgroundLine]="websiteThemeColor?.backgroundLine" [style.--backgroundColor]="websiteThemeColor?.background" [style.--fontColor]="websiteThemeColor?.font" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" [style.--font]="font">
    <ion-card class="confirm-modal-card center ion-no-padding">
      <ion-card-content class="ion-no-padding">
        <div class="ion-margin-vertical">
          <ion-icon class="icon-warning center" name="warning-outline"></ion-icon>
        </div>
        <div>
          <div class="ion-margin-vertical font-24 font-semi" *ngIf="title" [innerHTML]="title"></div>
          <div class="ion-margin-vertical font-20" *ngIf="subtitle" [innerHTML]="subtitle"></div>
          <div class="ion-margin-vertical ion-text-center font-16" *ngIf="msg" [innerHTML]="msg"></div>
        </div>
        <ion-grid fixed>
          <ion-row class="ion-margin-vertical ion-hide-sm-down"></ion-row>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size-xs="0" size-sm="1"></ion-col>
            <ion-col class="ion-align-self-center ion-text-center">
              <ion-button class="full-width ion-no-margin" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="cancel()">
                <ion-icon class="font-20" name="close"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size-xs="1" size-sm="2"></ion-col>
            <ion-col class="ion-align-self-center center">
              <ion-button class="full-width ion-no-margin" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="confirm()">
                <ion-icon class="font-26 font-bold" name="checkmark-outline"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size-xs="0" size-sm="1"></ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>