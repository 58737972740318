<ion-header class="header-light">
  <ion-toolbar [color]="type === 'theme' ? 'white' : 'light'">
    <ion-buttons slot="start">
      <ion-button (click)="discard()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="type === 'setting'">{{ 'VISITOR.setting.title' | translate }}</ng-container>
      <ng-container *ngIf="type === 'info'">{{ 'VISITOR.setting.info' | translate }}</ng-container>
      <ng-container *ngIf="type === 'theme'">{{ 'VISITOR.setting.theme' | translate }}</ng-container>
    </ion-title>
    <ion-buttons slot="end" *ngIf="type !== 'theme'">
      <ion-button class="ion-margin" expand="block" (click)="reset()">
        <ion-icon slot="icon-only" name="refresh"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom" [color]="type === 'theme' ? 'white' : 'light'" #content>
  <ng-container *ngIf="type === 'setting'">
    <form [formGroup]="pageSettingForm" *ngIf="pageSettingForm">
      <ion-list>
        <ion-card class="setting-card">
          <ion-item class="gps-item" lines="none">
            <ion-label class="ion-text-wrap">
              <ion-item class="ion-item-no-padding" lines="none">
                <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/general/map-point.svg"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.gps.title' | translate"></div>
                  <div class="font-12">
                    <ion-text [innerHtml]="'VISITOR.setting.gps.on' | translate" *ngIf="pageSettingForm.value?.googleMap || pageSettingForm.value?.waze || pageSettingForm.value?.uber || pageSettingForm.value?.appleMap"></ion-text>
                    <ion-text [innerHtml]="'VISITOR.setting.gps.off' | translate" *ngIf="!pageSettingForm.value?.googleMap && !pageSettingForm.value?.waze && !pageSettingForm.value?.uber && !pageSettingForm.value?.appleMap"></ion-text>
                  </div>
                </ion-label>
              </ion-item>
              <div>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.googleMap ? 'success' : 'light'" (click)="updateGpsSetting('googleMap')" *ngIf="googleMapCountry">
                  <ion-text class="font-10">{{ 'VISITOR.setting.lbl.google' | translate }}</ion-text>
                </ion-button>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.waze ? 'success' : 'light'" (click)="updateGpsSetting('waze')" *ngIf="wazeCountry">
                  <ion-text class="font-10">{{ 'VISITOR.setting.lbl.waze' | translate }}</ion-text>
                </ion-button>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.uber ? 'success' : 'light'" (click)="updateGpsSetting('uber')" *ngIf="uberCountry">
                  <ion-text class="font-10">{{ 'VISITOR.setting.lbl.uber' | translate }}</ion-text>
                </ion-button>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.grab ? 'success' : 'light'" (click)="updateGpsSetting('grab')" *ngIf="grabCountry">
                  <ion-text class="font-10">{{ 'VISITOR.setting.lbl.grab' | translate }}</ion-text>
                </ion-button>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.apple ? 'success' : 'light'" (click)="updateGpsSetting('appleMap')">
                  <ion-text class="font-10">{{ 'VISITOR.setting.lbl.apple' | translate }}</ion-text>
                </ion-button>
              </div>
            </ion-label>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" name="cloud-done-outline" slot="start"></ion-icon>
            <ion-toggle formControlName="webCheckin" color="success" (ionChange)="setupSelfCheckinTime()">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.webCheckin.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.webCheckin.on' | translate" *ngIf="pageSettingForm.value?.webCheckin"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.webCheckin.off' | translate" *ngIf="!pageSettingForm.value?.webCheckin"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <ng-container *ngIf="pageSettingForm.value?.webCheckin">
          <ion-card class="setting-card">
            <ion-item class="setting-item web-checkin-time-item ion-item-no-padding" lines="none" (click)="presentSelfCheckinTimePicker('start', pageSettingForm.value?.webCheckinStartHour, pageSettingForm.value?.webCheckinStartMinute)" detail>
              <ion-icon class="setting-icon icon-small" name="hourglass-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">
                <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.webCheckinStartTime.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.webCheckinStartTime.msg' | translate"></ion-text>
                </div>
              </ion-label>
              <ion-text class="checkin-time ion-no-margin ion-text-right" slot="end">
                {{ pageSettingForm.value?.webCheckinStartHour }}{{ 'DATE.lbl.h' | translate }} {{ pageSettingForm.value?.webCheckinStartMinute }}{{ 'DATE.lbl.m' | translate }}
              </ion-text>
            </ion-item>
          </ion-card>

          <ion-card class="setting-card">
            <ion-item class="setting-item web-checkin-time-item ion-item-no-padding" lines="none" (click)="presentSelfCheckinTimePicker('end', pageSettingForm.value?.webCheckinEndHour, pageSettingForm.value?.webCheckinEndMinute)" detail>
              <ion-icon class="setting-icon icon-small" name="stopwatch-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">
                <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.webCheckinEndTime.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.webCheckinEndTime.msg' | translate"></ion-text>
                </div>
              </ion-label>
              <ion-text class="checkin-time ion-no-margin ion-text-right" slot="end">
                {{ pageSettingForm.value?.webCheckinEndHour }}{{ 'DATE.lbl.h' | translate }} {{ pageSettingForm.value?.webCheckinEndMinute }}{{ 'DATE.lbl.m' | translate }}
              </ion-text>
            </ion-item>
          </ion-card>
        </ng-container>
        
        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/tabs/guest-outline.svg" slot="start"></ion-icon>
            <ion-toggle formControlName="pax" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.pax.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.pax.on' | translate" *ngIf="pageSettingForm.value?.pax"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.pax.off' | translate" *ngIf="!pageSettingForm.value?.pax"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/guest/seating-outline.svg" slot="start"></ion-icon>
            <ion-toggle formControlName="seatingBeforeCheckin" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.seating.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.seating.on' | translate" *ngIf="pageSettingForm.value?.seatingBeforeCheckin"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.seating.off' | translate" *ngIf="!pageSettingForm.value?.seatingBeforeCheckin"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>
        
        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/qrcode/qrcode-outline.svg" slot="start"></ion-icon>
            <ion-toggle formControlName="qrcode" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.qrcode.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.qrcode.on' | translate" *ngIf="pageSettingForm.value?.qrcode"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.qrcode.off' | translate" *ngIf="!pageSettingForm.value?.qrcode"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" name="time-outline" slot="start"></ion-icon>
            <ion-toggle formControlName="session" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'LBL.session' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.session.on' | translate" *ngIf="pageSettingForm.value?.session"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.session.off' | translate" *ngIf="!pageSettingForm.value?.session"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/guest/group.svg" slot="start"></ion-icon>
            <ion-toggle formControlName="groupMember" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.groupMember.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.groupMember.on' | translate" *ngIf="pageSettingForm.value?.groupMember"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.groupMember.off' | translate" *ngIf="!pageSettingForm.value?.groupMember"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <!-- <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/general/cocktail-outline.svg"></ion-icon>
            <ion-label class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.cocktail.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.cocktail.on' | translate" *ngIf="pageSettingForm.value?.cocktail"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.cocktail.off' | translate" *ngIf="!pageSettingForm.value?.cocktail"></ion-text>
              </div>
            </ion-label>
            <ion-toggle formControlName="cocktail" color="success"></ion-toggle>
          </ion-item>
        </ion-card> -->
  
        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none" *ngIf="pageSettingForm.value.qrcode">
            <ion-icon class="setting-icon icon-small" name="help-circle-outline" slot="start"></ion-icon>
            <ion-toggle formControlName="btn" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.btn.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.btn.on' | translate" *ngIf="pageSettingForm.value?.btn"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.btn.off' | translate" *ngIf="!pageSettingForm.value?.btn"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="setting-item" lines="none">
            <ion-icon class="setting-icon icon-small" name="calendar-outline" slot="start"></ion-icon>
            <ion-toggle formControlName="calendar" color="success">
              <ion-text class="ion-text-wrap">
                <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.calendar.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.calendar.on' | translate" *ngIf="pageSettingForm.value?.calendar"></ion-text>
                  <ion-text [innerHtml]="'VISITOR.setting.calendar.off' | translate" *ngIf="!pageSettingForm.value?.calendar"></ion-text>
                </div>
              </ion-text>
            </ion-toggle>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="full-width" lines="none">
            <ion-icon class="setting-icon icon-small" name="image-outline"></ion-icon>
            <ion-label class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.background.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.background.on' | translate" *ngIf="pageSetting?.backgroundUrl"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.background.off' | translate" *ngIf="!pageSetting?.backgroundUrl"></ion-text>
              </div>
            </ion-label>
          </ion-item>
          <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectPhoto()">
            <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.backgroundUrl">
              <ion-label class="ion-margin ion-padding ion-text-center">
                <ion-img class="center upload-img" name="cloud-upload-outline"></ion-img>
                <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.background.upload' | translate }}</div>
              </ion-label>
            </ion-item>
            <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.backgroundUrl">
              <ion-label class="ion-no-margin custom-background-label">
                <app-cached-image class="custom-background" [type]="'custom-background'" [src]="pageSetting.backgroundUrl" [spinner]="true"></app-cached-image>
                <div class="ion-text-center">
                  <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
                </div>
              </ion-label>
            </ion-item>
          </div>
        </ion-card>

        <ion-card class="setting-card">
          <ion-item class="gps-item" lines="none">
            <ion-label class="ion-text-wrap">
              <ion-item class="ion-item-no-padding" lines="none">
                <ion-icon class="setting-icon icon-small" name="document-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.attachment.title' | translate"></div>
                  <div class="font-12">
                    <ion-text [innerHtml]="'VISITOR.setting.attachment.intro' | translate"></ion-text>
                    <!-- <ion-text [innerHtml]="'VISITOR.setting.attachment.off' | translate" *ngIf="!pageSettingForm.value?.floorplan?.enable && !pageSettingForm.value?.menu?.enable && !pageSettingForm.value?.attachment?.[0]?.enable"></ion-text> -->
                  </div>
                </ion-label>
              </ion-item>
              <div>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.floorplan?.enable ? 'success' : 'light'" (click)="updateAttachment('floorplan')">
                  <ion-text class="font-10">{{ 'VISITOR.setting.attachment.floorplan.title' | translate }}</ion-text>
                </ion-button>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.menu?.enable ? 'success' : 'light'" (click)="updateAttachment('menu')">
                  <ion-text class="font-10">{{ 'VISITOR.setting.attachment.menu.title' | translate }}</ion-text>
                </ion-button>
                <ion-button class="gps-button" size="small" [color]="pageSettingForm?.value?.attachment?.[0]?.enable ? 'success' : 'light'" (click)="updateAttachment('attachment')">
                  <ion-text class="font-10">{{ 'LBL.other' | translate }}</ion-text>
                </ion-button>
              </div>
            </ion-label>
          </ion-item>
        </ion-card>

        <ion-card class="setting-card" *ngIf="pageSettingForm?.value?.floorplan?.enable">
          <ion-item class="full-width" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/visitor/floorplan.svg"></ion-icon>
            <ion-label class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.attachment.floorplan.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.attachment.floorplan.on' | translate" *ngIf="pageSetting?.floorplan?.enable"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.attachment.floorplan.off' | translate" *ngIf="!pageSetting?.floorplan?.enable"></ion-text>
              </div>
              <div class="font-10">
                *<ion-text [innerHtml]="'VISITOR.setting.attachment.support' | translate"></ion-text>
              </div>
            </ion-label>
          </ion-item>
          <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectFloorplan()">
            <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.floorplan?.url">
              <ion-label class="ion-margin ion-padding ion-text-center">
                <ion-img class="center upload-img" name="cloud-upload-outline"></ion-img>
                <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.floorplan.upload' | translate }}</div>
              </ion-label>
            </ion-item>
            <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.floorplan?.url">
              <ion-label class="ion-no-margin custom-background-label">
                <ion-icon class="font-40 center" name="document-outline"></ion-icon>
                <div class="ion-text-center">
                  <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
                </div>
              </ion-label>
            </ion-item>
          </div>
        </ion-card>

        <ion-card class="setting-card" *ngIf="pageSettingForm?.value?.menu?.enable">
          <ion-item class="full-width" lines="none">
            <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/visitor/menu.svg"></ion-icon>
            <ion-label class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.attachment.menu.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.attachment.menu.on' | translate" *ngIf="pageSetting?.menu?.enable"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.attachment.menu.off' | translate" *ngIf="!pageSetting?.menu?.enable"></ion-text>
              </div>
              <div class="font-10">
                *<ion-text [innerHtml]="'VISITOR.setting.attachment.support' | translate"></ion-text>
              </div>
            </ion-label>
          </ion-item>
          <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectMenu()">
            <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.menu?.url">
              <ion-label class="ion-margin ion-padding ion-text-center">
                <ion-img class="center upload-img" name="cloud-upload-outline"></ion-img>
                <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.menu.upload' | translate }}</div>
              </ion-label>
            </ion-item>
            <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.menu?.url">
              <ion-label class="ion-no-margin custom-background-label">
                <ion-icon class="font-40 center" name="document-outline"></ion-icon>
                <div class="ion-text-center">
                  <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
                </div>
              </ion-label>
            </ion-item>
          </div>
        </ion-card>

        <ion-card class="setting-card" *ngIf="pageSettingForm?.value?.attachment?.[0]?.enable">
          <ion-item class="full-width" lines="none">
            <ion-icon class="setting-icon icon-small" name="document-outline"></ion-icon>
            <ion-label class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHtml]="'LBL.other' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.attachment.on' | translate" *ngIf="pageSetting?.attachment?.[0]?.enable"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.attachment.off' | translate" *ngIf="!pageSetting?.attachment?.[0]?.enable"></ion-text>
              </div>
              <div class="font-10">
                *<ion-text [innerHtml]="'VISITOR.setting.attachment.support' | translate"></ion-text>
              </div>
            </ion-label>
          </ion-item>
          <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectAttachment()">
            <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.attachment?.[0]?.url">
              <ion-label class="ion-margin ion-padding ion-text-center">
                <ion-img class="center upload-img" name="cloud-upload-outline"></ion-img>
                <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.menu.upload' | translate }}</div>
              </ion-label>
            </ion-item>
            <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.attachment?.[0]?.url">
              <ion-label class="ion-no-margin custom-background-label">
                <ion-icon class="font-40 center" name="document-outline"></ion-icon>
                <div class="ion-text-center">
                  <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
                </div>
              </ion-label>
            </ion-item>
          </div>
          <!-- <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectAttachment()">
            <ion-grid fixed>
              <ion-row>
                <ng-container  *ngFor="let attachment of pageSetting?.attachment">
                  <ion-col *ngIf="attachment?.url">
                    <ion-icon class="font-40 center" name="document-outline"></ion-icon>
                    <div class="ion-text-center">
                      <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
                    </div>
                  </ion-col>
                </ng-container>
                <ion-col *ngIf="!pageSetting?.attachment?.length">
                  <ion-img class="center upload-img" name="cloud-upload-outline"></ion-img>
                  <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.upload' | translate }}</div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div> -->
        </ion-card>

      </ion-list>
    </form>
  </ng-container>

  <ng-container *ngIf="type === 'info'">
    <ion-toolbar class="language-toolbar" *ngIf="type === 'info' && languageList?.length > 1">
      <ion-segment class="full-width" scrollable [(ngModel)]="langCode">
        <ion-segment-button [value]="lang.code" *ngFor="let lang of pageLangList">
          <ion-label>{{ lang.name }}</ion-label>
        </ion-segment-button>
      </ion-segment>
      <ion-button class="ion-no-margin" fill="clear" type="button" (click)="newLanguage()" *ngIf="languageList.length > pageLangList?.length">
        <ion-icon class="icon-large" color="medium" name="add-circle-outline"></ion-icon>
      </ion-button>
    </ion-toolbar>
    <ng-container *ngIf="pageLocation?.coordinate && pageLocation?.name else pageLocationBlock">
      <ion-item class="background-transparent ion-margin ion-item-no-padding" lines="none">
        <ion-label color="dark">{{ 'VISITOR.setting.lbl.gps' | translate }}</ion-label>
        <ion-buttons class="ion-no-margin" slot="end">
          <ion-button class="font-12" fill="solid" size="small" color="warning" (click)="testGps()">
            <ion-icon class="icon-small" name="search"></ion-icon>
            <ion-label class="ion-padding-horizontal">{{ 'BTN.preview' | translate }}</ion-label>
          </ion-button>
          <ion-button class="font-12" fill="solid" size="small" color="warning" (click)="presentLocationModal(pageLocation)">
            <ion-icon class="icon-small" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
            <ion-label class="ion-padding-horizontal">{{ 'CRUD.edit' | translate }}</ion-label>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ng-container>
    <ng-template #pageLocationBlock>
      <div class="ion-padding">
        <ion-button class="ion-margin-horizontal" expand="block" color="warning" (click)="presentLocationModal()">
          {{ 'LOCATION.btn.setup_venue' | translate }}
        </ion-button>
      </div>
    </ng-template>
    <ng-container *ngIf="pageLocation && getPageInfoForm(langCode) as pageInfoForm">
      <form class="ion-padding-horizontal" [formGroup]="pageInfoForm" *ngIf="pageInfoForm">
        <ion-list class="background-transparent">
          <ng-container>  
            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'LOCATION.lbl.venue_name' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetVenue()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="venue" tabindex="1"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.venue">
                  <div class="error-message" *ngIf="pageInfoForm.get('venue').hasError(validation.type) && (pageInfoForm.get('venue').dirty || pageInfoForm.get('venue').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">{{ 'LOCATION.lbl.venue_hall' | translate }}</ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="hall" tabindex="2"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.hall">
                  <div class="error-message" *ngIf="pageInfoForm.get('hall').hasError(validation.type) && (pageInfoForm.get('hall').dirty || pageInfoForm.get('hall').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                &nbsp;<span class="font-underline ion-float-right" (click)="resetAddress()">{{ 'BTN.reset' | translate }}</span>
                {{ 'LOCATION.lbl.venue_address' | translate }}
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white" formControlName="address" autogrow="true" tabindex="3"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.address">
                  <div class="error-message" *ngIf="pageInfoForm.get('address').hasError(validation.type) && (pageInfoForm.get('address').dirty || pageInfoForm.get('address').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.remark' | translate }}</ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="remark" tabindex="11"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.remark">
                  <div class="error-message" *ngIf="pageInfoForm.get('remark').hasError(validation.type) && (pageInfoForm.get('remark').dirty || pageInfoForm.get('remark').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
              
            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ replaceEventType('ACCOUNT.lbl.wedding_title' | translate) }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetTitle()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('title')">
                <div class="full-width text-area" *ngIf="editMode.title">
                  <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.title = false;" formControlName="title" autoGrow="true" tabindex="4" #titleInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.title" [innerHtml]="getHtml(pageInfoForm?.value?.title)"></ion-text>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.title">
                  <div class="error-message" *ngIf="pageInfoForm.get('title').hasError(validation.type) && (pageInfoForm.get('title').dirty || pageInfoForm.get('title').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{  replaceEventType(validation.msg) }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">{{  replaceEventType('VISITOR.setting.lbl.title2' | translate) }}</ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="title2" tabindex="5"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.title2">
                  <div class="error-message" *ngIf="pageInfoForm.get('title2').hasError(validation.type) && (pageInfoForm.get('title2').dirty || pageInfoForm.get('title2').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{  replaceEventType(validation.msg) }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{  replaceEventType('DATE.lbl.wedding_date' | translate) }} <ion-text color="danger">*</ion-text>
                &nbsp;<span class="font-underline ion-float-right" (click)="resetDate()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('date')">
                <div class="full-width text-area" *ngIf="editMode.date">
                  <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.date = false;" formControlName="date" autoGrow="true" tabindex="6" #dateInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.date" [innerHtml]="getHtml(pageInfoForm?.value?.date)"></ion-text>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.date">
                  <div class="error-message" *ngIf="pageInfoForm.get('date').hasError(validation.type) && (pageInfoForm.get('date').dirty || pageInfoForm.get('date').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{  replaceEventType(validation.msg) }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ion-grid class="ion-no-margin ion-no-padding">
              <ion-row class="ion-no-margin ion-no-padding">
                <ion-col class="ion-no-margin ion-no-padding" style="padding-right:5px;">
                  <ion-item class="ion-item-no-padding" color="light" lines="none">
                    <ion-label position="stacked" color="dark">
                      {{ 'VISITOR.setting.lbl.startTime' | translate }}
                      &nbsp;<span class="font-underline ion-float-right" (click)="resetStartTime()">{{ 'BTN.reset' | translate }}</span>
                    </ion-label>
                    <ion-item class="prompt-input-item time-item ion-item-no-padding" lines="none" (click)="setEditMode('startTime')">
                      <div class="full-width text-area">
                        <div *ngIf="editMode.startTime">
                          <ion-input class="" (ionBlur)="editMode.startTime = false;" formControlName="startTime" type="text" inputmode="text" tabindex="7" #startTimeInput></ion-input>
                        </div>
                        <ion-text class="full-width wrap-newline" *ngIf="!editMode.startTime" [innerHtml]="getHtml(pageInfoForm?.value?.startTime)"></ion-text> 
                      </div>
                    </ion-item>
                    <div class="error-div">
                      <ng-container *ngFor="let validation of validationMsg?.startTime">
                        <div class="error-message" *ngIf="pageInfoForm.get('startTime').hasError(validation.type) && (pageInfoForm.get('startTime').dirty || pageInfoForm.get('startTime').touched)">
                          <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                        </div>
                      </ng-container>
                    </div>
                  </ion-item>
                </ion-col>
                <ion-col class="ion-no-margin ion-no-padding" style="padding-left: 5px;">
                  <ion-item class="ion-item-no-padding" color="light" lines="none">
                    <ion-label position="stacked" color="dark">
                      {{ 'VISITOR.setting.lbl.endTime' | translate }}
                      &nbsp;<span class="font-underline ion-float-right" (click)="resetEndTime()">{{ 'BTN.reset' | translate }}</span>
                    </ion-label>
                    <ion-item class="prompt-input-item time-item ion-item-no-padding" lines="none" (click)="setEditMode('endTime')">
                      <div class="full-width text-area">
                        <div *ngIf="editMode.endTime">
                          <ion-input class="" (ionBlur)="editMode.endTime = false;" formControlName="endTime" type="text" inputmode="text" tabindex="8" #endTimeInput></ion-input>
                        </div>
                        <ion-text class="full-width wrap-newline" *ngIf="!editMode.endTime" [innerHtml]="getHtml(pageInfoForm?.value?.endTime)"></ion-text> 
                      </div>
                    </ion-item>
                    <!-- <ion-item class="prompt-input-item time-item ion-item-no-padding" lines="none">
                      <div class="full-width text-area">
                        <ion-input class="" formControlName="endTime" type="text" inputmode="text" tabindex="8"></ion-input>
                      </div>
                    </ion-item> -->
                    <div class="error-div">
                      <ng-container *ngFor="let validation of validationMsg?.endTime">
                        <div class="error-message" *ngIf="pageInfoForm.get('endTime').hasError(validation.type) && (pageInfoForm.get('endTime').dirty || pageInfoForm.get('endTime').touched)">
                          <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                        </div>
                      </ng-container>
                    </div>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
    
            <ion-item class="ion-item-no-padding" lines="none" color="light">
              <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.cocktailTime' | translate }}
                &nbsp;
                <ion-text class="font-8">
                  ({{ 'VISITOR.eg.cocktail' | translate }})
                </ion-text>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="cocktailTime" tabindex="9"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.cocktailTime">
                  <div class="error-message" *ngIf="pageInfoForm.get('cocktailTime').hasError(validation.type) && (pageInfoForm.get('cocktailTime').dirty || pageInfoForm.get('cocktailTime').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ion-item class="ion-item-no-padding" lines="none" color="light">
              <ion-label position="stacked" color="dark">
                {{ 'DATE.lbl.timezone' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetTimezone()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('timezone')">
                <div class="full-width text-area" *ngIf="editMode.timezone">
                  <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.timezone = false;" formControlName="timezone" autoGrow="true" tabindex="10" #timezoneInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.timezone" [innerHtml]="getHtml(pageInfoForm?.value?.timezone)"></ion-text>
              </ion-item>
              <!-- <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="timezone" tabindex="10"></ion-textarea>
                </div>
              </ion-item> -->
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.timezone">
                  <div class="error-message" *ngIf="pageInfoForm.get('timezone').hasError(validation.type) && (pageInfoForm.get('timezone').dirty || pageInfoForm.get('timezone').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="checkGuestPass()">
              <ion-label position="stacked" color="dark">{{ 'VISITOR.variable.guestPass' | translate }}</ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
                <div class="full-width text-area">
                  <ion-textarea class="background-white fixed-height" formControlName="guestPass" tabindex="12"></ion-textarea>
                </div>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.guestPass">
                  <div class="error-message" *ngIf="pageInfoForm.get('guestPass').hasError(validation.type) && (pageInfoForm.get('guestPass').dirty || pageInfoForm.get('guestPass').touched)">
                    <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.itinerary' | translate }}</ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('itinerary')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.itinerary">
                  <ion-textarea class="background-white" formControlName="itinerary" autoGrow="true" tabindex="13" #itineraryInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.itinerary" [innerHtml]="getHtml(pageInfoForm?.value?.itinerary)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ion-grid class="full-width" fixed>
              <ion-row class="line">
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
            <div class="ion-padding-vertical">
              <ion-text class="font-10 font-bold">{{ 'QRCODE.lbl.dynamic_variable' | translate }}</ion-text>
              <ion-segment class="background-transparent" scrollable>
                <ng-container *ngFor="let variable of getTemplateVariableList()">
                  <ion-segment-button class="dynamic-variable-seg-btn ion-no-padding" (click)="copy(variable)" *ngIf="checkVariable(variable)">
                    <ion-text>{{  replaceEventType('TEMPLATE.variable.' + variable | translate) }}</ion-text>
                  </ion-segment-button>
                </ng-container>
              </ion-segment>
            </div>

            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'VISITOR.lbl.greeting' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetGreeting()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('greeting')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.greeting">
                  <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.greeting = false;" formControlName="greeting" autoGrow="true" tabindex="14" #greetingInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.greeting" [innerHtml]="getHtml(pageInfoForm?.value?.greeting)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'LBL.msg' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetMsg()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('msg')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.msg">
                  <ion-textarea class="background-white" formControlName="msg" autoGrow="true" tabindex="15" #msgInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div" *ngIf="!editMode.msg" [innerHtml]="getHtml(pageInfoForm?.value?.msg)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="pageSetting?.pax">
              <ion-label position="stacked" color="dark">
                {{ 'VISITOR.setting.pax.title' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetPax()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('pax')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.pax">
                  <ion-textarea class="background-white" formControlName="pax" autoGrow="true" tabindex="16" #paxInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.pax" [innerHtml]="getHtml(pageInfoForm?.value?.pax)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="pageSetting?.seatingBeforeCheckin">
              <ion-label position="stacked" color="dark">
                {{ 'VISITOR.setting.seating.title' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetSeating()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('seating')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.seating">
                  <ion-textarea class="background-white" formControlName="seating" autoGrow="true" tabindex="17" #seatingInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div" *ngIf="!editMode.seating" [innerHtml]="getHtml(pageInfoForm?.value?.seating)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none" *ngIf="pageSetting?.session">
              <ion-label position="stacked" color="dark">
                {{ 'LBL.session' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetSession()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('session')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.session">
                  <ion-textarea class="background-white" formControlName="session" autoGrow="true" tabindex="18" #sessionInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.session" [innerHtml]="getHtml(pageInfoForm?.value?.session)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ion-item class="ion-item-no-padding" color="light" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'TEMPLATE.variable.group_name' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetGroupName()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('groupName')" lines="none">
                <div class="full-width text-area" *ngIf="editMode.groupName">
                  <ion-textarea class="background-white" formControlName="groupName" autoGrow="true" tabindex="19" #groupNameInput></ion-textarea>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.groupName" [innerHtml]="getHtml(pageInfoForm?.value?.groupName)"></ion-text>
              </ion-item>
              <div class="error-div"></div>
            </ion-item>

            <ng-container *ngIf="pageSetting?.qrcode">
              <ion-item class="ion-item-no-padding" color="light" lines="none">
                <ion-label position="stacked" color="dark">
                  {{ pageSetting?.qrcode ? ('QRCODE.lbl.group_qrcode' | translate) : ('QRCODE.lbl.group_invitation' | translate) }}
                  &nbsp;<span class="font-underline ion-float-right" (click)="resetGroupType()">{{ 'BTN.reset' | translate }}</span>
                </ion-label>
                <ion-item class="full-width prompt-input-item ion-item-no-padding" lines="none">
                  <div class="full-width text-area">
                    <ion-input class="" formControlName="groupType" type="text" inputmode="text" tabindex="20"></ion-input>
                  </div>
                  
                </ion-item>
                <div class="error-div"></div>
              </ion-item>
  
              <ion-item class="ion-item-no-padding" color="light" lines="none">
                <ion-label position="stacked" color="dark">
                  {{ pageSetting?.qrcode ? ('QRCODE.lbl.guest_group_qrcode' | translate) : ('QRCODE.lbl.guest_group_invitation' | translate) }}
                  &nbsp;<span class="font-underline ion-float-right" (click)="resetGroupMemberType()">{{ 'BTN.reset' | translate }}</span>
                </ion-label>
                <ion-item class="full-width prompt-input-item ion-item-no-padding" lines="none">
                  <div class="full-width text-area">
                    <ion-input class="" formControlName="groupMemberType" type="text" inputmode="text" tabindex="21"></ion-input>
                  </div>
                  
                </ion-item>
                <div class="error-div"></div>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="pageSetting?.floorplan?.url">
              <ion-item class="ion-item-no-padding" color="light" lines="none">
                <ion-label position="stacked" color="dark">
                  {{ 'VISITOR.setting.attachment.floorplan.title' | translate }}
                  &nbsp;<span class="font-underline ion-float-right" (click)="resetFloorplan()">{{ 'BTN.reset' | translate }}</span>
                </ion-label>
                <ion-item class="full-width prompt-input-item ion-item-no-padding" (click)="setEditMode('floorplan')" lines="none">
                  <div class="full-width text-area" *ngIf="editMode.floorplan">
                    <ion-input class=" background-white" formControlName="floorplan" autoGrow="true" tabindex="20" #floorplanInput></ion-input>
                  </div>
                  <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.floorplan" [innerHtml]="getHtml(pageInfoForm?.value?.floorplan)"></ion-text>
                </ion-item>
                <div class="error-div"></div>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="pageSetting?.menu?.url">
              <ion-item class="ion-item-no-padding" color="light" lines="none">
                <ion-label position="stacked" color="dark">
                  {{ 'VISITOR.setting.attachment.menu.title' | translate }}
                  &nbsp;<span class="font-underline ion-float-right" (click)="resetMenu()">{{ 'BTN.reset' | translate }}</span>
                </ion-label>
                <ion-item class="full-width prompt-input-item ion-item-no-padding" (click)="setEditMode('menu')" lines="none">
                  <div class="full-width text-area" *ngIf="editMode.menu">
                    <ion-input class="background-white" formControlName="menu" autoGrow="true" tabindex="21" #menuInput></ion-input>
                  </div>
                  <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.menu" [innerHtml]="getHtml(pageInfoForm?.value?.menu)"></ion-text>
                </ion-item>
                <div class="error-div"></div>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="pageSetting?.attachment?.[0]?.url">
              <ion-item class="ion-item-no-padding" color="light" lines="none">
                <ion-label position="stacked" color="dark">
                  {{ 'VISITOR.setting.attachment.title' | translate }}
                  &nbsp;<span class="font-underline ion-float-right" (click)="resetAttachment()">{{ 'BTN.reset' | translate }}</span>
                </ion-label>
                <ion-item class="full-width prompt-input-item ion-item-no-padding" (click)="setEditMode('attachment')" lines="none">
                  <div class="full-width text-area" *ngIf="editMode.attachment">
                    <ion-input class="background-white" formControlName="attachment" autoGrow="true" tabindex="22" #attachmentInput></ion-input>
                  </div>
                  <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.attachment" [innerHtml]="getHtml(pageInfoForm?.value?.attachment)"></ion-text>
                </ion-item>
                <div class="error-div"></div>
              </ion-item>
            </ng-container>

          </ng-container>
        </ion-list>
      </form>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type === 'theme'">
    <app-setting-visitor-theme class="full-width-height" [pageTheme]="pageTheme" (setPageTheme)="setPageTheme($event)"></app-setting-visitor-theme>
  </ng-container>

  <ion-list class="ion-padding-top" *ngIf="type === 'info' && pageLangList?.length > 1">
    <ion-item-sliding class="ion-margin-top" #deleteItem>
      <ion-item-options side="start" (ionSwipe)="slideDelete(deleteItem)" lines="none">
        <ion-item-option color="danger" (click)="slideDelete(deleteItem)" lines="none">
          <ion-icon name="checkmark-outline"></ion-icon>
        </ion-item-option>
      </ion-item-options>
      <ion-item color="danger" lines="none">
        <ion-icon slot="start" class="icon-small" name="trash-outline"></ion-icon> 
        <ion-text slot="start">{{ 'CRUD.swipe_field' | translate: { field: 'CRUD.delete' | translate } }}</ion-text> 
        <ion-icon slot="start" class="icon-small" name="arrow-forward-outline"></ion-icon>
      </ion-item>
      <ion-item-options side="end" (ionSwipe)="slideDelete(deleteItem)" lines="none">
        <ion-item-option color="danger" (click)="slideDelete(deleteItem)" lines="none">
          <ion-icon name="checkmark-outline"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-no-padding footer-toolbar" color="white">
    <ion-grid class="footer-grid" fixed>
      <ion-row *ngIf="type === 'theme'">
        <ion-col class="ion-text-center ion-no-padding">
          <ion-text class="font-12">{{ 'VISITOR.setting.theme_select' | translate }}</ion-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-button expand="block" color="light" (click)="preview()">
            {{ 'BTN.preview' | translate }}
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button expand="block" color="primary" (click)="save()">
            {{ 'BTN.save' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
