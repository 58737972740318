<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="discard()">
        <ion-icon class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'CRUD.update_field' | translate: { field: 'LIST.group.group' | translate } }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="isHybrid">
      <ion-button (click)="presentReadContactsModal()">
        <ion-icon name="person-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="white">
  <form class="ion-padding full-width-height" [formGroup]="form" (ngSubmit)="groupEditFormSubmit()" *ngIf="form">
    <ion-item class="ion-item-no-padding" color="white" lines="none">
      <ion-label position="stacked" color="dark">{{ 'GROUP.lbl.name' | translate }}</ion-label>
      <ion-input class="prompt-input" formControlName="groupName" [placeholder]="" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="1" clearInput></ion-input>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.groupName">
          <div class="error-message" *ngIf="form?.get('groupName').hasError(validation.type) && (form?.get('groupName').dirty || form?.get('groupName').touched)">
            <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ion-item>
    <ion-item class="ion-item-no-padding" color="white" lines="none">
      <ion-label position="stacked" color="dark">{{ 'LBL.email' | translate }}</ion-label>
      <ion-input class="prompt-input" formControlName="email" [placeholder]="" type="email" inputmode="email" autocomplete="on" tabindex="3" clearInput></ion-input>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.email">
          <div class="error-message" *ngIf="form?.get('email').hasError(validation.type) && (form?.get('email').dirty || form?.get('email').touched)">
            <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ion-item>
    <div>
      <app-mobile class="full-width-height" [lbl]="'MOBILE.lbl.no' | translate" [setMobile]="mobile" [setCssClass]="''" (outputMobile)="updateMobile($event)" (outputMobileInvalid)="updateMobileInvalid($event)"></app-mobile>
    </div>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="groupEditFormSubmit()" [disabled]="!form?.valid || mobileInvalid">
      <ng-container *ngIf="newGroup">{{ 'CRUD.update' | translate }}</ng-container>
      <ng-container *ngIf="!newGroup">{{ 'BTN.save' | translate }}</ng-container>
    </ion-button>
  </ion-toolbar>
</ion-footer>