<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'MENU.accounts_add' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="content-div">
    <div class="ion-text-center ion-padding-vertical">
      <ion-icon name="lock-open-outline"></ion-icon>&nbsp;<ion-text class="font-14 font-med" color="dark">{{ 'ACCOUNTS.msg.id_password' | translate }}</ion-text>
    </div>
    <form [formGroup]="passwordForm" (ngSubmit)="submitPassword()" *ngIf="passwordForm">
      <div>
        <div class="ion-padding-horizontal">
          <ion-label position="stacked" color="dark">{{ 'ACCOUNT.lbl.wedding_id' | translate }}</ion-label>
          <ion-input class="prompt-input" formControlName="weddingId" type="text" inputmode="text" value="" tabindex="1" clearInput></ion-input>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.weddingId">
              <div class="error-message" *ngIf="passwordForm.get('weddingId').hasError(validation.type) && (passwordForm.get('weddingId').dirty || passwordForm.get('weddingId').touched)">
                <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="ion-padding-horizontal">
          <ion-label position="stacked" color="dark">{{ 'ACCOUNT.lbl.wedding_pass' | translate }}</ion-label>
          <ion-input class="prompt-input" formControlName="weddingPass" type="password" inputmode="text" value="" minlength="1" maxlength="20" tabindex="2" clearInput></ion-input>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.weddingPass">
              <div class="error-message" *ngIf="passwordForm.get('weddingPass').hasError(validation.type) && (passwordForm.get('weddingPass').dirty || passwordForm.get('weddingPass').touched)">
                <ion-icon name="alert-circle-outline"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="center ion-text-center" *ngIf="recaptcha && siteKey">
          <ngx-invisible-recaptcha #captchaElem
            class="center"
            [siteKey]="siteKey"
            (reset)="handleReset()"
            (ready)="handleReady()"
            (load)="handleLoad()"
            (success)="handleSuccess($event)"
            (error)="handleError()"
            (expire)="handleExpire()"
            [useGlobalDomain]="false"
            badge="inline"
            [ngModel]="recaptchaModel"
            [ngModelOptions]="{ standalone: true }">
          </ngx-invisible-recaptcha>
        </div>
      </div>
      <div class="ion-padding">
        <ion-button class="ion-margin-vertical" expand="block" color="primary" fill="solid" type="submit" [disabled]="!passwordForm.valid">
          <ion-icon name="enter-outline"></ion-icon> &nbsp; {{ 'BTN.continue' | translate }}
        </ion-button>
      </div>
    </form>
  </div>
</ion-content>